export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date */
  Date: any;
  /** DateTime */
  DateTime: string;
  /** Must be a valid email address */
  Email: any;
  /** An alpha-numeric string that represents a fee category. */
  FeeCategoryCode: any;
  /** An integer that represents a Group Coordinator's free place ratio for tour products or insurance. */
  FreePlaceRatio: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** Trip component line item code */
  LineItemCode: any;
  /** Mongodb ObjectId */
  ObjectId: string;
  /** Percent value */
  Percentage: any;
  /** Phone number */
  PhoneNumber: any;
  /** A component that differentiates different events happening on the same date. I.E 2 different departures on the same date for the same tour. */
  Series: any;
  /** A time of day without a date */
  Time: any;
  /** An alpha-numeric string that represents a tour cabin. */
  TourCabinCode: any;
};

export type AchPaymentMethod = {
  __typename?: 'ACHPaymentMethod';
  accountNumberMask: Scalars['String'];
  address?: Maybe<Address>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  processorPaymentMethodId?: Maybe<Scalars['Int']>;
  routingNumber: Scalars['String'];
};

export type AcceptGcDepartureTermsResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidDateError | InvalidOrderError | InvalidTermError | NotAuthorizedError | NotFoundError | ValidationError;

export type AcceptTripTermsResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTermError | NotAuthorizedError | NotFoundError | ValidationError;

/** Add promos to a campaign */
export type AddCampaignPromosInput = {
  offerCodes: Array<Scalars['String']>;
};

export type AddCampaignPromosResult = BusinessNotSpecifiedError | Campaign | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftBookingPaymentScheduleResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPaymentError | InvalidPaymentScheduleError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderAdjustmentInput = {
  /** The dollar amount to adjust the trip by. */
  amount: Scalars['Float'];
  /** Specific line item the adjustment targets. If not provided, the adjustment will target the trip in total. */
  targetLineItem?: InputMaybe<Scalars['ObjectId']>;
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderAdjustmentResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidAdjustmentError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderCampaignResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | InvalidOrderError | InvalidPromoError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderExcursionInput = {
  /** Excursion lineItemCode to add. */
  extensionExcursionLineItemCodes: Array<Scalars['LineItemCode']>;
  /** Excursion lineItemCode to add. */
  mainTourExcursionLineItemCodes: Array<Scalars['LineItemCode']>;
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderExcursionsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderExtendedStayInput = {
  /** The number of days of the extended stay. */
  nbDays: Scalars['Int'];
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderExtensionResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderFeeInput = {
  /** The fee amount. Will override the results of buildPrice(). */
  overrideAmount?: InputMaybe<Scalars['Float']>;
  /** The line item the fee should target. */
  targetLineItemId?: InputMaybe<Scalars['ObjectId']>;
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderFeeResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderInsuranceAddonInput = {
  /** lineItemCode for the insurance plan addon. */
  insuranceAddonLineItemCode: Scalars['LineItemCode'];
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderInsuranceAddonResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderInsuranceExtensionInput = {
  /** Number of days to the insurance extension. */
  daysToExtend: Scalars['Int'];
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type AddDraftOrderInsuranceExtensionResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderInsuranceResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderPostStayResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderPreStayResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderPrivateGroupResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftOrderPromoResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPromoError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddDraftQuoteTripInput = {
  /** The DraftOrder _id */
  _id: Scalars['ObjectId'];
  /** The Customer ID for the Trip being added to the Draft Quote, if one exists */
  customerId?: InputMaybe<Scalars['ObjectId']>;
  /** The customer's home gateway code */
  gatewayCode?: InputMaybe<Scalars['String']>;
};

export type AddDraftQuoteTripResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidFlightPackageError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type AddEmployeesToRoleInput = {
  employeeIds: Array<Scalars['ObjectId']>;
};

export type AddEmployeesToRoleResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Role | ValidationError;

/** Add additional targets to a promo */
export type AddPrivateGroupInput = {
  basePrice: Array<MoneyInput>;
  rollsUpToTarget?: InputMaybe<Scalars['Boolean']>;
};

export type AddPrivateGroupResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

/** Add promo codes to a promo */
export type AddPromoCodesInput = {
  promoCodes: Array<Scalars['String']>;
};

export type AddPromoCodesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type AddPromoCustomersInput = {
  customerIds: Array<Scalars['ObjectId']>;
};

export type AddPromoCustomersResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

/** Add gc departure to a promo */
export type AddPromoGcDepartureInput = {
  gcDepartureIds: Array<Scalars['ObjectId']>;
};

export type AddPromoGcDeparturesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

/** Add additional targets to a promo */
export type AddPromoTargetsInput = {
  targets: Array<CreatePromoTargetInput>;
};

export type AddPromoTargetsResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type AddTourExcursionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type AddTourExtensionExcursionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourExtension | ValidationError;

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
};

/** Non standard increases or reductions to a trip balance */
export type Adjustment = TripComponent & {
  __typename?: 'Adjustment';
  _id: Scalars['ObjectId'];
  adjustmentCode: Scalars['String'];
  businessCode?: Maybe<BusinessCode>;
  displayName: Scalars['String'];
  isPublished: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  meta?: Maybe<AdjustmentMetadata>;
  persistsOnCancelAndRebook: Scalars['Boolean'];
  rollsUpToTarget: Scalars['Boolean'];
  status: TripComponentStatus;
  type: TripComponentType;
};

export type AdjustmentLineItem = LineItem & {
  __typename?: 'AdjustmentLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  meta?: Maybe<AdjustmentMetadata>;
  persistsOnCancelAndRebook: Scalars['Boolean'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** Additional settings for this adjustment line item */
export type AdjustmentLineItemMetadata = {
  __typename?: 'AdjustmentLineItemMetadata';
  isCompanionDiscount: Scalars['Boolean'];
  isFreePlace: Scalars['Boolean'];
  isStaffOnTour: Scalars['Boolean'];
};

/** Additional settings for this adjustment */
export type AdjustmentMetadata = {
  __typename?: 'AdjustmentMetadata';
  isCompanionDiscount: Scalars['Boolean'];
  isFreePlace: Scalars['Boolean'];
  isStaffOnTour: Scalars['Boolean'];
};

/** A paginated result of Discounts */
export type AdjustmentPaginatedResult = {
  __typename?: 'AdjustmentPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Adjustment>;
};

export enum AdjustmentSortField {
  AdjustmentCode = 'adjustmentCode',
  LineItemCode = 'lineItemCode'
}

/** Generate a magic link, and send to a customer or tour director */
export type AdminCreateMagicLinkInput = {
  email: Scalars['String'];
  isTourDirector?: InputMaybe<Scalars['Boolean']>;
  returnURL: Scalars['String'];
};

export type AdminCreateMagicLinkResult = AdminMagicLinkResult | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidMagicLinkError | NotAuthorizedError | NotFoundError | ValidationError;

export type AdminMagicLinkResult = {
  __typename?: 'AdminMagicLinkResult';
  returnLink: Scalars['String'];
};

export type AffirmPaymentMethod = {
  __typename?: 'AffirmPaymentMethod';
  /** The CHECKOUT_TOKEN from affirm for this loan */
  checkoutToken: Scalars['String'];
  /** The loan id from affirm */
  transactionId: Scalars['String'];
};

export type AffirmPaymentMethodInput = {
  /** The CHECKOUT_TOKEN from affirm for this loan */
  affirmCheckoutToken: Scalars['String'];
};

/** Airline company that provides air transportation services for passengers */
export type Airline = {
  __typename?: 'Airline';
  _id: Scalars['ObjectId'];
  code: Scalars['String'];
  iataCode?: Maybe<Scalars['String']>;
  icaoCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** Airport transfers are for LO1 flights where the customer has purchased a transfer to/from the airport */
export type AirportTransfer = {
  __typename?: 'AirportTransfer';
  airline: Scalars['String'];
  arrivalDate: Scalars['Date'];
  arrivalTime: Scalars['Time'];
  departureDate: Scalars['Date'];
  departureTime: Scalars['Time'];
  flightNumber: Scalars['String'];
  gatewayCodeFrom: Scalars['String'];
  gatewayCodeTo: Scalars['String'];
  gatewayFrom?: Maybe<Gateway>;
  gatewayTo?: Maybe<Gateway>;
  pnrLocator?: Maybe<Scalars['String']>;
};

export type AirportTransferInput = {
  airline: Scalars['String'];
  arrivalDate: Scalars['Date'];
  arrivalTime: Scalars['Time'];
  departureDate: Scalars['Date'];
  departureTime: Scalars['Time'];
  flightNumber: Scalars['String'];
  gatewayCodeFrom: Scalars['String'];
  gatewayCodeTo: Scalars['String'];
  pnrLocator?: InputMaybe<Scalars['String']>;
};

/** Represents a grouping of money unique by currency code */
export type Amount = {
  __typename?: 'Amount';
  cad?: Maybe<Money>;
  currencies: Array<CurrencyCode>;
  usd?: Maybe<Money>;
  value: Array<Money>;
};

/** Input for answering a free text question */
export type AnswerFreeTextQuestionInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  questionId: Scalars['ObjectId'];
  value?: InputMaybe<Scalars['String']>;
};

/** Input for answering a matrix question */
export type AnswerMatrixQuestionInput = {
  questionId: Scalars['ObjectId'];
  rows: Array<AnswerMatrixRowInput>;
};

/** Input for answering a matrix row */
export type AnswerMatrixRowInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  choiceId: Scalars['ObjectId'];
  rowId: Scalars['ObjectId'];
};

/** Input for answering a multiple choice question */
export type AnswerMultipleChoiceQuestionInput = {
  additionalComments?: InputMaybe<Scalars['String']>;
  choiceId: Scalars['ObjectId'];
  questionId: Scalars['ObjectId'];
};

/** API Key used for auth with x-api-key header */
export type ApiKey = {
  __typename?: 'ApiKey';
  _id: Scalars['ObjectId'];
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  name: Scalars['String'];
  roleIds: Array<Scalars['ObjectId']>;
};

/** API Keys only return the generated key at creation. It will never be available again after */
export type ApiKeyResult = {
  __typename?: 'ApiKeyResult';
  /** The created API key */
  apiKey: ApiKey;
  /** The generated token to use with a basic auth header */
  token: Scalars['String'];
};

export type ApplyGcDeparturePrivateGroupFeeResult = ApplyGcDeparturePrivateGroupFeeSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidFeeError | InvalidGcDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type ApplyGcDeparturePrivateGroupFeeSuccess = {
  __typename?: 'ApplyGCDeparturePrivateGroupFeeSuccess';
  data: Array<Booking>;
};

/** Represents an asset, most commonly images */
export type Asset = {
  __typename?: 'Asset';
  _id: Scalars['ObjectId'];
  filename: Scalars['String'];
  folderId?: Maybe<Scalars['ObjectId']>;
  key: Scalars['String'];
  location: Scalars['String'];
  mimetype: Scalars['String'];
  size: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

/** Filters for asset query */
export type AssetFilterInput = {
  businessCode?: InputMaybe<BusinessCode>;
  folderId?: InputMaybe<Scalars['ObjectId']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** A paginated set of Assets */
export type AssetPaginatedResult = {
  __typename?: 'AssetPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Asset>;
};

/** A room with assigned roommates (or a single) */
export type AssignedRoom = {
  __typename?: 'AssignedRoom';
  bedCode: Scalars['String'];
  trips: Array<BookingTrip>;
};

/** The user that made the change. */
export type AuditIdentity = {
  __typename?: 'AuditIdentity';
  _id: Scalars['String'];
  type: Scalars['String'];
  /** The user that made the change. Returns null if the user is not found or the type is not a system user. */
  user?: Maybe<User>;
};

/** A booked order */
export type Booking = Order & {
  __typename?: 'Booking';
  _id: Scalars['ObjectId'];
  /** The active payment plan attached to the order. */
  activePaymentSchedule?: Maybe<PaymentSchedule>;
  /** The amount paid on the booking. */
  amountPaid: Money;
  /** The available balance for each payment method. */
  availablePaymentBalanceByPaymentMethod?: Maybe<Array<PaymentBalanceByPaymentMethod>>;
  /** The remaining balance of the booking. */
  balance: Money;
  /** Where the order was booked. */
  bookingMethod: BookingMethod;
  businessCode: BusinessCode;
  canEnrollInAutopay: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency code of the order. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer who initiated the booking. */
  customerId?: Maybe<Scalars['ObjectId']>;
  /** The date the booking was made. */
  dateBooked: Scalars['DateTime'];
  departure?: Maybe<Departure>;
  /** The departure id of the order. */
  departureId: Scalars['ObjectId'];
  /** The extended date the final payment is due for the order. */
  extendedFinalPaymentDate?: Maybe<Scalars['Date']>;
  /** The date the final installment for app is due for the order. */
  finalInstallmentDate: Scalars['Date'];
  /** The date the final payment is due for the order. */
  finalPaymentDate: Scalars['Date'];
  gcDeparture?: Maybe<GcDeparture>;
  /** The id of the gc departure associated with the order. */
  gcDepartureId?: Maybe<Scalars['ObjectId']>;
  /** The total amount of the booking. This includes all line items and fees. */
  grandTotal: Money;
  /** Sum of all line item prices without discounts applied */
  grandTotalBeforeDiscount: Money;
  /** Sum of all line items excluding flights */
  grandTotalWithoutFlights: Money;
  /** Whether or not the booking has a positive balance. */
  hasBalanceRemaining: Scalars['Boolean'];
  /** Whether or not the order has an automatic payment plan that has been COMPLETED. */
  hasCompletePaymentPlan: Scalars['Boolean'];
  /** Whether or not the order has automatic payment plan that is ACTIVE, PENDING, or COMPLETED. */
  isEnrolledInAutopay: Scalars['Boolean'];
  /** Indicates if the final payment date has been extended. */
  isFinalPaymentDateExtended: Scalars['Boolean'];
  lastSuccsessfulAutopayTransactionDate?: Maybe<Scalars['DateTime']>;
  /** The latest date the final installment for app is due for the order. */
  latestFinalInstallmentDate: Scalars['Date'];
  /** The latest date the final payment is due for the order. */
  latestFinalPaymentDate: Scalars['Date'];
  /** The minimum amount owed today on the booking. */
  minimumAmountDue: Money;
  opportunityId?: Maybe<Scalars['String']>;
  paymentOptions: Array<BookingPaymentOption>;
  /** The payment plans attached to the order. */
  paymentSchedules: Array<PaymentSchedule>;
  /** The order that this order was rebooked from. */
  rebookedFromOrder?: Maybe<Order>;
  /** The order id that this order was rebooked from. */
  rebookedFromOrderId?: Maybe<Scalars['ObjectId']>;
  /** The risk free period end date. */
  riskFreePeriodEndDate?: Maybe<Scalars['Date']>;
  /** The stage of the order. */
  stage: OrderStage;
  /** Sum of all discount line items */
  totalDiscount: Money;
  tourLineItemCode?: Maybe<Scalars['LineItemCode']>;
  /** The transactions applied to the order, sorted by ascending transaction date. */
  transactions: Array<PaymentTransaction>;
  trips: Array<BookingTrip>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of the order. */
  versionId: Scalars['Int'];
};

/** The type of payment methods allowed for a Booking */
export enum BookingAllowedPaymentMethodType {
  Ach = 'ACH',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD'
}

export type BookingAutopayPaymentOption = {
  __typename?: 'BookingAutopayPaymentOption';
  allowedPaymentMethods: Array<BookingAllowedPaymentMethodType>;
  earliestPaymentScheduleStartDate: Scalars['Date'];
  latestPaymentScheduleStartDate: Scalars['Date'];
  type: BookingPaymentOptionType;
};

export type BookingManualPaymentOption = {
  __typename?: 'BookingManualPaymentOption';
  allowedPaymentMethods: Array<BookingAllowedPaymentMethodType>;
  type: BookingPaymentOptionType;
};

export enum BookingMethod {
  Mobile = 'MOBILE',
  Offline = 'OFFLINE',
  Web = 'WEB'
}

export type BookingPaginatedResult = {
  __typename?: 'BookingPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Booking>;
};

export type BookingPaymentOption = BookingAutopayPaymentOption | BookingManualPaymentOption;

/** The type of payment options for a Booking */
export enum BookingPaymentOptionType {
  AutoPay = 'AUTO_PAY',
  Manual = 'MANUAL'
}

export enum BookingSortField {
  DateBooked = 'dateBooked'
}

/** Represents a trip on a booking order */
export type BookingTrip = Trip & {
  __typename?: 'BookingTrip';
  _id: Scalars['ObjectId'];
  /** The address for the trip. */
  address?: Maybe<Address>;
  booking?: Maybe<Booking>;
  businessCode?: Maybe<BusinessCode>;
  /** Whether the trip is within the cutoff date range for reviews */
  canBeReviewed?: Maybe<Scalars['Boolean']>;
  cancellationMeta?: Maybe<TripCancellationMeta>;
  checklistItems: Array<TripChecklistItem>;
  /** The currency code of the trip. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer id of the trip. */
  customerId?: Maybe<Scalars['ObjectId']>;
  customerType: TripCustomerType;
  dateBooked: Scalars['DateTime'];
  departure?: Maybe<Departure>;
  departureDate: Scalars['Date'];
  /** The deposit amount for the trip. */
  depositAmount: Money;
  /** Checklist item for whether or not the trip has emergency contacts */
  emergencyContactChecklistItem?: Maybe<EmergencyContactChecklistItem>;
  /** The emergency contacts for the trip. */
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  emergencyContactsSelfServiceDeadline?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['Date'];
  evaluation?: Maybe<CustomerEvaluation>;
  evaluationRelease?: Maybe<ReleaseDetails>;
  /** Sum of all open excursion line items */
  excursionsTotal: Money;
  extensionRoommates?: Maybe<Array<Customer>>;
  flightPackagePrice?: Maybe<Money>;
  flights?: Maybe<TripFlights>;
  grandTotal: Money;
  hasAddress: Scalars['Boolean'];
  hasConfirmedExcursions: Scalars['Boolean'];
  hasConfirmedTSAInformation: Scalars['Boolean'];
  hasDepartingFlightDeviation: Scalars['Boolean'];
  hasEmergencyContacts: Scalars['Boolean'];
  /** Whether the trip has an open line item for an extension */
  hasExtension: Scalars['Boolean'];
  hasInsurance: Scalars['Boolean'];
  hasReturningFlightDeviation: Scalars['Boolean'];
  hasSignedTerms: Scalars['Boolean'];
  hasTsaInformation: Scalars['Boolean'];
  /** Checklist item for whether or not the trip has health notes */
  healthNotesChecklistItem?: Maybe<HealthNotesChecklistItem>;
  healthNotesSelfServiceDeadline?: Maybe<Scalars['DateTime']>;
  hotels?: Maybe<Array<TripHotel>>;
  insurancePlanOffers: Array<InsurancePlanOffer>;
  /** Whether the trip is cancelled. */
  isCancelled: Scalars['Boolean'];
  /** Whether the trip is confirmed. */
  isConfirmed: Scalars['Boolean'];
  isLandOnly: Scalars['Boolean'];
  /** Whether the trip is open. */
  isOpen: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  /** Whether the trip is quoted. */
  isQuoted: Scalars['Boolean'];
  /** Whether the trip is rebooked. */
  isRebooked: Scalars['Boolean'];
  /** Whether the trip is requested. */
  isRequested: Scalars['Boolean'];
  isTravelReady: Scalars['Boolean'];
  /** Whether the trip is waitlisted. */
  isWaitlisted: Scalars['Boolean'];
  /** The line items that compose the trip. */
  lineItems: Array<LineItem>;
  /** The REBOOKED + OPEN line items if the trip is rebooked, the CANCELLED + OPEN line items with PARENT_TRIP_CANCELLED cancellation metadata if the trip is cancelled, or the open line items in all other cases.  */
  lineItemsMatchingTripStatus: Array<LineItem>;
  locator: Scalars['String'];
  openAdjustmentLineItems: Array<AdjustmentLineItem>;
  openDepartingFlightLineItem?: Maybe<FlightPackageLineItem>;
  openExcursionLineItems: Array<TourExcursionLineItem>;
  openExtensionLineItem?: Maybe<TourExtensionLineItem>;
  openExtensionRoomLineItem?: Maybe<TourRoomLineItem>;
  openFeeLineItems: Array<FeeLineItem>;
  openInsuranceAddonLineItems: Array<InsuranceAddonLineItem>;
  openInsuranceLineItem?: Maybe<InsuranceLineItem>;
  /** The line items that compose the trip which are not CANCELLED or REBOOKED */
  openLineItems: Array<LineItem>;
  openPostStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPreStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPrivateGroupLineItem?: Maybe<PrivateGroupLineItem>;
  openPromoLineItems: Array<PromoLineItem>;
  openReturningFlightLineItem?: Maybe<FlightPackageLineItem>;
  openRoomLineItem?: Maybe<TourRoomLineItem>;
  /** Checklist item for whether or not the trip has passport information */
  passportInformationChecklistItem?: Maybe<PassportInformationChecklistItem>;
  passportInformationSelfServiceDeadline?: Maybe<Scalars['DateTime']>;
  /** The trip ID for the trip that this trip was rebooked from. */
  rebookedFromTripId?: Maybe<Scalars['ObjectId']>;
  rebookingMeta?: Maybe<TripRebookingMeta>;
  requestedRoommates?: Maybe<Array<Scalars['String']>>;
  requestedTravelCompanions?: Maybe<Array<Scalars['String']>>;
  review?: Maybe<Review>;
  roommates?: Maybe<Array<Customer>>;
  serviceRecoveries: Array<ServiceRecovery>;
  signedTerms?: Maybe<Term>;
  /** The status of the trip */
  status: TripStatus;
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** Checklist item for whether or not the trip has signed terms */
  termsSignatureChecklistItem?: Maybe<TermsSignatureChecklistItem>;
  timingStatus: TripTimingStatus;
  tourLineItem?: Maybe<TourLineItem>;
  travelerNotes?: Maybe<TravelerNotes>;
  /** Checklist item for whether or not the trip has confirmed TSA identifiers */
  tsaIdentifiersChecklistItem?: Maybe<TsaIdentifiersChecklistItem>;
  tsaIdentifiersSelfServiceDeadline?: Maybe<Scalars['DateTime']>;
  /** The TSA information for the trip. */
  tsaInformation?: Maybe<TsaInformation>;
};

export type BulkCreateDepartureExcursionInput = {
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  matchCode: Scalars['String'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureExcursionsResult = BulkCreateDepartureExcursionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDepartureExcursionsSuccess = {
  __typename?: 'BulkCreateDepartureExcursionsSuccess';
  data: Array<Departure>;
};

export type BulkCreateDepartureExtensionExcursionInput = {
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  extensionTourCode: Scalars['String'];
  matchCode: Scalars['String'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureExtensionExcursionsResult = BulkCreateDepartureExtensionExcursionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDepartureExtensionExcursionsSuccess = {
  __typename?: 'BulkCreateDepartureExtensionExcursionsSuccess';
  data: Array<Departure>;
};

export type BulkCreateDepartureExtensionInput = {
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  extensionTourCode: Scalars['String'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureExtensionRoomInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  /** Extension tour code of the departure */
  extensionTourCode: Scalars['String'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  /** Tour code of the departure */
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureExtensionRoomsResult = BulkCreateDepartureExtensionRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDepartureExtensionRoomsSuccess = {
  __typename?: 'BulkCreateDepartureExtensionRoomsSuccess';
  data: Array<Departure>;
};

export type BulkCreateDepartureExtensionsResult = BulkCreateDepartureExtensionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDepartureExtensionsSuccess = {
  __typename?: 'BulkCreateDepartureExtensionsSuccess';
  data: Array<Departure>;
};

export type BulkCreateDepartureInput = {
  addonPrice?: InputMaybe<Array<MoneyInput>>;
  businessCode: BusinessCode;
  departureDate: Scalars['Date'];
  exclusivityType: DepartureExclusivityType;
  extensionTourCode?: InputMaybe<Scalars['String']>;
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status: DepartureStatus;
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureRoomInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  tourCode: Scalars['String'];
};

export type BulkCreateDepartureRoomsResult = BulkCreateDepartureRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDepartureRoomsSuccess = {
  __typename?: 'BulkCreateDepartureRoomsSuccess';
  data: Array<Departure>;
};

export type BulkCreateDeparturesResult = BulkCreateDeparturesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateDeparturesSuccess = {
  __typename?: 'BulkCreateDeparturesSuccess';
  data: Array<Departure>;
};

export type BulkCreateFlightPackagesInput = {
  businessCode: BusinessCode;
  flightPackages: Array<BulkFlightPackageInput>;
};

export type BulkCreateFlightPackagesResult = BulkCreateFlightPackagesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidFlightPackageError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateFlightPackagesSuccess = {
  __typename?: 'BulkCreateFlightPackagesSuccess';
  data: Array<FlightPackage>;
};

/** Input for creating a gc departure prospect */
export type BulkCreateGcDepartureProspectsInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gcDepartureId: Scalars['ObjectId'];
  lastName?: InputMaybe<Scalars['String']>;
  levelOfInterest?: InputMaybe<GcDepartureProspectLevelOfInterest>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type BulkCreateGcDepartureProspectsResult = BulkCreateGcDepartureProspectsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidGcDepartureError | InvalidGcDepartureProspectError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreateGcDepartureProspectsSuccess = {
  __typename?: 'BulkCreateGCDepartureProspectsSuccess';
  data: Array<GcDepartureProspect>;
};

export type BulkCreatePrivateGroupsInput = {
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  priceYear: Scalars['Int'];
  rollsUpToTarget?: Scalars['Boolean'];
  tourCode: Scalars['String'];
};

export type BulkCreatePrivateGroupsResult = BulkCreatePrivateGroupsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreatePrivateGroupsSuccess = {
  __typename?: 'BulkCreatePrivateGroupsSuccess';
  data: Array<Tour>;
};

export type BulkCreatePromoTargetInput = {
  amount: Array<MoneyInput>;
  businessCode: BusinessCode;
  componentType: TripComponentType;
  departureDateFrom?: InputMaybe<Scalars['Date']>;
  departureDateTo?: InputMaybe<Scalars['Date']>;
  lineItemCode?: InputMaybe<Scalars['LineItemCode']>;
  offerCode: Scalars['String'];
  series?: InputMaybe<Scalars['Series']>;
};

export type BulkCreatePromoTargetsResult = BulkCreatePromoTargetsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPromoError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreatePromoTargetsSuccess = {
  __typename?: 'BulkCreatePromoTargetsSuccess';
  data: Array<Promo>;
};

export type BulkCreatePromosResult = BulkCreatePromosSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPromoError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkCreatePromosSuccess = {
  __typename?: 'BulkCreatePromosSuccess';
  data: Array<Promo>;
};

export type BulkFlightPackageInput = {
  arrivalGatewayCode: Scalars['String'];
  basePrice: Array<MoneyInput>;
  departureGatewayCode: Scalars['String'];
  isCustomGateway: Scalars['Boolean'];
};

export type BulkUpdateDepartureExcursionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  matchCode: Scalars['String'];
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureExcursionsResult = BulkUpdateDepartureExcursionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureExcursionsSuccess = {
  __typename?: 'BulkUpdateDepartureExcursionsSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDepartureExtensionExcursionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  /** Extension tour code of the departure */
  extensionTourCode: Scalars['String'];
  matchCode: Scalars['String'];
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  /** Tour code of the departure */
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureExtensionExcursionsResult = BulkUpdateDepartureExtensionExcursionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureExtensionExcursionsSuccess = {
  __typename?: 'BulkUpdateDepartureExtensionExcursionsSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDepartureExtensionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  /** Extension tour code of the departure */
  extensionTourCode: Scalars['String'];
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  /** Tour code of the departure */
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureExtensionRoomInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  /** Extension tour code of the departure */
  extensionTourCode: Scalars['String'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  /** Tour code of the departure */
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureExtensionRoomsResult = BulkUpdateDepartureExtensionRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureExtensionRoomsSuccess = {
  __typename?: 'BulkUpdateDepartureExtensionRoomsSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDepartureExtensionsResult = BulkUpdateDepartureExtensionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureExtensionsSuccess = {
  __typename?: 'BulkUpdateDepartureExtensionsSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDepartureInput = {
  addonPrice?: InputMaybe<Array<MoneyInput>>;
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  businessCode: BusinessCode;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  exclusivityType?: InputMaybe<DepartureExclusivityType>;
  finalInstallmentDeadline?: InputMaybe<Scalars['Date']>;
  finalPaymentDeadline?: InputMaybe<Scalars['Date']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  priceYear: Scalars['Int'];
  reservedCapacity?: InputMaybe<Scalars['Int']>;
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureOwnerInput = {
  businessCode: BusinessCode;
  departureDate: Scalars['Date'];
  ownerEmail?: InputMaybe<Scalars['Email']>;
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureOwnersResult = BulkUpdateDepartureOwnersSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureOwnersSuccess = {
  __typename?: 'BulkUpdateDepartureOwnersSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDepartureRoomInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  capacity?: InputMaybe<Scalars['Int']>;
  departureDate: Scalars['Date'];
  priceYear: Scalars['Int'];
  series: Scalars['Series'];
  status?: InputMaybe<DepartureStatus>;
  tourCode: Scalars['String'];
};

export type BulkUpdateDepartureRoomsResult = BulkUpdateDepartureRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDepartureRoomsSuccess = {
  __typename?: 'BulkUpdateDepartureRoomsSuccess';
  data: Array<Departure>;
};

export type BulkUpdateDeparturesResult = BulkUpdateDeparturesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateDeparturesSuccess = {
  __typename?: 'BulkUpdateDeparturesSuccess';
  data: Array<Departure>;
};

export type BulkUpdateExtendedStayPriceRulesInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cityGatewayCode: Scalars['String'];
  priceRules: Array<PriceRuleInput>;
};

export type BulkUpdateExtendedStayPriceRulesResult = BulkUpdateExtendedStayPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateExtendedStayPriceRulesSuccess = {
  __typename?: 'BulkUpdateExtendedStayPriceRulesSuccess';
  data: Array<ExtendedStay>;
};

export type BulkUpdateFeePriceRulesInput = {
  businessCode: BusinessCode;
  categoryCode: Scalars['FeeCategoryCode'];
  priceRules: Array<PriceRuleInput>;
  typeCode: FeeTypeCode;
};

export type BulkUpdateFeePriceRulesResult = BulkUpdateFeePriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateFeePriceRulesSuccess = {
  __typename?: 'BulkUpdateFeePriceRulesSuccess';
  data: Array<Fee>;
};

export type BulkUpdateFlightPackagePriceRulesInput = {
  arrivalGatewayCode: Scalars['String'];
  businessCode: BusinessCode;
  departureGatewayCode: Scalars['String'];
  priceRules: Array<PriceRuleInput>;
};

export type BulkUpdateFlightPackagePriceRulesResult = BulkUpdateFlightPackagePriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateFlightPackagePriceRulesSuccess = {
  __typename?: 'BulkUpdateFlightPackagePriceRulesSuccess';
  data: Array<FlightPackage>;
};

export type BulkUpdateInsurancePlanAddonPriceRulesInput = {
  addonPlanCode: Scalars['String'];
  businessCode: BusinessCode;
  planCode: Scalars['String'];
  priceRules: Array<PriceRuleInput>;
};

export type BulkUpdateInsurancePlanAddonPriceRulesResult = BulkUpdateInsurancePlanAddonPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateInsurancePlanAddonPriceRulesSuccess = {
  __typename?: 'BulkUpdateInsurancePlanAddonPriceRulesSuccess';
  data: Array<InsurancePlan>;
};

export type BulkUpdateInsurancePlanPriceRulesInput = {
  businessCode: BusinessCode;
  planCode: Scalars['String'];
  priceRules: Array<PriceRuleInput>;
};

export type BulkUpdateInsurancePlanPriceRulesResult = BulkUpdateInsurancePlanPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateInsurancePlanPriceRulesSuccess = {
  __typename?: 'BulkUpdateInsurancePlanPriceRulesSuccess';
  data: Array<InsurancePlan>;
};

export type BulkUpdatePrivateGroupPriceRulesInput = {
  businessCode: BusinessCode;
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
  tourCode: Scalars['String'];
};

export type BulkUpdatePrivateGroupPriceRulesResult = BulkUpdatePrivateGroupPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdatePrivateGroupPriceRulesSuccess = {
  __typename?: 'BulkUpdatePrivateGroupPriceRulesSuccess';
  data: Array<Tour>;
};

export type BulkUpdatePrivateGroupsInput = {
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  priceYear: Scalars['Int'];
  rollsUpToTarget?: Scalars['Boolean'];
  status?: InputMaybe<TripComponentStatus>;
  tourCode: Scalars['String'];
};

export type BulkUpdatePrivateGroupsResult = BulkUpdatePrivateGroupsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdatePrivateGroupsSuccess = {
  __typename?: 'BulkUpdatePrivateGroupsSuccess';
  data: Array<Tour>;
};

export type BulkUpdatePromoInput = {
  businessCode: BusinessCode;
  displayName?: InputMaybe<Scalars['String']>;
  effectiveFrom?: InputMaybe<Scalars['DateTime']>;
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
  exclusivity?: InputMaybe<PromoExclusivity>;
  isGroupsOnly?: InputMaybe<Scalars['Boolean']>;
  isHiddenFromSales?: InputMaybe<Scalars['Boolean']>;
  isIndiesOnly?: InputMaybe<Scalars['Boolean']>;
  isShareable?: InputMaybe<Scalars['Boolean']>;
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  offerCode: Scalars['String'];
  promoCategory?: InputMaybe<PromoCategory>;
  status?: InputMaybe<TripComponentStatus>;
};

export type BulkUpdatePromosResult = BulkUpdatePromosSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPromoError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdatePromosSuccess = {
  __typename?: 'BulkUpdatePromosSuccess';
  data: Array<Promo>;
};

export type BulkUpdateTourExcursionInput = {
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  businessCode: BusinessCode;
  matchCode: Scalars['String'];
  /** The minimum number of travelers required for this excursion to be confirmed. */
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
  priceYear: Scalars['Int'];
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type BulkUpdateTourExcursionPriceRulesInput = {
  businessCode: BusinessCode;
  matchCode: Scalars['String'];
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
};

export type BulkUpdateTourExcursionPriceRulesResult = BulkUpdateTourExcursionPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExcursionPriceRulesSuccess = {
  __typename?: 'BulkUpdateTourExcursionPriceRulesSuccess';
  data: Array<TourExcursion>;
};

export type BulkUpdateTourExcursionsResult = BulkUpdateTourExcursionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExcursionsSuccess = {
  __typename?: 'BulkUpdateTourExcursionsSuccess';
  data: Array<TourExcursion>;
};

export type BulkUpdateTourExtensionInput = {
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  businessCode: BusinessCode;
  /** The default capacity that will set for Departures created from this Tour Extension. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreExtension?: InputMaybe<Scalars['Boolean']>;
  minimumCapacity?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  priceYear: Scalars['Int'];
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  tourCode: Scalars['String'];
};

export type BulkUpdateTourExtensionPriceRulesInput = {
  businessCode: BusinessCode;
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
  tourCode: Scalars['String'];
};

export type BulkUpdateTourExtensionPriceRulesResult = BulkUpdateTourExtensionPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExtensionPriceRulesSuccess = {
  __typename?: 'BulkUpdateTourExtensionPriceRulesSuccess';
  data: Array<TourExtension>;
};

export type BulkUpdateTourExtensionRoomInput = {
  basePrice?: InputMaybe<Array<MoneyInput>>;
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  /** The capacity that will set for Departure Rooms created from this Tour Extension Room. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: InputMaybe<Scalars['Boolean']>;
  priceYear: Scalars['Int'];
  status?: InputMaybe<TripComponentStatus>;
  tourCode: Scalars['String'];
};

export type BulkUpdateTourExtensionRoomPriceRulesInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
  tourCode: Scalars['String'];
};

export type BulkUpdateTourExtensionRoomPriceRulesResult = BulkUpdateTourExtensionRoomPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExtensionRoomPriceRulesSuccess = {
  __typename?: 'BulkUpdateTourExtensionRoomPriceRulesSuccess';
  data: Array<TourExtension>;
};

export type BulkUpdateTourExtensionRoomsResult = BulkUpdateTourExtensionRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExtensionRoomsSuccess = {
  __typename?: 'BulkUpdateTourExtensionRoomsSuccess';
  data: Array<TourExtension>;
};

export type BulkUpdateTourExtensionsResult = BulkUpdateTourExtensionsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourExtensionsSuccess = {
  __typename?: 'BulkUpdateTourExtensionsSuccess';
  data: Array<TourExtension>;
};

export type BulkUpdateTourInput = {
  activityLevel?: InputMaybe<Scalars['Int']>;
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  businessCode: BusinessCode;
  classLevel?: InputMaybe<Scalars['Int']>;
  /** The default capacity that will set for Departures created from this Tour. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  deposit?: InputMaybe<Array<MoneyInput>>;
  depositForAutomaticPlan?: InputMaybe<Array<MoneyInput>>;
  depositForLateAdd?: InputMaybe<Array<MoneyInput>>;
  feeCategoryCode?: InputMaybe<Scalars['FeeCategoryCode']>;
  finalInstallmentDeadlineDpd?: InputMaybe<Scalars['Int']>;
  finalPaymentDeadlineDpd?: InputMaybe<Scalars['Int']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isNonRevenueTour?: InputMaybe<Scalars['Boolean']>;
  isPassportRequired?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isVisaRequired?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  minimumCapacity?: InputMaybe<Scalars['Int']>;
  nbDepartureFlightDays?: InputMaybe<Scalars['Int']>;
  nbReturningFlightDays?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  priceYear: Scalars['Int'];
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  tourCode: Scalars['String'];
};

export type BulkUpdateTourPriceRulesInput = {
  businessCode: BusinessCode;
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
  tourCode: Scalars['String'];
};

export type BulkUpdateTourPriceRulesResult = BulkUpdateTourPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourPriceRulesSuccess = {
  __typename?: 'BulkUpdateTourPriceRulesSuccess';
  data: Array<Tour>;
};

export type BulkUpdateTourRoomInput = {
  basePrice?: InputMaybe<Array<MoneyInput>>;
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  /** The capacity that will set for Departure Rooms created from this Tour Room.  */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: InputMaybe<Scalars['Boolean']>;
  priceYear: Scalars['Int'];
  status?: InputMaybe<TripComponentStatus>;
  tourCode: Scalars['String'];
};

export type BulkUpdateTourRoomPriceRulesInput = {
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  priceRules: Array<PriceRuleInput>;
  priceYear: Scalars['Int'];
  tourCode: Scalars['String'];
};

export type BulkUpdateTourRoomPriceRulesResult = BulkUpdateTourRoomPriceRulesSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourRoomPriceRulesSuccess = {
  __typename?: 'BulkUpdateTourRoomPriceRulesSuccess';
  data: Array<Tour>;
};

export type BulkUpdateTourRoomsResult = BulkUpdateTourRoomsSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateTourRoomsSuccess = {
  __typename?: 'BulkUpdateTourRoomsSuccess';
  data: Array<Tour>;
};

export type BulkUpdateToursResult = BulkUpdateToursSuccess | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type BulkUpdateToursSuccess = {
  __typename?: 'BulkUpdateToursSuccess';
  data: Array<Tour>;
};

/** Represents a business tenant */
export type Business = {
  __typename?: 'Business';
  /** The unique identifier of the business */
  _id: Scalars['ObjectId'];
  code: BusinessCode;
  name: Scalars['String'];
  priceYears: Array<PriceYear>;
  settings: BusinessSettings;
};

export enum BusinessCode {
  Adv = 'ADV',
  Gat = 'GAT',
  Ub = 'UB'
}

/** The CurrentUser is not authorized to perform the requested action */
export type BusinessNotSpecifiedError = DomainError & {
  __typename?: 'BusinessNotSpecifiedError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Object that represents settings specific to a business */
export type BusinessSettings = {
  __typename?: 'BusinessSettings';
  departures: DepartureSettings;
  evaluations: EvaluationSettings;
  fees: FeeSettings;
  flights: FlightSettings;
  gcDepartures: GcDepartureSettings;
  insurance: InsuranceSettings;
  integrations?: Maybe<IntegrationSettings>;
  orders: OrderSettings;
  promos: PromoSettings;
  reviews: ReviewSettings;
  tripChecklists: TripChecklistSettings;
  tsa: TsaSettings;
};

export type CalculatePaymentScheduleInput = {
  frequency: PaymentScheduleFrequency;
  /** An initial amount paid that will discount the payments in the schedule. */
  initialPayment?: InputMaybe<MoneyInput>;
  /** The date of the first scheduled payment. Defaults to the earliest allowed first payment date. */
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CalculatePaymentScheduleResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPaymentScheduleError | NotAuthorizedError | NotFoundError | PaymentSchedule | ValidationError;

/** A collection of promotions that are conditionally applied. */
export type Campaign = {
  __typename?: 'Campaign';
  _id: Scalars['ObjectId'];
  campaignCode: Scalars['String'];
  displayName: Scalars['String'];
  /** The date when the promo is applicable and can be used */
  effectiveFrom: Scalars['DateTime'];
  /** The date when the promo expires and is no longer valid. */
  effectiveTo: Scalars['DateTime'];
  isValid: Scalars['Boolean'];
  /** The number of promos associated with the campaign. */
  nbPromos?: Maybe<Scalars['Int']>;
  promoIds: Array<Scalars['ObjectId']>;
  status: CampaignStatus;
};

/** A paginated result of campaigns. */
export type CampaignPaginatedResult = {
  __typename?: 'CampaignPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Campaign>;
};

export enum CampaignStatus {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type CancelDraftBookingInput = {
  /** The draft booking ID */
  _id: Scalars['ObjectId'];
  /** The business reason for the cancellation */
  cancellationCategory: TripCancellationCategory;
  /** A free-text reason for the cancellation */
  cancellationReason: Scalars['String'];
  /** Whether or not to waive the cancellation fee */
  waiveFee?: InputMaybe<Scalars['Boolean']>;
};

export type CancelDraftBookingPaymentScheduleResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPaymentError | NotAuthorizedError | NotFoundError | ValidationError;

export type CancelDraftBookingResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type CancelDraftBookingTripsInput = {
  /** The draft booking ID */
  _id: Scalars['ObjectId'];
  /** The business reason for the cancellation */
  cancellationCategory: TripCancellationCategory;
  /** A free-text reason for the cancellation */
  cancellationReason: Scalars['String'];
  /** The new primary trip ID, if the primary trip is being cancelled but not all trips are being cancelled */
  newPrimaryTripId?: InputMaybe<Scalars['ObjectId']>;
  /** The trip IDs to cancel */
  tripIds: Array<Scalars['ObjectId']>;
  /** Whether or not to waive the cancellation fee */
  waiveFee?: InputMaybe<Scalars['Boolean']>;
};

export type CancelDraftBookingTripsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type CancelGcDepartureInput = {
  /** The ID of the GC Departure to cancel */
  _id: Scalars['ObjectId'];
  /** The business reason for cancelling the GC Departure */
  cancellationCategory: GcDepartureCancellationCategory;
  /** The reason for cancelling the GC Departure */
  reason: Scalars['String'];
};

export type CancelGcDepartureResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidDateError | InvalidGcDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export enum CardBrand {
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod';
  address?: Maybe<Address>;
  cardBrand: CardBrand;
  cardNumberMask: Scalars['String'];
  cardType: CardType;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  firstName: Scalars['String'];
  isExpired: Scalars['Boolean'];
  lastName: Scalars['String'];
  processorPaymentMethodId?: Maybe<Scalars['Int']>;
};

export enum CardType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Unknown = 'UNKNOWN'
}

/** A category organizes tags into a hierarchy */
export type Category = {
  __typename?: 'Category';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tags: Array<Tag>;
};

/** A paginated set of Categories */
export type CategoryPaginatedResult = {
  __typename?: 'CategoryPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Category>;
};

/** Inputs necessary to change the expiration date of a voucher */
export type ChangeVoucherExpirationInput = {
  _id: Scalars['ObjectId'];
  expirationDate: Scalars['Date'];
};

export type ChangeVoucherExpirationResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

export enum CheckListItemImportanceLevel {
  HighlyRecommended = 'HIGHLY_RECOMMENDED',
  Recommended = 'RECOMMENDED',
  Required = 'REQUIRED'
}

export type CheckPaymentMethod = {
  __typename?: 'CheckPaymentMethod';
  checkNumber?: Maybe<Scalars['String']>;
  isFromLockbox: Scalars['Boolean'];
};

export type CheckPaymentMethodInput = {
  /** The 3-6 digit number to identify the check */
  checkNumber?: InputMaybe<Scalars['String']>;
  /** Date we received the check */
  checkReceivedDate?: InputMaybe<Scalars['Date']>;
  /** Was this check processed via lockbox */
  isFromLockBox?: InputMaybe<Scalars['Boolean']>;
};

export type CheckTripChecklistItemResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTripChecklistItemTemplateError | NotAuthorizedError | NotFoundError | TripChecklistItemState | ValidationError;

export type City = Place & {
  __typename?: 'City';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  parentIds: Array<Scalars['ObjectId']>;
  type: PlaceType;
};

export type ConfirmGcDepartureResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidDateError | InvalidGcDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type ContactInfoContent = {
  __typename?: 'ContactInfoContent';
  address: ContactInfoContentAddress;
  groupSales?: Maybe<ContactInfoContentDepartment>;
  groupService?: Maybe<ContactInfoContentDepartment>;
  id: Scalars['String'];
  indiesSales?: Maybe<ContactInfoContentDepartment>;
  indiesService?: Maybe<ContactInfoContentDepartment>;
  onTourSupport?: Maybe<ContactInfoContentDepartment>;
};

export type ContactInfoContentAddress = {
  __typename?: 'ContactInfoContentAddress';
  addressName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Contact information for a specific department. Null for workHours just indicates that work hours have not been configured. */
export type ContactInfoContentDepartment = {
  __typename?: 'ContactInfoContentDepartment';
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberFormatted?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  workHoursFriday?: Maybe<ContactInfoContentWorkHours>;
  workHoursMonday?: Maybe<ContactInfoContentWorkHours>;
  workHoursSaturday?: Maybe<ContactInfoContentWorkHours>;
  workHoursSunday?: Maybe<ContactInfoContentWorkHours>;
  workHoursThursday?: Maybe<ContactInfoContentWorkHours>;
  workHoursTuesday?: Maybe<ContactInfoContentWorkHours>;
  workHoursWednesday?: Maybe<ContactInfoContentWorkHours>;
};

export type ContactInfoContentWorkHours = {
  __typename?: 'ContactInfoContentWorkHours';
  endTime?: Maybe<Scalars['String']>;
  /** Override startTime and endTime to indicate that office is closed. */
  isClosed?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
};

/** Ancestor stories of content. Useful for generating breadcrumbs. */
export type ContentAncestor = {
  __typename?: 'ContentAncestor';
  fullSlug: Scalars['String'];
  name: Scalars['String'];
};

export type ContentAsset = {
  __typename?: 'ContentAsset';
  alt?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  focus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Storyblok link type */
export type ContentLink = {
  __typename?: 'ContentLink';
  cachedUrl?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkType?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Blok containing text and a link */
export type ContentTextLink = {
  __typename?: 'ContentTextLink';
  link?: Maybe<ContentLink>;
  text?: Maybe<Scalars['String']>;
};

export type Continent = Place & {
  __typename?: 'Continent';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  type: PlaceType;
};

export type Country = Place & {
  __typename?: 'Country';
  _id: Scalars['ObjectId'];
  /** Two-letter country code as defined in ISO 3166 */
  alpha2Code: Scalars['String'];
  /** Three-letter country code as defined in ISO 3166 */
  alpha3Code: Scalars['String'];
  name: Scalars['String'];
  parentIds: Array<Scalars['ObjectId']>;
  type: PlaceType;
};

export type CountryRegion = Place & {
  __typename?: 'CountryRegion';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  parentIds: Array<Scalars['ObjectId']>;
  type: PlaceType;
};

export type CreateAdjustmentInput = {
  adjustmentCode: Scalars['String'];
  businessCode: BusinessCode;
  displayName: Scalars['String'];
  isRefundable?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<CreateAdjustmentMetaInput>;
  persistsOnCancelAndRebook?: InputMaybe<Scalars['Boolean']>;
  rollsUpToTarget?: InputMaybe<Scalars['Boolean']>;
  tagIds?: Array<Scalars['ObjectId']>;
};

export type CreateAdjustmentMetaInput = {
  isCompanionDiscount: Scalars['Boolean'];
  isFreePlace: Scalars['Boolean'];
  isStaffOnTour: Scalars['Boolean'];
};

export type CreateAdjustmentResult = Adjustment | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateApiKeyInput = {
  description: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  roleIds: Array<Scalars['ObjectId']>;
};

export type CreateApiKeyResult = ApiKeyResult | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateCampaignInput = {
  businessCode: BusinessCode;
  campaignCode: Scalars['String'];
  displayName: Scalars['String'];
  effectiveFrom: Scalars['DateTime'];
  effectiveTo: Scalars['DateTime'];
  promoIds: Array<Scalars['ObjectId']>;
};

export type CreateCampaignResult = BusinessNotSpecifiedError | Campaign | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Create a new category */
export type CreateCategoryInput = {
  businessCode: BusinessCode;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type CreateCategoryResult = BusinessNotSpecifiedError | Category | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Create a new customer */
export type CreateCustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
};

export type CreateCustomerResult = BusinessNotSpecifiedError | Customer | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDepartureExcursionInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  lineItemCode: Scalars['LineItemCode'];
};

export type CreateDepartureExcursionResult = BusinessNotSpecifiedError | DepartureExcursion | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDepartureExtensionExcursionInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  lineItemCode: Scalars['LineItemCode'];
};

export type CreateDepartureExtensionExcursionResult = BusinessNotSpecifiedError | DepartureExcursion | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDepartureExtensionInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  lineItemCode: Scalars['LineItemCode'];
};

export type CreateDepartureExtensionResult = BusinessNotSpecifiedError | DepartureExtension | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDepartureExtensionRoomInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  lineItemCode: Scalars['LineItemCode'];
};

export type CreateDepartureExtensionRoomResult = BusinessNotSpecifiedError | DepartureRoom | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDepartureRoomInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  lineItemCode: Scalars['LineItemCode'];
};

export type CreateDepartureRoomResult = BusinessNotSpecifiedError | DepartureRoom | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidTourError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateDraftOrderResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Create a draft term */
export type CreateDraftTermInput = {
  businessCode: BusinessCode;
  certificationText?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['JSON']>;
  market: TermMarket;
  termType: TermType;
};

export type CreateDraftTermResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Term | ValidationError;

export type CreateExtendedStayInput = {
  basePrice: Array<MoneyInput>;
  bedCode: TourBedCode;
  businessCode: BusinessCode;
  cityGatewayCode: Scalars['String'];
};

export type CreateExtendedStayResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | ExtendedStay | InvalidDateError | InvalidExtendedStayError | NotAuthorizedError | NotFoundError | ValidationError;

/** Create a new fee */
export type CreateFeeInput = {
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  categoryCode: Scalars['FeeCategoryCode'];
  isRefundable?: InputMaybe<Scalars['Boolean']>;
  rollsUpToTarget?: InputMaybe<Scalars['Boolean']>;
  typeCode: FeeTypeCode;
};

export type CreateFeeResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | Fee | InvalidDateError | InvalidFeeError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateFlightPackageInput = {
  arrivalGatewayCode: Scalars['String'];
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  departureGatewayCode: Scalars['String'];
  isCustomGateway: Scalars['Boolean'];
};

export type CreateFlightPackageResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | FlightPackage | InvalidDateError | InvalidFlightPackageError | NotAuthorizedError | NotFoundError | ValidationError;

/** Input for creating a GC Departure */
export type CreateGcDepartureInput = {
  companionDiscountRate: Scalars['Float'];
  departureId: Scalars['ObjectId'];
  expectedVolume?: InputMaybe<Scalars['Int']>;
  freeInsuranceRatio: Scalars['FreePlaceRatio'];
  freePlaceRatio: Scalars['FreePlaceRatio'];
  groupCoordinatorCustomerId: Scalars['ObjectId'];
  groupSizeEstimate?: InputMaybe<Scalars['Int']>;
  includePrivateGroupFee?: InputMaybe<Scalars['Boolean']>;
  lineItemRules?: InputMaybe<Array<CreateGcDepartureLineItemRuleInput>>;
  micrositeUrl?: InputMaybe<Scalars['String']>;
  opportunityId?: InputMaybe<Scalars['String']>;
  primaryCurrencyCode: CurrencyCode;
  recruitmentMeetingDate?: InputMaybe<Scalars['Date']>;
  stipendRate: Scalars['Float'];
};

/** The input for creating a GC Departure Line Item Rule */
export type CreateGcDepartureLineItemRuleInput = {
  level: GcDepartureRuleLevel;
  lineItemCode: Scalars['LineItemCode'];
  lineItemType: GcDepartureRuleLineItemType;
};

export type CreateGcDepartureResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidDateError | InvalidGcDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateInsuranceExtensionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsuranceExtension | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateInsurancePlanAddonInput = {
  basePrice: Array<MoneyInput>;
  includedWithinDays?: InputMaybe<Scalars['Int']>;
  /** A code specific to this insurance plan addon */
  insurancePlanAddonCode: Scalars['String'];
  internalName: Scalars['String'];
  preventPurchaseAfterDays?: InputMaybe<Scalars['Int']>;
};

export type CreateInsurancePlanAddonResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlanAddon | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateInsurancePlanInput = {
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  internalName: Scalars['String'];
  isEligibleForRolloverOnRebooking?: InputMaybe<Scalars['Boolean']>;
  planCode: Scalars['String'];
  policyCoversPreExistingConditions?: InputMaybe<Scalars['Boolean']>;
  policyNumber: Scalars['String'];
  providerName: Scalars['String'];
  /** The insurance plan will become available starting from the specified number of days after the booking date */
  purchasableAfterDays?: InputMaybe<Scalars['Int']>;
  /** The insurance plan will be available during the quote process and for the specified number of days after booking */
  purchasableWithinDays?: InputMaybe<Scalars['Int']>;
};

export type CreateInsurancePlanResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlan | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type CreateNoteInput = {
  content: Scalars['String'];
  /** The id of the entity this note applies to */
  targetId: Scalars['ObjectId'];
};

export type CreateNoteResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Note | ValidationError;

export type CreatePromoInput = {
  businessCode: BusinessCode;
  customerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  displayName: Scalars['String'];
  effectiveFrom: Scalars['DateTime'];
  effectiveTo: Scalars['DateTime'];
  exclusivity: PromoExclusivity;
  gcDepartureIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isGroupsOnly?: Scalars['Boolean'];
  isHiddenFromSales?: Scalars['Boolean'];
  isIndiesOnly?: Scalars['Boolean'];
  isShareable?: Scalars['Boolean'];
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  offerCode: Scalars['String'];
  promoCategory?: InputMaybe<PromoCategory>;
  promoCodes: Array<Scalars['String']>;
};

export type CreatePromoResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPromoError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

/** Create a new promo target */
export type CreatePromoTargetInput = {
  amount: Array<MoneyInput>;
  componentType: TripComponentType;
  departureDateFrom?: InputMaybe<Scalars['Date']>;
  departureDateTo?: InputMaybe<Scalars['Date']>;
  lineItemCode?: InputMaybe<Scalars['LineItemCode']>;
  series?: InputMaybe<Scalars['Series']>;
};

/** Written review from the Customer */
export type CreateReviewDetailInput = {
  author?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

/** Create a new review */
export type CreateReviewInput = {
  detail?: InputMaybe<CreateReviewDetailInput>;
  rating: Scalars['Int'];
  tripId: Scalars['ObjectId'];
};

export type CreateReviewResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidRatingError | InvalidReviewError | InvalidReviewResponseError | NotAuthorizedError | NotFoundError | Review | ValidationError;

export type CreateRoleInput = {
  businessCode?: InputMaybe<BusinessCode>;
  name: Scalars['String'];
  permissionKeys: Array<PermissionKey>;
};

export type CreateRoleResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Role | ValidationError;

export type CreateServiceRecoveryPromoInput = {
  amount: Scalars['Float'];
  businessCode: BusinessCode;
  expiryDate: Scalars['DateTime'];
  notes?: InputMaybe<Scalars['String']>;
  offerCode: Scalars['String'];
  recoveryReason: Scalars['String'];
  tripId: Scalars['ObjectId'];
};

export type CreateServiceRecoveryPromoResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPromoError | NotAuthorizedError | NotFoundError | ServiceRecovery | ValidationError;

export type CreateSupportTicketResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | SupportTicketError | SupportTicketSuccess | ValidationError;

/** Create a new tag */
export type CreateTagInput = {
  categoryId: Scalars['ObjectId'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTagResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Tag | ValidationError;

export type CreateTourExcursionInput = {
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  matchCode: Scalars['String'];
  minNbTravelersRequired: Scalars['Int'];
  placeIds?: Array<Scalars['ObjectId']>;
  priceYear: Scalars['Int'];
  tagIds?: Array<Scalars['ObjectId']>;
};

export type CreateTourExcursionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourExcursion | ValidationError;

export type CreateTourExtensionInput = {
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  /** The default capacity that will set for departures created from this Extension. */
  defaultCapacity: Scalars['Int'];
  isFlightShoppingAllowed?: Scalars['Boolean'];
  isPreExtension?: Scalars['Boolean'];
  minimumCapacity?: Scalars['Int'];
  placeIds?: Array<Scalars['ObjectId']>;
  priceYear: Scalars['Int'];
  rooms?: Array<CreateTourRoomInput>;
  tagIds?: Array<Scalars['ObjectId']>;
  /** The extension tour code. */
  tourCode: Scalars['String'];
};

export type CreateTourExtensionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourExtension | ValidationError;

export type CreateTourExtensionRoomInput = {
  basePrice: Array<MoneyInput>;
  bedCode: TourBedCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  /** The default capacity that will set for Departure Rooms created from this Tour Room. Defaults to the Tour Extension capacity */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: Scalars['Boolean'];
};

export type CreateTourExtensionRoomResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

/** Create a new tour in a specific tour year */
export type CreateTourInput = {
  activityLevel?: Scalars['Int'];
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Array<MoneyInput>;
  businessCode: BusinessCode;
  classLevel?: Scalars['Int'];
  defaultCapacity: Scalars['Int'];
  deposit: Array<MoneyInput>;
  depositForAutomaticPlan: Array<MoneyInput>;
  depositForLateAdd: Array<MoneyInput>;
  feeCategoryCode: Scalars['FeeCategoryCode'];
  finalInstallmentDeadlineDpd: Scalars['Int'];
  finalPaymentDeadlineDpd: Scalars['Int'];
  isFlightShoppingAllowed: Scalars['Boolean'];
  isNonRevenueTour: Scalars['Boolean'];
  isPassportRequired: Scalars['Boolean'];
  isPostStayAllowed: Scalars['Boolean'];
  isPreStayAllowed: Scalars['Boolean'];
  isVisaRequired: Scalars['Boolean'];
  isWaitlistAllowed: Scalars['Boolean'];
  minimumCapacity?: Scalars['Int'];
  nbDepartureFlightDays: Scalars['Int'];
  nbReturningFlightDays: Scalars['Int'];
  placeIds?: Array<Scalars['ObjectId']>;
  priceYear: Scalars['Int'];
  rooms?: Array<CreateTourRoomInput>;
  tagIds?: Array<Scalars['ObjectId']>;
  tourCode: Scalars['String'];
};

export type CreateTourResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type CreateTourRoomInput = {
  basePrice: Array<MoneyInput>;
  bedCode: TourBedCode;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  /** The default capacity that will set for Departure Rooms created from this Tour Room. Defaults to the Tour capacity. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: Scalars['Boolean'];
};

export type CreateTourRoomResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

export type CreateTripChecklistItemTemplateInput = {
  businessCode: BusinessCode;
  description: Scalars['String'];
  dpd: Scalars['Int'];
  effectiveFrom: Scalars['Date'];
  effectiveTo: Scalars['Date'];
  excludeTourCodes?: InputMaybe<Array<Scalars['String']>>;
  importanceLevel: CheckListItemImportanceLevel;
  includeTourCodes?: InputMaybe<Array<Scalars['String']>>;
  internalName: Scalars['String'];
  title: Scalars['String'];
};

export type CreateTripChecklistItemTemplateResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTripChecklistItemTemplateError | NotAuthorizedError | NotFoundError | TripChecklistItemTemplate | ValidationError;

/** Inputs necessary to create a voucher that originates from a booking */
export type CreateVoucherFromBookingInput = {
  bookingId: Scalars['ObjectId'];
  customerId: Scalars['ObjectId'];
  expirationDate: Scalars['Date'];
  initialAmount: MoneyInput;
  isRefundable: Scalars['Boolean'];
  /** @deprecated Use voucherReason instead */
  reason?: InputMaybe<CreditReasonCode>;
  voucherReason?: InputMaybe<VoucherReason>;
};

export type CreateVoucherFromBookingResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidMoneyError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

/** Create a voucher that originates from a different system and non WOJO EF Product */
export type CreateVoucherFromExternalInput = {
  businessCode: BusinessCode;
  customerId: Scalars['ObjectId'];
  expirationDate: Scalars['Date'];
  initialAmount: MoneyInput;
  isRefundable: Scalars['Boolean'];
  transferBusinessUnit: Scalars['String'];
};

export type CreateVoucherFromExternalResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

/** Creates a new wishlist item */
export type CreateWishlistItemInput = {
  businessCode: BusinessCode;
  customerId: Scalars['ObjectId'];
  tourCode: Scalars['String'];
};

export type CreateWishlistItemResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError | WishlistItem;

/** A transaction that debits a payment method */
export type CreditPaymentTransaction = PaymentTransaction & {
  __typename?: 'CreditPaymentTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  /** The customer of this transaction. */
  customerId: Scalars['ObjectId'];
  displayStatus: PaymentTransactionDisplayStatus;
  paymentMethod: TransactionPaymentMethod;
  paymentSysResponseCode?: Maybe<Scalars['String']>;
  paymentSysResponseMessage?: Maybe<Scalars['String']>;
  processorTransactionId?: Maybe<Scalars['Int']>;
  reasonCode: CreditReasonCode;
  reconciledBy?: Maybe<Employee>;
  referenceNumber?: Maybe<Scalars['String']>;
  settlementDate?: Maybe<Scalars['Date']>;
  /** The status of this transaction. */
  status: PaymentTransactionStatus;
  transactionDate: Scalars['DateTime'];
};

/** The reason code for a credit transaction */
export enum CreditReasonCode {
  /** The transaction was cancelled */
  Cancellation = 'CANCELLATION',
  /** Funds were returned for an unspecified reason */
  Optional = 'OPTIONAL',
  /** The customer overpaid. */
  Overpayment = 'OVERPAYMENT',
  /** The amount was transferred to another order. */
  Transfer = 'TRANSFER',
  /** The amount was converted into a voucher. */
  Voucher = 'VOUCHER'
}

export enum CurrencyCode {
  Cad = 'CAD',
  Usd = 'USD'
}

/** An Abe customer and its associated data */
export type Customer = User & {
  __typename?: 'Customer';
  _id: Scalars['ObjectId'];
  address?: Maybe<Address>;
  bio?: Maybe<Scalars['String']>;
  /** Get a customer's booked trips by business code */
  bookingTrips: Array<BookingTrip>;
  /** Get a customer's canceled trips by business code */
  cancelledTrips: Array<BookingTrip>;
  /** Get the chat user ID for the customer for the given business code */
  chatUserId?: Maybe<Scalars['String']>;
  /** Get GC Departures for Group Coordinators */
  confirmedGCDepartures: Array<GcDeparture>;
  /** Get a customer's pre-2018 confirmed trips by business code */
  confirmedOldTrips: Array<OldTrip>;
  /** Get a customer's confirmed trips by business code */
  confirmedTrips: Array<BookingTrip>;
  customerId: Scalars['ObjectId'];
  email?: Maybe<Scalars['Email']>;
  /** The customer's Facebook URL */
  facebookUrl?: Maybe<Scalars['String']>;
  facebookUsername?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** ID used for resolving in the global rewards system. Currently Phoenix CustomerNumber */
  globalRewardsId?: Maybe<Scalars['String']>;
  /** Check if the customer has any GC Departures */
  hasGCDepartures: Scalars['Boolean'];
  /** The customer's Instagram URL */
  instagramUrl?: Maybe<Scalars['String']>;
  instagramUsername?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  legacyIds?: Maybe<LegacyCustomerIds>;
  /** Get the loyalty level for the customer */
  loyaltyLevel?: Maybe<LoyaltyLevel>;
  /**
   * Get a customer's on hold trips by business code
   * @deprecated No longer a valid status
   */
  onHoldTrips: Array<BookingTrip>;
  /** Get past GC Departure Prospects for Group Coordinators */
  pastGCDepartureProspects: Array<GcDepartureProspect>;
  /** Get past Group Members for Group Coordinator */
  pastGroupMembers: Array<GcPastGroupMember>;
  /** Get a customer's pending rebooking trips by business code */
  pendingRebookingTrips: Array<BookingTrip>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']>;
  /** The customer's profile image asset */
  profileImageAsset?: Maybe<Asset>;
  /** Get a customer's rebooked trips by business code */
  rebookedTrips: Array<BookingTrip>;
  /** Get a customer's requested trips by business code */
  requestedTrips: Array<BookingTrip>;
  /** Get a customer's saved payment methods (optionally) by business code */
  savedPaymentMethods: Array<SavedPaymentMethod>;
  /** The customer's Snapchat URL */
  snapchatUrl?: Maybe<Scalars['String']>;
  snapchatUsername?: Maybe<Scalars['String']>;
  /** The customer's TikTok URL */
  tiktokUrl?: Maybe<Scalars['String']>;
  tiktokUsername?: Maybe<Scalars['String']>;
  /** Get a customer's vouchers */
  vouchers: Array<Voucher>;
  /** Get a customer's waitlisted trips by business code */
  waitlistedTrips: Array<BookingTrip>;
  /** Get a customer's wishlist items (optionally) by business code */
  wishlist: Array<WishlistItem>;
  /** The customer's X URL */
  xUrl?: Maybe<Scalars['String']>;
  xUsername?: Maybe<Scalars['String']>;
};


/** An Abe customer and its associated data */
export type CustomerBookingTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerCancelledTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerChatUserIdArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerConfirmedGcDeparturesArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerConfirmedOldTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerConfirmedTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerHasGcDeparturesArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerLoyaltyLevelArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerOnHoldTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerPastGcDepartureProspectsArgs = {
  businessCodes?: InputMaybe<Array<BusinessCode>>;
};


/** An Abe customer and its associated data */
export type CustomerPastGroupMembersArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerPendingRebookingTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerRebookedTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerRequestedTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerSavedPaymentMethodsArgs = {
  businessCode?: InputMaybe<BusinessCode>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};


/** An Abe customer and its associated data */
export type CustomerWaitlistedTripsArgs = {
  businessCode: BusinessCode;
};


/** An Abe customer and its associated data */
export type CustomerWishlistArgs = {
  businessCode?: InputMaybe<BusinessCode>;
};

export type CustomerCredentialOptions = {
  __typename?: 'CustomerCredentialOptions';
  hasEmail: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
};

/** Customer evaluation type */
export type CustomerEvaluation = Evaluation & {
  __typename?: 'CustomerEvaluation';
  _id: Scalars['ObjectId'];
  /** The date that the evaluation is available */
  availabilityDate: Scalars['DateTime'];
  businessCode?: Maybe<BusinessCode>;
  /** The date that the evaluation is due */
  deadlineDate: Scalars['DateTime'];
  /** The departure ID of the departure that this evaluation belong to */
  departureId: Scalars['ObjectId'];
  evaluationType: EvaluationType;
  /** The date that the evaluation was finalized */
  finalizationDate?: Maybe<Scalars['DateTime']>;
  hasAnsweredQuestions: Scalars['Boolean'];
  /** Whether the evaluation has been started */
  hasBeenStarted: Scalars['Boolean'];
  /** The NPS score of the evaluation */
  npsScore?: Maybe<Scalars['Int']>;
  questions: Array<EvaluationQuestion>;
  /** The date that the evaluation was submitted */
  submissionDate?: Maybe<Scalars['DateTime']>;
  templateId: Scalars['ObjectId'];
  /** The trip ID of the booking trip that this evaluation belong to */
  tripId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

/** Customer evaluation settings */
export type CustomerEvaluationSettings = {
  __typename?: 'CustomerEvaluationSettings';
  /** boolean to determine if all questions should be generated at once */
  generateAllQuestionsAtOnce: Scalars['Boolean'];
  /** the number of days before a departure or before a departure end that eval availabilityDate should be set */
  nbDaysUntilEvaluationAvailable?: Maybe<Scalars['Int']>;
  /** the number of days after a departure that an eval is due */
  nbDaysUntilEvaluationDue?: Maybe<Scalars['Int']>;
  /** number of days before the departure that the preDepartureEnd questions should be generated */
  nbPreDepartureEndQuestionDays?: Maybe<Scalars['Int']>;
  /** number of days before the departure that the preDeparture questions should be generated */
  nbPreDepartureQuestionDays?: Maybe<Scalars['Int']>;
  /** boolean to determine if an eval should be generated before the departure, otherwise will generate x days before departure end */
  shouldGeneratePreDeparture: Scalars['Boolean'];
};

/** Customer merge error */
export type CustomerMergeError = DomainError & {
  __typename?: 'CustomerMergeError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type CustomerPaginatedResult = {
  __typename?: 'CustomerPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Customer>;
};

export type CustomerSignupResult = {
  __typename?: 'CustomerSignupResult';
  customer: Customer;
  token: Scalars['String'];
  tokenExpiresAt: Scalars['DateTime'];
};

export type DateOnlyRangeInput = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type DateRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** A transaction that debits a payment method */
export type DebitPaymentTransaction = PaymentTransaction & {
  __typename?: 'DebitPaymentTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  /** The customer of this transaction. */
  customerId: Scalars['ObjectId'];
  displayStatus: PaymentTransactionDisplayStatus;
  paymentMethod: TransactionPaymentMethod;
  paymentScheduleId?: Maybe<Scalars['ObjectId']>;
  paymentSysResponseCode?: Maybe<Scalars['String']>;
  paymentSysResponseMessage?: Maybe<Scalars['String']>;
  processorTransactionId?: Maybe<Scalars['Int']>;
  reconciledBy?: Maybe<Employee>;
  referenceNumber?: Maybe<Scalars['String']>;
  settlementDate?: Maybe<Scalars['Date']>;
  /** The status of this transaction. */
  status: PaymentTransactionStatus;
  transactionDate: Scalars['DateTime'];
};

export type DeleteCategoryResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | SuccessResult | ValidationError;

export type DeleteGcDepartureProspectResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDepartureProspectRemovedResult | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type DeleteReviewImageResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Review | ValidationError;

export type DeleteTagResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | SuccessResult | ValidationError;

export type DeleteWishlistItemResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError | WishlistItemRemovedResult;

/** Departure of a tour */
export type Departure = {
  __typename?: 'Departure';
  _id: Scalars['ObjectId'];
  /** The sellable extension for a departure if it has one. Otherwise Null. */
  activeExtension?: Maybe<DepartureExtension>;
  activeExtensionContent?: Maybe<TourExtensionContent>;
  addonPrice: Amount;
  autoApproveDeadline: Scalars['Date'];
  /** All trips that count as a spot booked (CONFIRMED, REQUESTED, WAITLISTED). */
  bookedTrips: Array<BookingTrip>;
  /** All booking trips. */
  bookingTrips: Array<BookingTrip>;
  bookings: Array<Booking>;
  cancellationMeta?: Maybe<DepartureCancellationMeta>;
  capacity: Scalars['Int'];
  /** Id to use to create a unique chat channel for the departure. Falls back to the legacy id for older departures. */
  chatChannelId: Scalars['String'];
  confirmedRooming?: Maybe<DepartureRooming>;
  confirmedTrips: Array<BookingTrip>;
  customerEvaluations?: Maybe<Array<CustomerEvaluation>>;
  /** Returns the prices for the available returning flight packages based on the base tour's start gateway code. */
  departingFlightPrices: Array<DepartureFlightPackagePrice>;
  departureDate: Scalars['Date'];
  departureDateWithExtension: Scalars['Date'];
  /** Departure itinerary associated with the departure */
  departureItinerary?: Maybe<DepartureItinerary>;
  /** Document metadata */
  documentMetadata?: Maybe<Array<DocumentMetadata>>;
  dpd: Scalars['Int'];
  /** Includes the date at which eTicket information is released, and whether or not it is already released */
  eTicketsRelease?: Maybe<ReleaseDetails>;
  endDate: Scalars['Date'];
  endDateWithExtension: Scalars['Date'];
  endGateway?: Maybe<Gateway>;
  endGatewayCode: Scalars['String'];
  exclusivityType: DepartureExclusivityType;
  excursions: Array<DepartureExcursion>;
  extendedFamilyCode: Scalars['String'];
  /** All extensions for a departure regardless of status. At most one can be active. */
  extensions: Array<DepartureExtension>;
  familyCode: Scalars['String'];
  finalInstallmentDeadline: Scalars['Date'];
  finalPaymentDeadline: Scalars['Date'];
  /** Includes the date at which flights are released, and whether or not they are already released */
  flightsRelease?: Maybe<ReleaseDetails>;
  /**
   * The date that flight line items go on request
   * @deprecated Use flightsRequestDeadlineDate which has no timestamp
   */
  flightsRequestDeadline?: Maybe<Scalars['DateTime']>;
  /** The date that flight line items go on request */
  flightsRequestDeadlineDate?: Maybe<Scalars['Date']>;
  /**
   * The deadline for customers to request flights
   * @deprecated Use flightsRequestMessagingDeadlineDate which has no timestamp
   */
  flightsRequestMessagingDeadline?: Maybe<Scalars['DateTime']>;
  /** The deadline for customers to request flights */
  flightsRequestMessagingDeadlineDate?: Maybe<Scalars['Date']>;
  /** GC departures associated with the departure */
  gcDeparture?: Maybe<Array<GcDeparture>>;
  hasSpotsLeft: Scalars['Boolean'];
  hasUnmatchedRoommates: Scalars['Boolean'];
  hotelsRelease?: Maybe<ReleaseDetails>;
  /** @deprecated Use hotelsRequestDeadlineDate which has no timestamp */
  hotelsRequestDeadline?: Maybe<Scalars['DateTime']>;
  hotelsRequestDeadlineDate?: Maybe<Scalars['Date']>;
  isFlightShoppingAllowed: Scalars['Boolean'];
  isPostStayAllowed: Scalars['Boolean'];
  isPreStayAllowed: Scalars['Boolean'];
  /** Whether or not status is purchasable status. */
  isPurchasable: Scalars['Boolean'];
  isWaitlistAllowed: Scalars['Boolean'];
  /** Price with fees and promos optionally applied */
  leadInPrice: Array<LeadInPrice>;
  lineItemCode: Scalars['LineItemCode'];
  nbSpotsBooked: Scalars['Int'];
  nbSpotsLeft: Scalars['Int'];
  /** @deprecated No longer a valid status */
  onHoldTrips: Array<BookingTrip>;
  owner?: Maybe<Employee>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  pendingRebookingTrips: Array<BookingTrip>;
  postStays?: Maybe<Array<ExtendedStay>>;
  preStays?: Maybe<Array<ExtendedStay>>;
  price: Amount;
  priceYear: PriceYear;
  prodTourId?: Maybe<Scalars['Int']>;
  requestedRooming?: Maybe<DepartureRooming>;
  requestedTrips: Array<BookingTrip>;
  reservedCapacity: Scalars['Int'];
  /** Returns the prices for the available returning flight packages based on the base tour's end gateway code. */
  returningFlightPrices: Array<DepartureFlightPackagePrice>;
  rooming?: Maybe<DepartureRooming>;
  rooms: Array<DepartureRoom>;
  salesTourId: Scalars['String'];
  series: Scalars['Series'];
  startDate: Scalars['Date'];
  startGateway?: Maybe<Gateway>;
  startGatewayCode: Scalars['String'];
  status: DepartureStatus;
  tagIds: Array<Scalars['ObjectId']>;
  tags: Array<Tag>;
  timingStatus: DepartureTimingStatus;
  /** Parent tour associated with the departure */
  tour?: Maybe<Tour>;
  tourCode: Scalars['String'];
  tourContent?: Maybe<TourContent>;
  tourDirectors: Array<TourDirector>;
  tourEndReportRelease?: Maybe<ReleaseDetails>;
  travelers: Array<Customer>;
  /** All trips, including booked and quoted trips associated with the departure */
  trips: Array<Trip>;
};


/** Departure of a tour */
export type DepartureActiveExtensionContentArgs = {
  language: Scalars['String'];
};


/** Departure of a tour */
export type DepartureLeadInPriceArgs = {
  campaignCode?: InputMaybe<Scalars['String']>;
  promoCodes?: InputMaybe<Array<Scalars['String']>>;
};


/** Departure of a tour */
export type DepartureTourContentArgs = {
  language: Scalars['String'];
};

/** Information about why a departure or extension was cancelled */
export type DepartureCancellationMeta = {
  __typename?: 'DepartureCancellationMeta';
  cancellationDate: Scalars['DateTime'];
  cancellationReason: Scalars['String'];
  cancelledById: Scalars['String'];
};

/** Departure evaluation statistics */
export type DepartureEvaluationSummary = {
  __typename?: 'DepartureEvaluationSummary';
  /** The average percentage based on the average score and the maximum score */
  averagePercentage: Scalars['Float'];
  /** The average score of all answered tour director questions on a departure */
  averageScore: Scalars['Float'];
  /** The average score level based on the average score */
  averageScoreLevel: EvaluationScoreLevel;
  /** Customer responses to the tour director question, only questions with additional comments are processed */
  customerResponses: Array<EvaluationSummaryCustomerResponse>;
  departure: Departure;
  /** Departure ID */
  departureId: Scalars['ObjectId'];
  /** Percentage of evaluations completed */
  evaluationCompletionPercentage: Scalars['Float'];
  /** Evaluation completion percentage level */
  evaluationCompletionPercentageLevel: EvaluationScoreLevel;
  /** Number of completed evaluations */
  nbOfCompletedEvaluations: Scalars['Int'];
  /** Number of evaluations */
  nbOfEvaluations: Scalars['Int'];
  /** NPS score of the tour. */
  npsScore: Scalars['Int'];
  /** NPS score level */
  npsScoreLevel: EvaluationScoreLevel;
  /** List of score ranges, based on the average score of each answered evaluation */
  ranges: Array<EvaluationScoreRange>;
  /** Release information for the evaluation statistics */
  releaseInformation: ReleaseDetails;
};

export enum DepartureExclusivityType {
  Exclusive = 'EXCLUSIVE',
  Standard = 'STANDARD'
}

/** Excursion configuration for a departure */
export type DepartureExcursion = {
  __typename?: 'DepartureExcursion';
  _id: Scalars['ObjectId'];
  /** The deadline past which the status of this Excursion will be REQUESTED when added to a Trip. */
  autoApproveDeadline: Scalars['Date'];
  autoApproveDeadlineDpd?: Maybe<Scalars['Int']>;
  capacity: Scalars['Int'];
  content?: Maybe<TourExcursionContent>;
  /** Whether or not status is considered an active status. */
  isActive: Scalars['Boolean'];
  /** Whether or not status is considered a purchasable status. */
  isPurchasable: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  matchCode: Scalars['String'];
  /** Indicates the minimum number of travelers required to run the excursion. */
  minNbTravelersRequired: Scalars['Int'];
  nbSpotsBooked: Scalars['Int'];
  nbSpotsLeft: Scalars['Int'];
  price: Amount;
  series: Scalars['Series'];
  status: DepartureStatus;
  tourExcursion?: Maybe<TourExcursion>;
};


/** Excursion configuration for a departure */
export type DepartureExcursionContentArgs = {
  language: Scalars['String'];
};

/** Extension config for a Departure */
export type DepartureExtension = {
  __typename?: 'DepartureExtension';
  _id: Scalars['ObjectId'];
  autoApproveDeadline: Scalars['Date'];
  cancellationMeta?: Maybe<DepartureCancellationMeta>;
  capacity: Scalars['Int'];
  confirmedRooming?: Maybe<DepartureRooming>;
  /** Returns the prices for the available departing flight packages based on the extension or departure's start gateway code. */
  departingFlightPrices: Array<DepartureFlightPackagePrice>;
  departure?: Maybe<Departure>;
  departureDate: Scalars['Date'];
  departureId: Scalars['ObjectId'];
  dpd: Scalars['Int'];
  endDate: Scalars['Date'];
  endGatewayCode: Scalars['String'];
  excursions: Array<DepartureExcursion>;
  extendedFamilyCode: Scalars['String'];
  familyCode: Scalars['String'];
  isFlightShoppingAllowed: Scalars['Boolean'];
  isPreExtension: Scalars['Boolean'];
  /** Whether or not status is purchasable status. */
  isPurchasable: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  nbSpotsBooked: Scalars['Int'];
  nbSpotsLeft: Scalars['Int'];
  postStays?: Maybe<Array<ExtendedStay>>;
  preStays?: Maybe<Array<ExtendedStay>>;
  price: Amount;
  prodTourId?: Maybe<Scalars['Int']>;
  requestedRooming?: Maybe<DepartureRooming>;
  /** Returns the prices for the available returning flight packages based on the extension or departure's end gateway code. */
  returningFlightPrices: Array<DepartureFlightPackagePrice>;
  rooming?: Maybe<DepartureRooming>;
  rooms: Array<DepartureRoom>;
  salesTourId: Scalars['String'];
  series: Scalars['Series'];
  startDate: Scalars['Date'];
  startGatewayCode: Scalars['String'];
  status: DepartureStatus;
  timingStatus: DepartureTimingStatus;
  tourCode: Scalars['String'];
  tourExtension?: Maybe<TourExtension>;
};

export type DepartureFlightPackagePrice = {
  __typename?: 'DepartureFlightPackagePrice';
  homeGateway?: Maybe<Gateway>;
  homeGatewayCode: Scalars['String'];
  isCustomGateway: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  prices: Array<Money>;
};

/** The itinerary of a departure */
export type DepartureItinerary = {
  __typename?: 'DepartureItinerary';
  _id: Scalars['ObjectId'];
  components: Array<DepartureItineraryComponent>;
  prodTourId: Scalars['Int'];
  tourDirectors: Array<DepartureItineraryComponent>;
};

/** The component on a departure itinerary */
export type DepartureItineraryComponent = {
  __typename?: 'DepartureItineraryComponent';
  agent?: Maybe<Supplier>;
  breakdown?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  externalReferenceNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes: Array<Scalars['String']>;
  productionComponentId: Scalars['Int'];
  reconfirmationDpd?: Maybe<Scalars['Int']>;
  reservation?: Maybe<Reservation>;
  startDate: Scalars['Date'];
  startTime?: Maybe<Scalars['Time']>;
  supplier?: Maybe<Supplier>;
  type: Scalars['String'];
};

/** Departure not bookable error */
export type DepartureNotBookableError = DomainError & {
  __typename?: 'DepartureNotBookableError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** A paginated result of Departures */
export type DeparturePaginatedResult = {
  __typename?: 'DeparturePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Departure>;
};

/** Room on a Departure */
export type DepartureRoom = {
  __typename?: 'DepartureRoom';
  _id: Scalars['ObjectId'];
  bed: TourBed;
  cabinCode?: Maybe<Scalars['TourCabinCode']>;
  capacity: Scalars['Int'];
  content?: Maybe<RoomContent>;
  /** Whether or not status is purchasable status. */
  isPurchasable: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  nbSpotsBooked: Scalars['Int'];
  nbSpotsLeft: Scalars['Int'];
  price: Amount;
  /** Whether or not the room requires a roommate configuration. */
  requiresRooming: Scalars['Boolean'];
  series: Scalars['Series'];
  status: DepartureStatus;
};


/** Room on a Departure */
export type DepartureRoomContentArgs = {
  language: Scalars['String'];
};

/** Departure Rooming */
export type DepartureRooming = {
  __typename?: 'DepartureRooming';
  /** Travelers with assigned roommates */
  assignedRooms: Array<AssignedRoom>;
  /** Total room and traveler counts for each bed count */
  breakdown: Array<DepartureRoomingBreakdown>;
  /** Travelers who are yet to be assigned a roommate */
  unassignedTravelers: Array<UnassignedTravelers>;
};

/** The breakdown by bed code */
export type DepartureRoomingBreakdown = {
  __typename?: 'DepartureRoomingBreakdown';
  bedCode: Scalars['String'];
  nbRooms: Scalars['Int'];
  nbTravelers: Scalars['Int'];
};

export type DepartureSettings = {
  __typename?: 'DepartureSettings';
  documentMetadata: Array<DocumentMetadataSettings>;
  /** The DPD at which this business expects flight segments to have e-ticket numbers populated */
  eTicketsAvailabilityDpd?: Maybe<Scalars['Int']>;
  /** The DPD at which this business expects flights information populated */
  flightsReleaseDpd: Scalars['Int'];
  flightsRequestDpd: Scalars['Int'];
  flightsRequestMessagingDpd: Scalars['Int'];
  hotelsReleaseDpd: Scalars['Int'];
  hotelsRequestDpd: Scalars['Int'];
  publicDepartureMinDpd: Scalars['Int'];
};

export enum DepartureSortField {
  AddOnPriceCad = 'addOnPriceCAD',
  AddOnPriceUsd = 'addOnPriceUSD',
  AutoApproveDeadline = 'autoApproveDeadline',
  Capacity = 'capacity',
  DepartureDate = 'departureDate',
  EndDate = 'endDate',
  ExclusivityType = 'exclusivityType',
  FinalPaymentDeadline = 'finalPaymentDeadline',
  NbSpotsBooked = 'nbSpotsBooked',
  PriceCad = 'priceCAD',
  PriceUsd = 'priceUSD',
  PriceYear = 'priceYear',
  SalesTourId = 'salesTourId',
  StartDate = 'startDate',
  Status = 'status',
  TourCode = 'tourCode'
}

export enum DepartureStatus {
  /** The departure has been cancelled, all trips are cancelled, and the departure is cancelled in Atlas. */
  Cancelled = 'CANCELLED',
  /** The trip is no longer available because it's passed it's run date. */
  Closed = 'CLOSED',
  /** Like PENDING except the prod has been created in Atlas. */
  Hold = 'HOLD',
  /** The trip is past the tour finalization and allocation has begun therefore all bookings will be ON_REQUEST until approved by Ops and/or Travel teams */
  OnRequest = 'ON_REQUEST',
  /** The departure is not available for sale yet and the prod has not been created in Atlas. */
  Pending = 'PENDING',
  /** The Prod tour is cancelled in atlas but not all customer trips on it have been cancelled. */
  PendingCancellation = 'PENDING_CANCELLATION',
  /** The departure is available for sale. */
  Sell = 'SELL'
}

export enum DepartureTimingStatus {
  OnTour = 'OnTour',
  PostTour = 'PostTour',
  PreTour = 'PreTour'
}

/** Document metadata for a specific departure. */
export type DocumentMetadata = {
  __typename?: 'DocumentMetadata';
  /** The full path where the document can be downloaded from. */
  downloadPath: Scalars['String'];
  /** The title of the document to be displayed by clients. */
  title: Scalars['String'];
};

/** Document metadata settings */
export type DocumentMetadataSettings = {
  __typename?: 'DocumentMetadataSettings';
  documentType: DocumentType;
  /** The url path where the document can be found */
  downloadPath: Scalars['String'];
  title: Scalars['String'];
};

export enum DocumentType {
  Cruise = 'CRUISE',
  Extension = 'EXTENSION',
  General = 'GENERAL'
}

/** Domain Error interface for all to implement */
export type DomainError = {
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** A draft of an order for editing */
export type DraftOrder = {
  __typename?: 'DraftOrder';
  _id: Scalars['ObjectId'];
  order: Order;
};

/** An entity with the same unique key already exists */
export type DuplicateUniqueKeyError = DomainError & {
  __typename?: 'DuplicateUniqueKeyError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type EfTransferPaymentMethod = {
  __typename?: 'EFTransferPaymentMethod';
  efProduct: Scalars['String'];
  efStaffName: Scalars['String'];
  efTransferNumber: Scalars['String'];
  externCustomerName: Scalars['String'];
  externalCustomerId: Scalars['String'];
  externalTripId: Scalars['String'];
};

/** Failed to send an email */
export type EmailSendFailedError = DomainError & {
  __typename?: 'EmailSendFailedError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** emergency contact information */
export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  _id: Scalars['ObjectId'];
  confirmationDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['Email']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['PhoneNumber']>;
  relationship: EmergencyContactRelationship;
};

export type EmergencyContactChecklistItem = SystemChecklistItem & {
  __typename?: 'EmergencyContactChecklistItem';
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

export type EmergencyContactInput = {
  confirmationDate?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  relationship: EmergencyContactRelationship;
};

export enum EmergencyContactRelationship {
  Child = 'CHILD',
  Friend = 'FRIEND',
  NotSpecified = 'NOT_SPECIFIED',
  Other = 'OTHER',
  Parent = 'PARENT',
  Sibling = 'SIBLING',
  Spouse = 'SPOUSE'
}

/** An EF employee and its associated data */
export type Employee = User & {
  __typename?: 'Employee';
  _id: Scalars['ObjectId'];
  email?: Maybe<Scalars['Email']>;
  entraId: Scalars['String'];
  firstName: Scalars['String'];
  fullname: Scalars['String'];
  lastName: Scalars['String'];
  roleIds: Array<Scalars['ObjectId']>;
  roles: Array<Role>;
};

/** A paginated result of Tours */
export type EmployeePaginatedResult = {
  __typename?: 'EmployeePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Employee>;
};

export enum EmployeeSortField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName'
}

/** Base type for all evaluations */
export type Evaluation = {
  _id: Scalars['ObjectId'];
  /** The date that the evaluation is available */
  availabilityDate: Scalars['DateTime'];
  businessCode?: Maybe<BusinessCode>;
  /** The date that the evaluation is due */
  deadlineDate: Scalars['DateTime'];
  /** The departure ID of the departure that this evaluation belong to */
  departureId: Scalars['ObjectId'];
  evaluationType: EvaluationType;
  /** The date that the evaluation was finalized */
  finalizationDate?: Maybe<Scalars['DateTime']>;
  hasAnsweredQuestions: Scalars['Boolean'];
  questions: Array<EvaluationQuestion>;
  /** The date that the evaluation was submitted */
  submissionDate?: Maybe<Scalars['DateTime']>;
  templateId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

/** Base type for all evaluation answers */
export type EvaluationAnswer = {
  additionalComments?: Maybe<Scalars['String']>;
};

/** An evaluation scale choice */
export type EvaluationChoice = {
  __typename?: 'EvaluationChoice';
  _id: Scalars['ObjectId'];
  label: Scalars['String'];
  /** The value of the choice, can be either a string or a number but always represented as a string in the graph */
  value: Scalars['String'];
};

/** Evaluation free text answer */
export type EvaluationFreeTextAnswer = EvaluationAnswer & {
  __typename?: 'EvaluationFreeTextAnswer';
  additionalComments?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** An evaluation free text question */
export type EvaluationFreeTextQuestion = EvaluationQuestion & {
  __typename?: 'EvaluationFreeTextQuestion';
  _id: Scalars['ObjectId'];
  answer?: Maybe<EvaluationFreeTextAnswer>;
  canonicalId: Scalars['String'];
  /** Whether the question has been answered */
  hasBeenAnswered: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<EvaluationQuestionMeta>;
  text: Scalars['String'];
};

/** An evaluation matrix answer */
export type EvaluationMatrixAnswer = EvaluationAnswer & {
  __typename?: 'EvaluationMatrixAnswer';
  additionalComments?: Maybe<Scalars['String']>;
  choiceId: Scalars['ObjectId'];
  /** The value of the choice, can be either a string or a number but always represented as a string in the graph */
  value: Scalars['String'];
};

/** An evaluation matrix question */
export type EvaluationMatrixQuestion = EvaluationQuestion & {
  __typename?: 'EvaluationMatrixQuestion';
  _id: Scalars['ObjectId'];
  canonicalId: Scalars['String'];
  choices: Array<EvaluationChoice>;
  /** Whether the question has been answered */
  hasBeenAnswered: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<EvaluationQuestionMeta>;
  rows: Array<EvaluationMatrixRow>;
  text: Scalars['String'];
};

/** An evaluation matrix row */
export type EvaluationMatrixRow = {
  __typename?: 'EvaluationMatrixRow';
  _id: Scalars['ObjectId'];
  answer?: Maybe<EvaluationMatrixAnswer>;
  text: Scalars['String'];
};

/** Evaluation multiple choice answer */
export type EvaluationMultipleChoiceAnswer = EvaluationAnswer & {
  __typename?: 'EvaluationMultipleChoiceAnswer';
  additionalComments?: Maybe<Scalars['String']>;
  choiceId: Scalars['ObjectId'];
  /** The value of the choice, can be either a string or a number but always represented as a string in the graph */
  value: Scalars['String'];
};

/** An evaluation multiple choice question */
export type EvaluationMultipleChoiceQuestion = EvaluationQuestion & {
  __typename?: 'EvaluationMultipleChoiceQuestion';
  _id: Scalars['ObjectId'];
  answer?: Maybe<EvaluationMultipleChoiceAnswer>;
  canonicalId: Scalars['String'];
  choices: Array<EvaluationChoice>;
  /** Whether the question has been answered */
  hasBeenAnswered: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<EvaluationQuestionMeta>;
  text: Scalars['String'];
};

/** Base type for all evaluations */
export type EvaluationQuestion = {
  _id: Scalars['ObjectId'];
  canonicalId: Scalars['String'];
  /** Whether the question has been answered */
  hasBeenAnswered: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<EvaluationQuestionMeta>;
  text: Scalars['String'];
};

/** Meta information about an evaluation question */
export type EvaluationQuestionMeta = {
  __typename?: 'EvaluationQuestionMeta';
  /** The end date of the component, can be used in the UI to group questions by day */
  componentEndDate?: Maybe<Scalars['Date']>;
  /** The start time of the component, can be used in the UI to help order questions */
  componentStartTime?: Maybe<Scalars['Time']>;
  /** The placement type of the question, can be used in the UI to determine where the question should be displayed */
  placementType?: Maybe<EvaluationQuestionPlacementType>;
};

export enum EvaluationQuestionPlacementType {
  PreDeparture = 'PRE_DEPARTURE',
  PreDepartureEnd = 'PRE_DEPARTURE_END'
}

export type EvaluationResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | EvaluationSuccess | InvalidDateError | InvalidEvaluationError | NotAuthorizedError | NotFoundError | ValidationError;

export enum EvaluationScoreLevel {
  Average = 'AVERAGE',
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Na = 'NA',
  Poor = 'POOR'
}

/** Evaluation score range */
export type EvaluationScoreRange = {
  __typename?: 'EvaluationScoreRange';
  /** Label for the score range */
  label: Scalars['String'];
  /** Score level */
  level: EvaluationScoreLevel;
  /** Maximum score */
  max: Scalars['Float'];
  /** Minimum score */
  min: Scalars['Float'];
  /** the percentage of items within this range */
  percentage: Scalars['Float'];
  /** the number of items within this range */
  value: Scalars['Int'];
};

/** Evaluation settings */
export type EvaluationSettings = {
  __typename?: 'EvaluationSettings';
  /** the default template string for component questions, example: 'How was your experience with <COMPONENT_NAME>'  */
  componentQuestionText: Scalars['String'];
  /** Customer evaluation settings */
  customerEvaluationSettings: CustomerEvaluationSettings;
  /** Tour director tour end report settings */
  tourDirectorTourEndReportSettings: TourDirectorTourEndReportSettings;
};

export type EvaluationSuccess = {
  __typename?: 'EvaluationSuccess';
  data: Evaluation;
};

/** Evaluation statistics customer response */
export type EvaluationSummaryCustomerResponse = {
  __typename?: 'EvaluationSummaryCustomerResponse';
  /** The level of the response, can be used to highlight good or bad responses */
  level: EvaluationScoreLevel;
  /** The response populated by the additional comments field in the user's answer */
  response: Scalars['String'];
  /** The selected value of the tour director question by the user. This should be a whole number */
  score: Scalars['Int'];
};

export enum EvaluationType {
  CustomerEvaluation = 'CUSTOMER_EVALUATION',
  TourDirectorTourEndReport = 'TOUR_DIRECTOR_TOUR_END_REPORT'
}

export enum ExcursionTourType {
  Extension = 'EXTENSION',
  MainTour = 'MAIN_TOUR'
}

/** Product that represents an extended stay pre or post tour */
export type ExtendedStay = PricedTripComponent & TripComponent & {
  __typename?: 'ExtendedStay';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  bedCode: TourBedCode;
  businessCode?: Maybe<BusinessCode>;
  /** Additional information about the gateway */
  cityGateway?: Maybe<Gateway>;
  cityGatewayCode: Scalars['String'];
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  status: TripComponentStatus;
  type: TripComponentType;
};

export type ExtendedStayLineItem = LineItem & {
  __typename?: 'ExtendedStayLineItem';
  _id: Scalars['ObjectId'];
  /** The bed code for the extended stay */
  bedCode?: Maybe<TourBedCode>;
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  /** Additional information about the city gateway */
  cityGateway?: Maybe<Gateway>;
  cityGatewayCode: Scalars['String'];
  departureDate: Scalars['Date'];
  endDate: Scalars['Date'];
  isOpen: Scalars['Boolean'];
  isPreStay: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** A paginated result of Extended Stays */
export type ExtendedStayPaginatedResult = {
  __typename?: 'ExtendedStayPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<ExtendedStay>;
};

export type ExternalToken = {
  __typename?: 'ExternalToken';
  token: Scalars['String'];
};

export type FaqContent = {
  __typename?: 'FaqContent';
  items: Array<FaqContentItem>;
};

export type FaqContentItem = {
  __typename?: 'FaqContentItem';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type Fee = PricedTripComponent & TripComponent & {
  __typename?: 'Fee';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  categoryCode: Scalars['FeeCategoryCode'];
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  rollsUpToTarget: Scalars['Boolean'];
  status: TripComponentStatus;
  type: TripComponentType;
  typeCode: FeeTypeCode;
};

export type FeeApplicationInfo = {
  __typename?: 'FeeApplicationInfo';
  applyTo: FeeApplicationInstructions;
  fee: FeeLineItem;
};

/** Instructs how to apply the fee to the trips on a Quote */
export enum FeeApplicationInstructions {
  AllTrips = 'ALL_TRIPS',
  PrimaryTripOnly = 'PRIMARY_TRIP_ONLY',
  RelevantTrips = 'RELEVANT_TRIPS'
}

export type FeeCalculationRule = {
  __typename?: 'FeeCalculationRule';
  /** Defines the maximum DPD value for this rule to match */
  maxDpd: Scalars['Int'];
  /** The strategy to use when calculating the fee */
  strategy: FeeCalculationStrategy;
  /** The percentage of the trip cost to use for the fee */
  tripPercent: Scalars['Percentage'];
};

export enum FeeCalculationStrategy {
  GreaterFeePriceOrTripCostPercent = 'GreaterFeePriceOrTripCostPercent',
  TripCostPercent = 'TripCostPercent'
}

/** Category Calculation Rule Settings */
export type FeeCancellationSettings = {
  __typename?: 'FeeCancellationSettings';
  categorySpecificRules: Array<FeeCategorySpecificCalculationRule>;
  defaultRules: Array<FeeCalculationRule>;
};

/** Category Calculation Rule Settings */
export type FeeCategorySpecificCalculationRule = {
  __typename?: 'FeeCategorySpecificCalculationRule';
  categoryCodes: Array<Scalars['FeeCategoryCode']>;
  rules: Array<FeeCalculationRule>;
};

/** A product line item */
export type FeeLineItem = LineItem & {
  __typename?: 'FeeLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** A paginated result of Fees */
export type FeePaginatedResult = {
  __typename?: 'FeePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Fee>;
};

/** Fee settings */
export type FeeSettings = {
  __typename?: 'FeeSettings';
  /** Settings for calculating cancellation fees */
  cancellationSettings: FeeCancellationSettings;
  useLowestDpdInRebookingChain: Scalars['Boolean'];
};

export enum FeeSortField {
  CategoryCode = 'categoryCode',
  LineItemCode = 'lineItemCode'
}

export enum FeeTypeCode {
  Cax = 'CAX',
  Flightaddcollect = 'FLIGHTADDCOLLECT',
  Flightcax = 'FLIGHTCAX',
  Flightchange = 'FLIGHTCHANGE',
  Latebooking = 'LATEBOOKING',
  Latepay = 'LATEPAY',
  Mpp = 'MPP',
  Payfail = 'PAYFAIL',
  Rebook = 'REBOOK',
  Refundprocessing = 'REFUNDPROCESSING',
  Retcheck = 'RETCHECK',
  Sptravel = 'SPTRAVEL',
  Tsa = 'TSA'
}

export type FilterAdjustmentInput = {
  adjustmentCode?: InputMaybe<Scalars['String']>;
  businessCode?: InputMaybe<BusinessCode>;
  isCompanionDiscount?: InputMaybe<Scalars['Boolean']>;
  isFreePlace?: InputMaybe<Scalars['Boolean']>;
  isStaffOnTour?: InputMaybe<Scalars['Boolean']>;
  lineItemCode?: InputMaybe<Scalars['LineItemCode']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterBookingInput = {
  businessCode?: InputMaybe<BusinessCode>;
  customerId?: InputMaybe<Scalars['ObjectId']>;
  dateBooked?: InputMaybe<Scalars['DateTime']>;
  dateBookedFrom?: InputMaybe<Scalars['DateTime']>;
  dateBookedTo?: InputMaybe<Scalars['DateTime']>;
  departureDateFrom?: InputMaybe<Scalars['Date']>;
  departureDateTo?: InputMaybe<Scalars['Date']>;
  departureId?: InputMaybe<Scalars['ObjectId']>;
  finalPaymentDateFrom?: InputMaybe<Scalars['Date']>;
  finalPaymentDateTo?: InputMaybe<Scalars['Date']>;
  isEnrolledInAutopay?: InputMaybe<Scalars['Boolean']>;
  lastAutopayTransactionDate?: InputMaybe<Scalars['DateTime']>;
};

/** Filter input for campaigns. */
export type FilterCampaignInput = {
  businessCode?: InputMaybe<BusinessCode>;
  campaignCodes?: InputMaybe<Array<Scalars['String']>>;
  effectiveFrom?: InputMaybe<Scalars['DateTime']>;
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
  promoIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<CampaignStatus>;
  /** Include only valid active campaigns. */
  valid?: InputMaybe<Scalars['Boolean']>;
};

/** Filters for category query */
export type FilterCategoryInput = {
  businessCode?: InputMaybe<BusinessCode>;
};

export type FilterCustomerInput = {
  /** Search by id */
  _id?: InputMaybe<Scalars['ObjectId']>;
  /** Search by email */
  email?: InputMaybe<Scalars['String']>;
  /** Search by first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Search by last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Search by phone number */
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
  /** Search first name, last name, email and phone number */
  query?: InputMaybe<Scalars['String']>;
};

export type FilterDepartureInput = {
  addOnPriceCADFrom?: InputMaybe<Scalars['Float']>;
  addOnPriceCADTo?: InputMaybe<Scalars['Float']>;
  addOnPriceUSDFrom?: InputMaybe<Scalars['Float']>;
  addOnPriceUSDTo?: InputMaybe<Scalars['Float']>;
  businessCode?: InputMaybe<BusinessCode>;
  capacityFrom?: InputMaybe<Scalars['Int']>;
  capacityTo?: InputMaybe<Scalars['Int']>;
  departureRange?: InputMaybe<DateOnlyRangeInput>;
  dpdFrom?: InputMaybe<Scalars['Int']>;
  dpdTo?: InputMaybe<Scalars['Int']>;
  endDateRange?: InputMaybe<DateOnlyRangeInput>;
  endGatewayCode?: InputMaybe<Scalars['String']>;
  exclusivityType?: InputMaybe<DepartureExclusivityType>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  lineItemCodes?: InputMaybe<Array<Scalars['LineItemCode']>>;
  priceCADFrom?: InputMaybe<Scalars['Float']>;
  priceCADTo?: InputMaybe<Scalars['Float']>;
  priceUSDFrom?: InputMaybe<Scalars['Float']>;
  priceUSDTo?: InputMaybe<Scalars['Float']>;
  priceYear?: InputMaybe<Scalars['Int']>;
  prodTourId?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  startGatewayCode?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<DepartureStatus>>;
  tourCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterEmployeeInput = {
  email?: InputMaybe<Scalars['Email']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type FilterExtendedStayInput = {
  bedCode?: InputMaybe<TourBedCode>;
  businessCode?: InputMaybe<BusinessCode>;
  cityGatewayCode?: InputMaybe<Scalars['String']>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterFeeInput = {
  businessCode?: InputMaybe<BusinessCode>;
  categoryCode?: InputMaybe<Scalars['String']>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  lineItemCode?: InputMaybe<Scalars['LineItemCode']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterFlightPackageInput = {
  arrivalGatewayCode?: InputMaybe<Scalars['String']>;
  businessCode?: InputMaybe<BusinessCode>;
  departureGatewayCode?: InputMaybe<Scalars['String']>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  isCustomGateway?: InputMaybe<Scalars['Boolean']>;
  isPriced?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterGcDepartureInput = {
  _id?: InputMaybe<Scalars['ObjectId']>;
  businessCode?: InputMaybe<BusinessCode>;
  departureDateRange?: InputMaybe<DateOnlyRangeInput>;
  departureIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  groupCoordinatorCustomerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  opportunityIds?: InputMaybe<Array<Scalars['String']>>;
  reconciliationDateRange?: InputMaybe<DateOnlyRangeInput>;
  statuses?: InputMaybe<Array<GcDepartureStatus>>;
  tourCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterGcDepartureProspectInput = {
  email?: InputMaybe<Scalars['String']>;
  gcDepartureId?: InputMaybe<Scalars['ObjectId']>;
  groupCoordinatorCustomerId?: InputMaybe<Scalars['ObjectId']>;
  levelOfInterest?: InputMaybe<Array<GcDepartureProspectLevelOfInterest>>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type FilterGatewayInput = {
  cityCode?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  isCityCodeOnly?: InputMaybe<Scalars['Boolean']>;
};

export type FilterInsurancePlanInput = {
  businessCode?: InputMaybe<BusinessCode>;
  planCode?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterNoteInput = {
  employeeId?: InputMaybe<Scalars['ObjectId']>;
  targetId?: InputMaybe<Scalars['ObjectId']>;
};

export type FilterOrderInput = {
  businessCode?: InputMaybe<BusinessCode>;
  customerId?: InputMaybe<Scalars['ObjectId']>;
  departureDate?: InputMaybe<Scalars['Date']>;
  departureId?: InputMaybe<Scalars['ObjectId']>;
  stage?: InputMaybe<OrderStage>;
};

export type FilterPaymentReconciliationTransactionInput = {
  businessCode?: InputMaybe<BusinessCode>;
  reconciliationStatus?: InputMaybe<ReconciliationStatus>;
  uploadDateFrom?: InputMaybe<Scalars['DateTime']>;
  uploadDateTo?: InputMaybe<Scalars['DateTime']>;
  uploadId?: InputMaybe<Scalars['ObjectId']>;
};

export type FilterPaymentReconciliationUploadInput = {
  businessCode?: InputMaybe<BusinessCode>;
  sourceProcessorOrAcquirer?: InputMaybe<SourceProcessorOrAcquirer>;
  uploadDateFrom?: InputMaybe<Scalars['DateTime']>;
  uploadDateTo?: InputMaybe<Scalars['DateTime']>;
};

export type FilterPaymentTransactionInput = {
  businessCode?: InputMaybe<BusinessCode>;
  customerId?: InputMaybe<Scalars['ObjectId']>;
  dateBooked?: InputMaybe<Scalars['DateTime']>;
  dateBookedFrom?: InputMaybe<Scalars['DateTime']>;
  dateBookedTo?: InputMaybe<Scalars['DateTime']>;
  departureId?: InputMaybe<Scalars['ObjectId']>;
  transactionDateFrom?: InputMaybe<Scalars['DateTime']>;
  transactionDateTo?: InputMaybe<Scalars['DateTime']>;
};

export type FilterPromoInput = {
  _ids?: InputMaybe<Array<Scalars['ObjectId']>>;
  businessCode?: InputMaybe<BusinessCode>;
  customerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  displayName?: InputMaybe<Scalars['String']>;
  /** The effective start date of a promo. */
  effectiveFrom?: InputMaybe<Scalars['DateTime']>;
  /** The effective end date of a promo. */
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
  exclusivity?: InputMaybe<PromoExclusivity>;
  gcDepartureIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  isGroupsOnly?: InputMaybe<Scalars['Boolean']>;
  isHiddenFromSales?: InputMaybe<Scalars['Boolean']>;
  isIndiesOnly?: InputMaybe<Scalars['Boolean']>;
  lineItemCode?: InputMaybe<Scalars['String']>;
  /** Max number of redemptions */
  maxRedeemed?: InputMaybe<Scalars['Int']>;
  /** Maximum value for the maxRedemptions */
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  /** Min number of redemptions */
  minRedeemed?: InputMaybe<Scalars['Int']>;
  /** Minimum value for the maxRedemptions */
  minRedemptions?: InputMaybe<Scalars['Int']>;
  offerCode?: InputMaybe<Scalars['String']>;
  promoCategories?: InputMaybe<Array<PromoCategory>>;
  promoCodes?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
  /** Include only valid active promos. */
  valid?: InputMaybe<Scalars['Boolean']>;
  validForCustomerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  validForGCDepartureIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  validShareableCustomerIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type FilterQuoteInput = {
  businessCode?: InputMaybe<BusinessCode>;
  expirationDateRange?: InputMaybe<DateRangeInput>;
  gcDepartureId?: InputMaybe<Scalars['ObjectId']>;
  isGCDeparture?: InputMaybe<Scalars['Boolean']>;
  opportunityId?: InputMaybe<Scalars['String']>;
};

export type FilterRoleInput = {
  businessCode?: InputMaybe<BusinessCode>;
  permissionKeys?: InputMaybe<Array<PermissionKey>>;
  query?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type FilterServiceRecoveryInput = {
  businessCode?: InputMaybe<BusinessCode>;
  orderId?: InputMaybe<Scalars['ObjectId']>;
  promoId?: InputMaybe<Scalars['ObjectId']>;
  query?: InputMaybe<Scalars['String']>;
  recoveryReason?: InputMaybe<Scalars['String']>;
  tripId?: InputMaybe<Scalars['ObjectId']>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

/** Filters for tag query */
export type FilterTagInput = {
  businessCode?: InputMaybe<BusinessCode>;
  query?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

/** Filter Tour Directors by specific criteria */
export type FilterTourDirectorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type FilterTourEndReportInput = {
  departureId?: InputMaybe<Scalars['ObjectId']>;
  tourDirectorId?: InputMaybe<Scalars['ObjectId']>;
};

export type FilterTourExcursionInput = {
  autoApprovedDeadlineDpdFrom?: InputMaybe<Scalars['Int']>;
  autoApprovedDeadlineDpdTo?: InputMaybe<Scalars['Int']>;
  basePriceCADFrom?: InputMaybe<Scalars['Float']>;
  basePriceCADTo?: InputMaybe<Scalars['Float']>;
  basePriceUSDFrom?: InputMaybe<Scalars['Float']>;
  basePriceUSDTo?: InputMaybe<Scalars['Float']>;
  businessCode?: InputMaybe<BusinessCode>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  lineItemCode?: InputMaybe<Scalars['LineItemCode']>;
  matchCode?: InputMaybe<Scalars['String']>;
  minNbTravelersRequiredFrom?: InputMaybe<Scalars['Int']>;
  minNbTravelersRequiredTo?: InputMaybe<Scalars['Int']>;
  priceYear?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type FilterTourExcursionSpecInput = {
  businessCode?: InputMaybe<BusinessCode>;
  matchCode?: InputMaybe<Scalars['String']>;
  matchCodes?: InputMaybe<Array<Scalars['String']>>;
  year?: InputMaybe<Scalars['Int']>;
};

export type FilterTourExtensionInput = {
  autoApproveDeadlineDpdFrom?: InputMaybe<Scalars['Int']>;
  autoApproveDeadlineDpdTo?: InputMaybe<Scalars['Int']>;
  basePriceCADFrom?: InputMaybe<Scalars['Float']>;
  basePriceCADTo?: InputMaybe<Scalars['Float']>;
  basePriceUSDFrom?: InputMaybe<Scalars['Float']>;
  basePriceUSDTo?: InputMaybe<Scalars['Float']>;
  bedCodes?: InputMaybe<Array<Scalars['String']>>;
  businessCode?: InputMaybe<BusinessCode>;
  defaultCapacityFrom?: InputMaybe<Scalars['Int']>;
  defaultCapacityTo?: InputMaybe<Scalars['Int']>;
  endGatewayCode?: InputMaybe<Scalars['String']>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreExtension?: InputMaybe<Scalars['Boolean']>;
  nbTourDaysFrom?: InputMaybe<Scalars['Int']>;
  nbTourDaysTo?: InputMaybe<Scalars['Int']>;
  priceYear?: InputMaybe<Scalars['Int']>;
  startGatewayCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
  tourCode?: InputMaybe<Scalars['String']>;
  tourType?: InputMaybe<Scalars['String']>;
  tourTypeCode?: InputMaybe<Scalars['String']>;
};

export type FilterTourInput = {
  bedCodes?: InputMaybe<Array<TourBedCode>>;
  businessCode?: InputMaybe<BusinessCode>;
  defaultCapacityFrom?: InputMaybe<Scalars['Int']>;
  defaultCapacityTo?: InputMaybe<Scalars['Int']>;
  hasPriceRules?: InputMaybe<Scalars['Boolean']>;
  hasPrivateGroup?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  nbTourDaysFrom?: InputMaybe<Scalars['Int']>;
  nbTourDaysTo?: InputMaybe<Scalars['Int']>;
  priceYear?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TripComponentStatus>;
  tourCode?: InputMaybe<Scalars['String']>;
  tourCodes?: InputMaybe<Array<Scalars['String']>>;
  tourType?: InputMaybe<TourType>;
  tourTypeCode?: InputMaybe<TourTypeCode>;
};

export type FilterTourPageContentInput = {
  businessCode: BusinessCode;
  language: Scalars['String'];
  market: Scalars['String'];
};

export type FilterTourSpecInput = {
  businessCode?: InputMaybe<BusinessCode>;
  tourCode?: InputMaybe<Scalars['String']>;
  tourCodes?: InputMaybe<Array<Scalars['String']>>;
  year?: InputMaybe<Scalars['Int']>;
};

export type FilterTripChecklistItemTemplateInput = {
  businessCode?: InputMaybe<BusinessCode>;
};

export type FilterVoucherInput = {
  bookingId?: InputMaybe<Scalars['ObjectId']>;
  businessCode?: InputMaybe<BusinessCode>;
  customerId?: InputMaybe<Scalars['ObjectId']>;
  expirationDateRange?: InputMaybe<DateOnlyRangeInput>;
  isRefundable?: InputMaybe<Scalars['Boolean']>;
  issueDateRange?: InputMaybe<DateOnlyRangeInput>;
  statuses?: InputMaybe<Array<VoucherStatus>>;
};

/** Product that represents a flight from one Gateway to another */
export type FlightPackage = PricedTripComponent & TripComponent & {
  __typename?: 'FlightPackage';
  _id: Scalars['ObjectId'];
  /** Additional information about the arrival gateway */
  arrivalGateway?: Maybe<Gateway>;
  arrivalGatewayCode: Scalars['String'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  /** Additional information about the departure gateway */
  departureGateway?: Maybe<Gateway>;
  departureGatewayCode: Scalars['String'];
  hasPriceRules: Scalars['Boolean'];
  isCustomGateway: Scalars['Boolean'];
  isLandOnly: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  /** Price for a given departure date */
  price: Amount;
  priceRules: Array<PriceRule>;
  status: TripComponentStatus;
  type: TripComponentType;
};


/** Product that represents a flight from one Gateway to another */
export type FlightPackagePriceArgs = {
  departureDate: Scalars['Date'];
};

/** Departing or Returning flight line item and configuration */
export type FlightPackageLineItem = LineItem & {
  __typename?: 'FlightPackageLineItem';
  _id: Scalars['ObjectId'];
  airportTransfer?: Maybe<AirportTransfer>;
  /** Additional information about the arrival gateway */
  arrivalGateway?: Maybe<Gateway>;
  arrivalGatewayCode: Scalars['String'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  departureDate: Scalars['Date'];
  /** Additional information about the departure gateway */
  departureGateway?: Maybe<Gateway>;
  departureGatewayCode: Scalars['String'];
  flightType: FlightType;
  isEligibleForAirportTransfer?: Maybe<Scalars['Boolean']>;
  isLandOnly: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  stopOver?: Maybe<StopOver>;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** A paginated result of Flight Packages */
export type FlightPackagePaginatedResult = {
  __typename?: 'FlightPackagePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<FlightPackage>;
};

/** Flight settings */
export type FlightSettings = {
  __typename?: 'FlightSettings';
  allowedStopoverGatewayCodes: Array<Scalars['String']>;
};

export enum FlightType {
  Departing = 'DEPARTING',
  Returning = 'RETURNING'
}

export type ForkBookingInput = {
  fromBookingId: Scalars['ObjectId'];
  newPrimaryTripId: Scalars['ObjectId'];
  transferInputs: Array<ForkTransferInput>;
  tripIds: Array<Scalars['ObjectId']>;
};

export type ForkBookingResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type ForkTransferInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  paymentMethodHash?: InputMaybe<Scalars['String']>;
  /** @deprecated Please begin using paymentMethodHash */
  savedPaymentMethodId?: InputMaybe<Scalars['ObjectId']>;
};

/** A collection of travelers (GMs) booking under a Group Coordinator (GC) on a given Departure */
export type GcDeparture = {
  __typename?: 'GCDeparture';
  _id: Scalars['ObjectId'];
  /** The actual price of the private group fee, if it were assessed based on the number of confirmed trips at the time of the API call. */
  actualPrivateGroupFeePrice?: Maybe<Money>;
  /** Adjustments that will be automatically applied to new GM quotes for this GC Departure */
  autoAppliedAdjustments: Array<GcDepartureAdjustment>;
  averageProgramPrice?: Maybe<Money>;
  businessCode: BusinessCode;
  cancelledTrips?: Maybe<Array<BookingTrip>>;
  /** The percentage of the average program fee that counts towards a companion discount for each traveler not already counting towards a free place */
  companionDiscountRate: GcDepartureBenefitRate;
  /** A summary of the companion discount benefit for the GC */
  companionDiscountSummary?: Maybe<GcDepartureCompanionDiscountSummary>;
  confirmedTrips?: Maybe<Array<BookingTrip>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  departure?: Maybe<Departure>;
  /** The date of the departure */
  departureDate: Scalars['Date'];
  /** The Departure this group is traveling on */
  departureId: Scalars['ObjectId'];
  /** Includes the date at which eTicket information is released, and whether or not it is already released */
  eTicketsRelease?: Maybe<ReleaseDetails>;
  /** The price of the private group fee, if it were assessed based on the estimated group size. Used as an estimation tool. Requires the groupSizeEstimate to be set. */
  estimatedPrivateGroupFeePrice?: Maybe<Money>;
  /** The expected number of travelers in the group, used as the stretch goal for the GC */
  expectedVolume?: Maybe<Scalars['Int']>;
  /** Includes the date at which flights are released, and whether or not they are already released */
  flightsRelease?: Maybe<ReleaseDetails>;
  /** The date at which flights are released */
  flightsReleasedDate?: Maybe<Scalars['Date']>;
  /** The rate at which the GC earns free places based on the number of travelers paying for an insurance plan */
  freeInsuranceRatio: Scalars['FreePlaceRatio'];
  /** The rate at which the GC earns free places based on the number of travelers paying for a certain trip component */
  freePlaceRatio: Scalars['FreePlaceRatio'];
  /** A breakdown of free places earned and applied for the various components of bookings on this GCDeparture */
  freePlaceSummary: GcDepartureFreePlaceSummary;
  gcProfileContent?: Maybe<GcProfileContent>;
  groupCoordinator?: Maybe<Customer>;
  /** The customer ID of the GC for this group */
  groupCoordinatorCustomerId: Scalars['ObjectId'];
  /** A configurable estimate of the number of travelers, used to estimate the private group fee */
  groupSizeEstimate?: Maybe<Scalars['Int']>;
  /** Whether or not the private group fee should be assessed at the time of reconciliation */
  includePrivateGroupFee: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  isGroupCoordinator: Scalars['Boolean'];
  isNonBookedGroupCoordinator: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  /** Rules that govern whether certain line items must, should, or cannot be added to group member trips */
  lineItemRules?: Maybe<Array<GcDepartureRule>>;
  micrositeContent?: Maybe<MicrositeContent>;
  /**
   * The URL of the microsite where GMs can quote and book
   * @deprecated Use micrositeUrls instead
   */
  micrositeUrl?: Maybe<Scalars['String']>;
  micrositeUrls: GcDepartureMicrositeUrls;
  nbTravelersCancelled: Scalars['Int'];
  nbTravelersConfirmed: Scalars['Int'];
  nbTravelersConfirmedLandOnly: Scalars['Int'];
  nbTravelersConfirmedWithFlights: Scalars['Int'];
  nbTravelersRebooked: Scalars['Int'];
  nbTravelersRequested: Scalars['Int'];
  nbTravelersWaitlisted: Scalars['Int'];
  nbTravelersWithExtension: Scalars['Int'];
  nbTravelersWithInsurance: Scalars['Int'];
  /** @deprecated No longer a valid status */
  onHoldTrips?: Maybe<Array<BookingTrip>>;
  /** The Salesforce Opportunity ID associated with this GCDeparture, if any */
  opportunityId?: Maybe<Scalars['String']>;
  pendingRebookingTrips?: Maybe<Array<BookingTrip>>;
  /** The primary currency code that will be used to generate price previews and record benefits */
  primaryCurrencyCode: CurrencyCode;
  /** A summary of the pricing for a potential group member quote for this GCDeparture */
  quoteSummary?: Maybe<GcDepartureQuoteSummary>;
  rebookedTrips?: Maybe<Array<BookingTrip>>;
  /** Rules that govern whether certain line items should be added to group member trips */
  recommendedLineItemRules?: Maybe<Array<GcDepartureRule>>;
  /** The date at which EF will review the GCDeparture to assess fees and redeem benefits for the GC */
  reconciliationDate: Scalars['Date'];
  /** The date of the recruitment meeting for the GC to promote the GCDeparture */
  recruitmentMeetingDate?: Maybe<Scalars['Date']>;
  /** The remaining amount of money available to be awarded to the GC a stipend, based on the average program price and any stipend already redeemed */
  remainingStipendAmountAvailable?: Maybe<Money>;
  requestedTrips?: Maybe<Array<BookingTrip>>;
  /** Rules that govern whether certain line items must be added to group member trips */
  requiredLineItemRules?: Maybe<Array<GcDepartureRule>>;
  /** Rules that govern whether certain line items cannot be added to group member trips */
  restrictedLineItemRules?: Maybe<Array<GcDepartureRule>>;
  status: GcDepartureStatus;
  /** A breakdown of the stipend benefit for the GC */
  stipendDetail?: Maybe<GcDepartureStipendDetail>;
  /** The percentage of the average program fee that counts towards the stipend for each traveler not already counting towards a free place */
  stipendRate: GcDepartureBenefitRate;
  /** The terms signed by the group coordinator */
  termsSignature?: Maybe<TermsSignature>;
  /** The tour code associated with the departure */
  tourCode: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitlistedTrips?: Maybe<Array<BookingTrip>>;
};


/** A collection of travelers (GMs) booking under a Group Coordinator (GC) on a given Departure */
export type GcDepartureGcProfileContentArgs = {
  language: Scalars['String'];
};


/** A collection of travelers (GMs) booking under a Group Coordinator (GC) on a given Departure */
export type GcDepartureMicrositeContentArgs = {
  language: Scalars['String'];
};

/** Configuration allowing the user to specify which adjustments should auto-apply to GM quotes on a GC Departure. Prices are determined by the settings in this object and visibility is set on the Adjustment itself */
export type GcDepartureAdjustment = {
  __typename?: 'GCDepartureAdjustment';
  adjustment?: Maybe<Adjustment>;
  amount: Amount;
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
};

/** An integer representing the percentage a GC earns when redeeming certain types of benefits */
export type GcDepartureBenefitRate = {
  __typename?: 'GCDepartureBenefitRate';
  asDecimal: Scalars['Float'];
  value: Scalars['Float'];
};

export enum GcDepartureCancellationCategory {
  Financial = 'FINANCIAL',
  LifeEvent = 'LIFE_EVENT',
  ProductTransition = 'PRODUCT_TRANSITION',
  TravelerDiscontent = 'TRAVELER_DISCONTENT',
  TravelRequirementNotMet = 'TRAVEL_REQUIREMENT_NOT_MET',
  Unknown = 'UNKNOWN',
  WojoCancellation = 'WOJO_CANCELLATION',
  WorldEvent = 'WORLD_EVENT'
}

export type GcDepartureCompanionDiscountSummary = {
  __typename?: 'GCDepartureCompanionDiscountSummary';
  /** The total amount of money that has been applied to trips as a companion discount */
  amountApplied: Money;
  /** The remaining amount of money available to be applied to trips as a companion discount */
  amountAvailable: Money;
  /** The IDs of the trips that have had the companion discount applied to them */
  appliedToTripIds: Array<Scalars['ObjectId']>;
  /** The trips that have had the companion discount applied to them */
  appliedToTrips: Array<BookingTrip>;
};

/** A preview of a flight package that a group member could add to their quote */
export type GcDepartureFlightPackageSummary = {
  __typename?: 'GCDepartureFlightPackageSummary';
  /** The date that this flight will depart based on whether the extension is recommended or required */
  departureDate: Scalars['Date'];
  flightPackage: FlightPackage;
  /** The price in the gcDeparture's primaryCurrencyCode */
  price: Money;
};

export type GcDepartureFreePlaceDetail = {
  __typename?: 'GCDepartureFreePlaceDetail';
  /** The IDs of the trips that have had the free places applied to them for this trip component */
  appliedToTripIds: Array<Scalars['ObjectId']>;
  /** The trips that have had the free places applied to them for this trip component */
  appliedToTrips: Array<BookingTrip>;
  /** The number of free places earned for this trip component */
  placesEarned: Scalars['Int'];
};

export type GcDepartureFreePlaceExcursionDetail = {
  __typename?: 'GCDepartureFreePlaceExcursionDetail';
  /** The IDs of the trips that have had the free places applied to them */
  appliedToTripIds: Array<Scalars['ObjectId']>;
  /** The trips that have had the free places applied to them */
  appliedToTrips: Array<BookingTrip>;
  /** The line item code of the excursion */
  lineItemCode: Scalars['LineItemCode'];
  /** The number of free places earned for the excursion */
  placesEarned: Scalars['Int'];
};

export type GcDepartureFreePlaceSummary = {
  __typename?: 'GCDepartureFreePlaceSummary';
  excursions: Array<GcDepartureFreePlaceExcursionDetail>;
  extension?: Maybe<GcDepartureFreePlaceDetail>;
  insurance: GcDepartureFreePlaceDetail;
  tour: GcDepartureFreePlaceDetail;
};

export type GcDepartureMicrositeUrls = {
  __typename?: 'GCDepartureMicrositeUrls';
  ca?: Maybe<Scalars['String']>;
  us?: Maybe<Scalars['String']>;
};

/** A paginated result of Departures */
export type GcDeparturePaginatedResult = {
  __typename?: 'GCDeparturePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<GcDeparture>;
};

/** A prospective traveler aspiring to book under a Group Coordinator (GC) on a given Departure */
export type GcDepartureProspect = {
  __typename?: 'GCDepartureProspect';
  _id: Scalars['ObjectId'];
  address?: Maybe<Address>;
  bookedTrip?: Maybe<BookingTrip>;
  businessCode?: Maybe<BusinessCode>;
  cancelledTrip?: Maybe<BookingTrip>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Customer>;
  email?: Maybe<Scalars['Email']>;
  firstName: Scalars['String'];
  gcDeparture?: Maybe<GcDeparture>;
  gcDepartureId: Scalars['ObjectId'];
  groupCoordinatorCustomerId: Scalars['ObjectId'];
  isBooked: Scalars['Boolean'];
  /** Check if GC Departure of the Prospect is in the past */
  isPast?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  levelOfInterest?: Maybe<GcDepartureProspectLevelOfInterest>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['PhoneNumber']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum GcDepartureProspectLevelOfInterest {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** A paginated result of GC Departure Prospects. */
export type GcDepartureProspectPaginatedResult = {
  __typename?: 'GCDepartureProspectPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<GcDepartureProspect>;
};

export type GcDepartureProspectRemovedResult = {
  __typename?: 'GCDepartureProspectRemovedResult';
  removedId: Scalars['ObjectId'];
};

export enum GcDepartureProspectSortField {
  BusinessCode = 'businessCode',
  CustomerId = 'customerId',
  Email = 'email',
  FirstName = 'firstName',
  GcDepartureId = 'gcDepartureId',
  GroupCoordinatorCustomerId = 'groupCoordinatorCustomerId',
  LastName = 'lastName',
  LevelOfInterest = 'levelOfInterest',
  Notes = 'notes',
  PhoneNumber = 'phoneNumber'
}

/** A slice of the pricing summary of a potential group member quote for a GC departure */
export type GcDepartureQuoteSummary = {
  __typename?: 'GCDepartureQuoteSummary';
  departingFlightSummary?: Maybe<GcDepartureFlightPackageSummary>;
  insurancePlanOffers: Array<InsurancePlanOffer>;
  privateGroupFeePrice?: Maybe<Money>;
  returningFlightSummary?: Maybe<GcDepartureFlightPackageSummary>;
  totalFlightPackagePrice?: Maybe<Money>;
  /** The base price of the quote with all items recommended by the GC. Includes the tour, extension, optionals, and flight packages */
  totalWithRecommendItems: Money;
  /** The base price of the quote with all items required by the GC. Includes the tour, extension, optionals, and flight packages */
  totalWithRequiredItems: Money;
};

export type GcDepartureQuoteSummaryAdjustmentInput = {
  amount?: Array<MoneyInput>;
  lineItemCode: Scalars['LineItemCode'];
  price: MoneyInput;
};

export type GcDepartureQuoteSummaryInput = {
  /** The ID of the GC Departure to calculate a quote summary for */
  _id: Scalars['ObjectId'];
  autoAppliedAdjustments?: InputMaybe<Array<GcDepartureQuoteSummaryAdjustmentInput>>;
  effectivePrivateGroupFeeGroupSize?: InputMaybe<Scalars['Int']>;
  includePrivateGroupFee?: InputMaybe<Scalars['Boolean']>;
  lineItemRules: Array<GcDepartureQuoteSummaryLineItemRuleInput>;
};

export type GcDepartureQuoteSummaryLineItemRuleInput = {
  level: GcDepartureRuleLevel;
  lineItemCode: Scalars['LineItemCode'];
  lineItemType: GcDepartureRuleLineItemType;
};

/** Allows a GC to require, recommend, or restrict certains line item */
export type GcDepartureRule = {
  __typename?: 'GCDepartureRule';
  level: GcDepartureRuleLevel;
  lineItemCode: Scalars['LineItemCode'];
  lineItemType: GcDepartureRuleLineItemType;
};

export enum GcDepartureRuleLevel {
  Recommended = 'RECOMMENDED',
  Required = 'REQUIRED',
  Restricted = 'RESTRICTED'
}

export enum GcDepartureRuleLineItemType {
  DepartingFlight = 'DEPARTING_FLIGHT',
  Excursion = 'EXCURSION',
  Extension = 'EXTENSION',
  ReturningFlight = 'RETURNING_FLIGHT'
}

/** GC Departure settings */
export type GcDepartureSettings = {
  __typename?: 'GCDepartureSettings';
  /** The DPD at which this business expects flight segments to have e-ticket numbers populated */
  eTicketsAvailabilityDpd?: Maybe<Scalars['Int']>;
  /** The DPD at which this business expects flights information populated */
  flightsReleasedDpd?: Maybe<Scalars['Int']>;
  reconciliationDpd?: Maybe<Scalars['Int']>;
};

export enum GcDepartureSortField {
  DepartureDate = 'departureDate',
  ReconciliationDate = 'reconciliationDate'
}

export enum GcDepartureStatus {
  /** The GC Departure has been cancelled, along with the associated Trips */
  Cancelled = 'CANCELLED',
  /** The GC Departure has been prepped for booking and GMs can book */
  Confirmed = 'CONFIRMED',
  /** The GC is in the process of rebooking their group onto a new Departure */
  PendingRebooking = 'PENDING_REBOOKING',
  /** The GC Departure has been prepped for pricing but Trips cannot be booked against it yet */
  Quoted = 'QUOTED',
  /** The GC Departure has been rebooked onto a new Departure and will be tracked separately on a new GC Departure */
  Rebooked = 'REBOOKED'
}

/** The status of any stipend issued to the GC */
export type GcDepartureStipendDetail = {
  __typename?: 'GCDepartureStipendDetail';
  amount?: Maybe<Money>;
  cancelledDate?: Maybe<Scalars['Date']>;
  checkNumber?: Maybe<Scalars['String']>;
  issuedDate?: Maybe<Scalars['Date']>;
  status: GcDepartureStipendDetailStatus;
};

export enum GcDepartureStipendDetailStatus {
  Cancelled = 'CANCELLED',
  PaidOut = 'PAID_OUT',
  Pending = 'PENDING'
}

/** A past group member of a GC with all their past trips with the GC. */
export type GcPastGroupMember = {
  __typename?: 'GCPastGroupMember';
  customer?: Maybe<Customer>;
  /** The customer ID of the past group member. */
  customerId: Scalars['ObjectId'];
  /** The group members past trips in CANCELLED or CONFIRMED status */
  trips: Array<BookingTrip>;
};

export type GcProfileContent = {
  __typename?: 'GCProfileContent';
  backgroundImage?: Maybe<ContentAsset>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullSlug: Scalars['String'];
  gcCustomer?: Maybe<Customer>;
  gcCustomerId?: Maybe<Scalars['String']>;
  gcDepartures: Array<GcDeparture>;
  image?: Maybe<ContentAsset>;
  logo?: Maybe<ContentAsset>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type GcProfileContentGcDeparturesArgs = {
  businessCode: BusinessCode;
};

/** A gateway that represents either an Airport or a City */
export type Gateway = {
  __typename?: 'Gateway';
  _id: Scalars['ObjectId'];
  airRegionCode: Scalars['String'];
  cityCode: Scalars['String'];
  code: Scalars['String'];
  internalCityLongName: Scalars['String'];
  internalCityName: Scalars['String'];
  isCityCodeOnly: Scalars['Boolean'];
  name: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Float']>;
  utcOffsetSummer?: Maybe<Scalars['Float']>;
  utcOffsetWinter?: Maybe<Scalars['Float']>;
};

/** A paginated result of Gateways */
export type GatewayPaginatedResult = {
  __typename?: 'GatewayPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Gateway>;
};

export type GeographicRegion = Place & {
  __typename?: 'GeographicRegion';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  parentIds: Array<Scalars['ObjectId']>;
  type: PlaceType;
};

/** A summary of the global rewards status and point for Group Coordinators */
export type GlobalRewardsSummary = {
  __typename?: 'GlobalRewardsSummary';
  firstName: Scalars['String'];
  globalRewardsId: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  membershipLevelId: Scalars['Int'];
  membershipLevelName: Scalars['String'];
  pointsAccrued: Scalars['Int'];
  pointsActive: Scalars['Int'];
  pointsPending: Scalars['Int'];
  pointsRedeemed: Scalars['Int'];
  pointsSuspended: Scalars['Int'];
  pointsTotal: Scalars['Int'];
};

export type HealthNotesChecklistItem = SystemChecklistItem & {
  __typename?: 'HealthNotesChecklistItem';
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

/** Response from the Zendesk help center API */
export type HelpCenterArticle = {
  __typename?: 'HelpCenterArticle';
  id?: Maybe<Scalars['String']>;
  sectionName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Response from the Zendesk help center API */
export type HelpCenterArticleResponse = {
  __typename?: 'HelpCenterArticleResponse';
  count?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  previousPage?: Maybe<Scalars['String']>;
  results?: Maybe<Array<HelpCenterArticle>>;
};

export enum IdentityDocumentType {
  DriversLicense = 'DRIVERS_LICENSE',
  Passport = 'PASSPORT'
}

/** Immutable term error */
export type ImmutableTermError = DomainError & {
  __typename?: 'ImmutableTermError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Increases the non-expired voucher by a dollar amount */
export type IncreaseVoucherInput = {
  /** The ID of the Voucher */
  _id: Scalars['ObjectId'];
  /** The amount to increase the Voucher by */
  amount: MoneyInput;
  /** The reason that the voucher is being increased */
  reason: Scalars['String'];
};

export type IncreaseVoucherResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

export type InsuranceAddonLineItem = LineItem & {
  __typename?: 'InsuranceAddonLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** Insurance extension plan details */
export type InsuranceExtension = PricedTripComponent & TripComponent & {
  __typename?: 'InsuranceExtension';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  status: TripComponentStatus;
  type: TripComponentType;
};

export type InsuranceExtensionLineItem = LineItem & {
  __typename?: 'InsuranceExtensionLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  daysToExtend: Scalars['Int'];
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

export type InsuranceLineItem = LineItem & {
  __typename?: 'InsuranceLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  travelerInsuranceId: Scalars['ObjectId'];
  travelerInsuranceIdCreationDate: Scalars['Date'];
  type: TripComponentType;
};

/** Primary insurance plan */
export type InsurancePlan = PricedTripComponent & TripComponent & {
  __typename?: 'InsurancePlan';
  _id: Scalars['ObjectId'];
  addons: Array<InsurancePlanAddon>;
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  hasPriceRules: Scalars['Boolean'];
  insuranceExtension?: Maybe<InsuranceExtension>;
  internalName: Scalars['String'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  planCode: Scalars['String'];
  policyNumber: Scalars['String'];
  priceRules: Array<PriceRule>;
  providerName: Scalars['String'];
  purchasableAfterDays?: Maybe<Scalars['Int']>;
  purchasableWithinDays?: Maybe<Scalars['Int']>;
  status: TripComponentStatus;
  type: TripComponentType;
};

/** Secondary insurance to be combined with primary insurance */
export type InsurancePlanAddon = PricedTripComponent & TripComponent & {
  __typename?: 'InsurancePlanAddon';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  hasPriceRules: Scalars['Boolean'];
  includedWithinDays?: Maybe<Scalars['Int']>;
  insurancePlanAddonCode: Scalars['String'];
  internalName: Scalars['String'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  preventPurchaseAfterDays?: Maybe<Scalars['Int']>;
  priceRules: Array<PriceRule>;
  status: TripComponentStatus;
  type: TripComponentType;
};

export type InsurancePlanAddonOffer = {
  __typename?: 'InsurancePlanAddonOffer';
  insurancePlanAddon: InsurancePlanAddon;
  price: Money;
};

/** Content describing an insurance plan */
export type InsurancePlanContent = {
  __typename?: 'InsurancePlanContent';
  addons: Array<InsurancePlanContentAddon>;
  benefits: Array<InsurancePlanContentBenefit>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  includedAddonsDescription?: Maybe<Scalars['String']>;
  includedAddonsSubtitle?: Maybe<Scalars['String']>;
  includedAddonsTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  planCode?: Maybe<Scalars['String']>;
  planSummaryDocument?: Maybe<ContentAsset>;
  planSummaryLink?: Maybe<ContentLink>;
  underwriterDescription?: Maybe<Scalars['String']>;
  underwriterDisclaimer?: Maybe<Scalars['String']>;
};

/** Content describing an insurance plan addon */
export type InsurancePlanContentAddon = {
  __typename?: 'InsurancePlanContentAddon';
  addonCode?: Maybe<Scalars['String']>;
  benefits: Array<InsurancePlanContentBenefit>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Content describing an insurance plan benefit */
export type InsurancePlanContentBenefit = {
  __typename?: 'InsurancePlanContentBenefit';
  description?: Maybe<Scalars['String']>;
  /** Describes the limit for a benefit e.g. 'Up to $50,000' */
  limitMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InsurancePlanOffer = {
  __typename?: 'InsurancePlanOffer';
  addons: Array<InsurancePlanAddonOffer>;
  content?: Maybe<InsurancePlanContent>;
  insurancePlan: InsurancePlan;
  price: Money;
};


export type InsurancePlanOfferContentArgs = {
  language: Scalars['String'];
};

/** Paginated result of Insurance Plans, filtered and sorted */
export type InsurancePlanPaginatedResult = {
  __typename?: 'InsurancePlanPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<InsurancePlan>;
};

export enum InsurancePlanSortField {
  PlanCode = 'planCode'
}

/** Insurance settings */
export type InsuranceSettings = {
  __typename?: 'InsuranceSettings';
  nbMaximumDaysToExtend: Scalars['Int'];
  removalWindowDays: Scalars['Int'];
};

/** Settings related to 3rd party APIs or integrations */
export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  /** Mailroom settings */
  mailroom?: Maybe<MailroomSettings>;
  /** Atlas Product Market code for the CA market */
  productMarketCodeCA?: Maybe<Scalars['String']>;
  /** Atlas Product Market code for the US market */
  productMarketCodeUS?: Maybe<Scalars['String']>;
  /** Canadian office ID for RightFlight, required for all API calls */
  rightFlightBrandIdCA?: Maybe<Scalars['String']>;
  /** US office ID for RightFlight, required for all API calls */
  rightFlightBrandIdUS?: Maybe<Scalars['String']>;
  /** Settings for transactional emails */
  transactionalEmails?: Maybe<Array<TransactionalEmailSettings>>;
};

/** Invalid adjustment error */
export type InvalidAdjustmentError = DomainError & {
  __typename?: 'InvalidAdjustmentError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The amount object is invalid */
export type InvalidAmountError = DomainError & {
  __typename?: 'InvalidAmountError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The campaign is invalid. */
export type InvalidCampaignError = DomainError & {
  __typename?: 'InvalidCampaignError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The DateOnly object is not a valid date type */
export type InvalidDateError = DomainError & {
  __typename?: 'InvalidDateError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidDepartureError = DomainError & {
  __typename?: 'InvalidDepartureError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The deposit amount item is invalid */
export type InvalidDepositItemError = DomainError & {
  __typename?: 'InvalidDepositItemError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid evaluation */
export type InvalidEvaluationError = DomainError & {
  __typename?: 'InvalidEvaluationError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidExtendedStayError = DomainError & {
  __typename?: 'InvalidExtendedStayError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid fee error */
export type InvalidFeeError = DomainError & {
  __typename?: 'InvalidFeeError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidFlightPackageError = DomainError & {
  __typename?: 'InvalidFlightPackageError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidGcDepartureError = DomainError & {
  __typename?: 'InvalidGCDepartureError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidGcDepartureProspectError = DomainError & {
  __typename?: 'InvalidGCDepartureProspectError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid insurance extension */
export type InvalidInsuranceExtensionError = DomainError & {
  __typename?: 'InvalidInsuranceExtensionError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid insurance plan */
export type InvalidInsurancePlanError = DomainError & {
  __typename?: 'InvalidInsurancePlanError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Magic link is not valid */
export type InvalidMagicLinkError = DomainError & {
  __typename?: 'InvalidMagicLinkError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The money object is invalid */
export type InvalidMoneyError = DomainError & {
  __typename?: 'InvalidMoneyError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid trip error */
export type InvalidOrderError = DomainError & {
  __typename?: 'InvalidOrderError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid payment error */
export type InvalidPaymentError = DomainError & {
  __typename?: 'InvalidPaymentError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  /** List of payment errors */
  paymentErrors: Array<Scalars['String']>;
};

/** Invalid payment schedule error */
export type InvalidPaymentScheduleError = DomainError & {
  __typename?: 'InvalidPaymentScheduleError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** A PriceYear failed to be created due to validation issues */
export type InvalidPriceYearError = DomainError & {
  __typename?: 'InvalidPriceYearError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The PriceRule is not valid at time of creation */
export type InvalidPricingError = DomainError & {
  __typename?: 'InvalidPricingError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid private group error */
export type InvalidPrivateGroupError = DomainError & {
  __typename?: 'InvalidPrivateGroupError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** The promotion is invalid. */
export type InvalidPromoError = DomainError & {
  __typename?: 'InvalidPromoError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid rating error */
export type InvalidRatingError = DomainError & {
  __typename?: 'InvalidRatingError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid review error */
export type InvalidReviewError = DomainError & {
  __typename?: 'InvalidReviewError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid review response error */
export type InvalidReviewResponseError = DomainError & {
  __typename?: 'InvalidReviewResponseError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** User credentials are invalid during login */
export type InvalidSigninError = DomainError & {
  __typename?: 'InvalidSigninError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid term error */
export type InvalidTermError = DomainError & {
  __typename?: 'InvalidTermError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type InvalidTourCabinCodeError = DomainError & {
  __typename?: 'InvalidTourCabinCodeError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid tour error */
export type InvalidTourError = DomainError & {
  __typename?: 'InvalidTourError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** An invalid trip checklist item template was specified */
export type InvalidTripChecklistItemTemplateError = DomainError & {
  __typename?: 'InvalidTripChecklistItemTemplateError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Invalid voucher */
export type InvalidVoucherError = DomainError & {
  __typename?: 'InvalidVoucherError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type IssueARefundResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPaymentError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError;

export type LeadInPrice = {
  __typename?: 'LeadInPrice';
  /** Amount of discount if applicable. Null if no discount. */
  discountAmount?: Maybe<Money>;
  /** Minimum remaining redemptions for discount offer codes. */
  discountLowestSpotsRemaining?: Maybe<Scalars['Int']>;
  /** Offer codes to calculate sale price. */
  discountOfferCodes: Array<Scalars['String']>;
  /** Original price. */
  price: Money;
  /** Price after discount if applicable. Null if no discount. */
  salePrice?: Maybe<Money>;
};

export type LegacyCustomerIds = {
  __typename?: 'LegacyCustomerIds';
  gatSalesforceAccountId?: Maybe<Scalars['String']>;
  jasmineCustomerId?: Maybe<Scalars['String']>;
  phxIndividualIds?: Maybe<Array<Scalars['String']>>;
  ubSalesforceAccountId?: Maybe<Scalars['String']>;
};

/** Base line item */
export type LineItem = {
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** Line item cancellation meta data. */
export type LineItemCancellationMeta = {
  __typename?: 'LineItemCancellationMeta';
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationReason: LineItemCancellationReason;
  cancelledBy?: Maybe<Scalars['ObjectId']>;
};

export enum LineItemCancellationReason {
  Financial = 'FINANCIAL',
  HealthOrLifeEvent = 'HEALTH_OR_LIFE_EVENT',
  ParentTripCancelled = 'PARENT_TRIP_CANCELLED',
  TravelerDiscontent = 'TRAVELER_DISCONTENT',
  TravelRequirementNotMet = 'TRAVEL_REQUIREMENT_NOT_MET',
  TripDetailsChanged = 'TRIP_DETAILS_CHANGED',
  Unknown = 'UNKNOWN',
  WojoCancellation = 'WOJO_CANCELLATION',
  WorldEvent = 'WORLD_EVENT'
}

export enum LineItemStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  PendingCancellation = 'PENDING_CANCELLATION',
  PendingRebooking = 'PENDING_REBOOKING',
  Quoted = 'QUOTED',
  Rebooked = 'REBOOKED',
  Requested = 'REQUESTED',
  Waitlisted = 'WAITLISTED'
}

/** A customer's repeat loyalty information with a specific business. */
export type LoyaltyLevel = {
  __typename?: 'LoyaltyLevel';
  /** The unique identifier of this item. The combination of businessCode and customerId is the more appropriate unique key */
  _id: Scalars['ObjectId'];
  /** The business code that this loyalty information is associated with */
  businessCode: BusinessCode;
  /** The content associated with this loyalty level */
  content?: Maybe<LoyaltyLevelContent>;
  /** The customer id that this loyalty information is associated with */
  customerId: Scalars['ObjectId'];
  /** The customer's loyalty level based on the repeat threshold in promotion settings that this customer has reached */
  loyaltyLevel: Scalars['Int'];
  /** The number of qualifying trips that count toward the customer's loyalty level */
  nbConfirmedLoyaltyTrips: Scalars['Int'];
  /** The number of additional qualifying trips the customer needs to reach the next loyalty level. If the busines has not configured repeat thresholds, or the customer is at the maximum loyalty level, this will be null */
  nbTripsUntilNextLevelReached?: Maybe<Scalars['Int']>;
  /** The per-tour discount that the customer is entitled based on the loyalty level they have reached */
  repeatDiscount?: Maybe<Amount>;
  /** The promo code associated with the repeat discount */
  repeatPromoCode?: Maybe<Scalars['String']>;
  /** The discount that the customer is currently entitled based on the loyalty level they have reached, as well as whether their previous qualifying trip returned within the return window defined in promo settings. */
  returnWindowDiscount?: Maybe<Amount>;
};


/** A customer's repeat loyalty information with a specific business. */
export type LoyaltyLevelContentArgs = {
  language: Scalars['String'];
};

export type LoyaltyLevelContent = {
  __typename?: 'LoyaltyLevelContent';
  icon?: Maybe<ContentAsset>;
  name?: Maybe<Scalars['String']>;
};

export type MagicLinkResult = {
  __typename?: 'MagicLinkResult';
  expiresAt: Scalars['DateTime'];
};

/** Settings for mailroom. */
export type MailroomSettings = {
  __typename?: 'MailroomSettings';
  isActive: Scalars['Boolean'];
  nbPreDepartureMailDaysFrom: Scalars['Int'];
  nbPreDepartureMailDaysTo: Scalars['Int'];
  preDepartureMailMinimumBalance: Amount;
};

export type MakeAPaymentResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidAmountError | InvalidDateError | InvalidOrderError | InvalidPaymentError | InvalidPaymentScheduleError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError;

export type MatchRoommatesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | MatchRoommatesSuccess | NotAuthorizedError | NotFoundError | ValidationError;

export type MatchRoommatesSuccess = {
  __typename?: 'MatchRoommatesSuccess';
  data: Array<Booking>;
};

export type MergeBookingInput = {
  fromBookingId: Scalars['ObjectId'];
  toBookingId: Scalars['ObjectId'];
};

export type MergeBookingResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

/** Merge two customers */
export type MergeCustomerInput = {
  /** The id of the customer merge into and keep. */
  newCustomerId: Scalars['ObjectId'];
  /** The id of the customer to merge and deprecate. */
  oldCustomerId: Scalars['ObjectId'];
};

export type MergeCustomerResult = BusinessNotSpecifiedError | Customer | CustomerMergeError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type MicrositeContent = {
  __typename?: 'MicrositeContent';
  fullSlug?: Maybe<Scalars['String']>;
  gcDeparture?: Maybe<GcDeparture>;
  gcDepartureId?: Maybe<Scalars['String']>;
  logo?: Maybe<ContentAsset>;
  title?: Maybe<Scalars['String']>;
  tripBuilderMarketingBody?: Maybe<Scalars['String']>;
  tripBuilderMarketingTitle?: Maybe<Scalars['String']>;
};

/** Represents monetary value in a specific currency */
export type Money = {
  __typename?: 'Money';
  currencyCode: CurrencyCode;
  formatted: Scalars['String'];
  value: Scalars['Float'];
};

/** Input for standard money type */
export type MoneyInput = {
  currencyCode: CurrencyCode;
  value: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts the group terms as a group coordinator. Signs the GC departure, as well as the GC's trip if it exists */
  acceptGCDepartureTerms: AcceptGcDepartureTermsResult;
  /** Accepts the terms and conditions for a single trip on a booking */
  acceptTripTerms: AcceptTripTermsResult;
  /** Add promos to a campaign */
  addCampaignPromos: AddCampaignPromosResult;
  /** Adds a payment schedule to a draft booking as long as one does not already exist */
  addDraftBookingPaymentSchedule: AddDraftBookingPaymentScheduleResult;
  /** Adds adjustment to a trip on a draft order. */
  addDraftOrderAdjustment: AddDraftOrderAdjustmentResult;
  /** Adds a campaign to a draft order for specified trips. */
  addDraftOrderCampaign: AddDraftOrderCampaignResult;
  /** Adds excursions to trips on a draft order. */
  addDraftOrderExcursions: AddDraftOrderExcursionsResult;
  /** Adds extension on draft order for specified trips. */
  addDraftOrderExtension: AddDraftOrderExtensionResult;
  /** Adds fee to a draft order for the specified trips. */
  addDraftOrderFee: AddDraftOrderFeeResult;
  /** Adds insurance on draft order for specified trips. */
  addDraftOrderInsurance: AddDraftOrderInsuranceResult;
  /** Adds insurance plan addons to one or more trips on a draft order. */
  addDraftOrderInsuranceAddon: AddDraftOrderInsuranceAddonResult;
  /** Adds insurance extension for a draft order to one or more trips. */
  addDraftOrderInsuranceExtension: AddDraftOrderInsuranceExtensionResult;
  /** Adds post-stay on draft order for specified trips. */
  addDraftOrderPostStay: AddDraftOrderPostStayResult;
  /** Adds pre-stay on draft order for specified trips. */
  addDraftOrderPreStay: AddDraftOrderPreStayResult;
  /** Adds private group fee to a draft order for the specified trips. */
  addDraftOrderPrivateGroup: AddDraftOrderPrivateGroupResult;
  /** Adds a promo to a draft order for specified trips. */
  addDraftOrderPromo: AddDraftOrderPromoResult;
  /** Adds a Trip to an existing Draft Quote. */
  addDraftQuoteTrip: AddDraftQuoteTripResult;
  /** Update an employee by id. */
  addEmployeesToRole: AddEmployeesToRoleResult;
  /** Adds a private group to a tour */
  addPrivateGroup: AddPrivateGroupResult;
  /** Add promo codes to a promo */
  addPromoCodes: AddPromoCodesResult;
  /** Add customers to a promo */
  addPromoCustomers: AddPromoCustomersResult;
  /** Add gc departure to a promo */
  addPromoGcDepartures: AddPromoGcDeparturesResult;
  /** Adds additional targets to a promo */
  addPromoTargets: AddPromoTargetsResult;
  /** Adds a Tour Excursion to this tour, provided it is set up in the Tour's spec and is not already on the Tour. */
  addTourExcursion: AddTourExcursionResult;
  /** Adds a Tour Excursion to this Tour Extension, provided it is set up in the Tour Extension's spec and is not already on the Extension. */
  addTourExtensionExcursion: AddTourExtensionExcursionResult;
  /** Generate a magic link for a customer or tour director and return it */
  adminCreateMagicLink: AdminCreateMagicLinkResult;
  /** Recalculates and applies or reapplies the private group fee to all confirmed Trips on Bookings associated with a GC Departure. If the fee is already found on a trip with a different price, it will be removed and reapplied with the new price. Does not use a draft publish flow and happens directly on the bookings. Will not apply the private group line item to quotes. Uses the number of confirmed trips as the group size by default. */
  applyGCDeparturePrivateGroupFee: ApplyGcDeparturePrivateGroupFeeResult;
  bulkCreateDepartureExcursions: BulkCreateDepartureExcursionsResult;
  bulkCreateDepartureExtensionExcursions: BulkCreateDepartureExtensionExcursionsResult;
  bulkCreateDepartureExtensionRooms: BulkCreateDepartureExtensionRoomsResult;
  bulkCreateDepartureExtensions: BulkCreateDepartureExtensionsResult;
  bulkCreateDepartureRooms: BulkCreateDepartureRoomsResult;
  bulkCreateDepartures: BulkCreateDeparturesResult;
  /** Bulk create flight packages */
  bulkCreateFlightPackages: BulkCreateFlightPackagesResult;
  /** Create GC Departure Prospects in bulk. */
  bulkCreateGCDepartureProspects: BulkCreateGcDepartureProspectsResult;
  /** Bulk create private groups */
  bulkCreatePrivateGroups: BulkCreatePrivateGroupsResult;
  bulkCreatePromoTargets: BulkCreatePromoTargetsResult;
  bulkCreatePromos: BulkCreatePromosResult;
  bulkUpdateDepartureExcursions: BulkUpdateDepartureExcursionsResult;
  bulkUpdateDepartureExtensionExcursions: BulkUpdateDepartureExtensionExcursionsResult;
  bulkUpdateDepartureExtensionRooms: BulkUpdateDepartureExtensionRoomsResult;
  bulkUpdateDepartureExtensions: BulkUpdateDepartureExtensionsResult;
  bulkUpdateDepartureOwners: BulkUpdateDepartureOwnersResult;
  bulkUpdateDepartureRooms: BulkUpdateDepartureRoomsResult;
  bulkUpdateDepartures: BulkUpdateDeparturesResult;
  bulkUpdateExtendedStayPriceRules: BulkUpdateExtendedStayPriceRulesResult;
  /** Bulk update the price rules for fees */
  bulkUpdateFeePriceRules: BulkUpdateFeePriceRulesResult;
  /** Bulk update flight package price rules, creates the flight package if it doesn't exist. */
  bulkUpdateFlightPackagePriceRules: BulkUpdateFlightPackagePriceRulesResult;
  bulkUpdateInsurancePlanAddonPriceRules: BulkUpdateInsurancePlanAddonPriceRulesResult;
  bulkUpdateInsurancePlanPriceRules: BulkUpdateInsurancePlanPriceRulesResult;
  /** Bulk update the price rules for private groups */
  bulkUpdatePrivateGroupPriceRules: BulkUpdatePrivateGroupPriceRulesResult;
  /** Bulk update private groups */
  bulkUpdatePrivateGroups: BulkUpdatePrivateGroupsResult;
  /** Bulk Update promos. */
  bulkUpdatePromos: BulkUpdatePromosResult;
  bulkUpdateTourExcursionPriceRules: BulkUpdateTourExcursionPriceRulesResult;
  /** Bulk Updates details of a tour room. */
  bulkUpdateTourExcursions: BulkUpdateTourExcursionsResult;
  bulkUpdateTourExtensionPriceRules: BulkUpdateTourExtensionPriceRulesResult;
  bulkUpdateTourExtensionRoomPriceRules: BulkUpdateTourExtensionRoomPriceRulesResult;
  /** Bulk Updates details of a tour extension room. */
  bulkUpdateTourExtensionRooms: BulkUpdateTourExtensionRoomsResult;
  /** Bulk Update Tour Extensions */
  bulkUpdateTourExtensions: BulkUpdateTourExtensionsResult;
  bulkUpdateTourPriceRules: BulkUpdateTourPriceRulesResult;
  bulkUpdateTourRoomPriceRules: BulkUpdateTourRoomPriceRulesResult;
  /** Bulk Updates details of a tour room. */
  bulkUpdateTourRooms: BulkUpdateTourRoomsResult;
  /** Bulk Update tours. */
  bulkUpdateTours: BulkUpdateToursResult;
  calculatePaymentSchedule: CalculatePaymentScheduleResult;
  /** Cancel a Draft Booking by cancelling all of its trips. */
  cancelDraftBooking: CancelDraftBookingResult;
  /** Cancels a payment schedule on a draft booking */
  cancelDraftBookingPaymentSchedule: CancelDraftBookingPaymentScheduleResult;
  /** Cancel one or more trips on a Draft Booking */
  cancelDraftBookingTrips: CancelDraftBookingTripsResult;
  /** Cancel a single GC Departure that is not already cancelled */
  cancelGCDeparture: CancelGcDepartureResult;
  /** Changes the expiration date of a voucher */
  changeVoucherExpiration: ChangeVoucherExpirationResult;
  /** Checks a trip checklist item */
  checkTripChecklistItem: CheckTripChecklistItemResult;
  /** Confirm a single GC Departure */
  confirmGCDeparture: ConfirmGcDepartureResult;
  /** Create a new adjustment for a given price year */
  createAdjustment: CreateAdjustmentResult;
  /** Create a new API key */
  createApiKey: CreateApiKeyResult;
  /** Create a new campaign */
  createCampaign: CreateCampaignResult;
  createCategory: CreateCategoryResult;
  createChatToken?: Maybe<ExternalToken>;
  /** Create a new customer from an email */
  createCustomer: CreateCustomerResult;
  createDepartureExcursion: CreateDepartureExcursionResult;
  createDepartureExtension: CreateDepartureExtensionResult;
  createDepartureExtensionExcursion: CreateDepartureExtensionExcursionResult;
  createDepartureExtensionRoom: CreateDepartureExtensionRoomResult;
  createDepartureRoom: CreateDepartureRoomResult;
  /** Create a new draft booking */
  createDraftOrder: CreateDraftOrderResult;
  /** Create a new draft term */
  createDraftTerm: CreateDraftTermResult;
  /** Create a new extended stay */
  createExtendedStay: CreateExtendedStayResult;
  /** Create a new fee */
  createFee: CreateFeeResult;
  /** Create a new flight package */
  createFlightPackage: CreateFlightPackageResult;
  /** Create a single GC Departure */
  createGCDeparture: CreateGcDepartureResult;
  /** Get a JWT token for logging a customer into the help center. */
  createHelpCenterLoginToken?: Maybe<ExternalToken>;
  /** Get a JWT token for authenticating a customer with the help center messaging platform. */
  createHelpCenterMessagingToken?: Maybe<ExternalToken>;
  /** Create a new insurance extension */
  createInsuranceExtension: CreateInsuranceExtensionResult;
  /** Create a new insurance plan for a given year */
  createInsurancePlan: CreateInsurancePlanResult;
  /** Create a new insurance plan addon for the given insurance plan */
  createInsurancePlanAddon: CreateInsurancePlanAddonResult;
  /** Create a new note targeting any other entity */
  createNote: CreateNoteResult;
  /** Create a new promo offer */
  createPromo: CreatePromoResult;
  /** Create a review of a trip */
  createReview: CreateReviewResult;
  createRole: CreateRoleResult;
  /** Create a new service recovery promo */
  createServiceRecoveryPromo: CreateServiceRecoveryPromoResult;
  /** Creates a support ticket */
  createSupportTicket: CreateSupportTicketResult;
  createTag: CreateTagResult;
  /** Create a new tour for a given year */
  createTour: CreateTourResult;
  /** Create a Tour Excursion for a given base Tour */
  createTourExcursion: CreateTourExcursionResult;
  /** Create a tour excursion for a given base tour */
  createTourExtension: CreateTourExtensionResult;
  createTourExtensionRoom: CreateTourExtensionRoomResult;
  createTourRoom: CreateTourRoomResult;
  /** Creates a TripChecklistItemTemplate */
  createTripChecklistItemTemplate: CreateTripChecklistItemTemplateResult;
  /** Create a voucher that originates from a booking */
  createVoucherFromBooking: CreateVoucherFromBookingResult;
  /** Create a voucher that originates from a different system and EF product outside WOJO */
  createVoucherFromExternal: CreateVoucherFromExternalResult;
  /** Create a Wishlist Item for a Customer and Tour Code */
  createWishlistItem: CreateWishlistItemResult;
  /** Delete a category */
  deleteCategory: DeleteCategoryResult;
  /** Removes a GC Departure Prospect */
  deleteGCDepartureProspect: DeleteGcDepartureProspectResult;
  deleteReviewImage: DeleteReviewImageResult;
  /** Delete a tag */
  deleteTag: DeleteTagResult;
  /** Removes a Wishlist Item from a Customer's wishlist */
  deleteWishlistItem: DeleteWishlistItemResult;
  /** Forks one or more trips from a Booking onto a new Booking. */
  forkBooking: ForkBookingResult;
  /** Increases a non-expired, non-voided voucher by a dollar amount */
  increaseVoucher: IncreaseVoucherResult;
  /** Makes a one time payment on an active booking. Will cause any payment plans on the booking to recalculate. */
  issueARefund: IssueARefundResult;
  /** Makes a one time payment on an active booking. Will cause any payment plans on the booking to recalculate. */
  makeAPayment: MakeAPaymentResult;
  /** Match trips in the same room */
  matchRoommates: MatchRoommatesResult;
  /** Merges a source booking into a target booking. */
  mergeBooking: MergeBookingResult;
  /** Merge two customers */
  mergeCustomer: MergeCustomerResult;
  /** Converts a quote into booking given payment is fulfilled in respect to the payment plan. */
  payAndBook: PayAndBookResult;
  /** Publishes draft booking */
  publishDraftBooking: PublishDraftBookingResult;
  /** Publishes draft quote */
  publishDraftQuote: PublishDraftQuoteResult;
  /** Publish a draft term */
  publishDraftTerm: PublishDraftTermResult;
  /**
   * Reconcile the status of a card or ach payment on a booking.
   * @deprecated Use reconcilePayment instead
   */
  reconcileCardOrAchPayment: ReconcileCardOrAchPaymentResult;
  /** Reconcile a pending check payment on a booking. */
  reconcileCheckPayment: ReconcileCheckPaymentResult;
  /** Reconcile the status of a card or ach payment on a booking. */
  reconcilePayment: ReconcilePaymentResult;
  /** Create a new note targeting any other entity */
  reconcilePayments: ReconcilePaymentsResult;
  /** Reduces a non-expired, non-voided voucher by a dollar amount */
  reduceVoucher: ReduceVoucherResult;
  /** Refunds a refundable, active voucher that has a remaining balance */
  refundVoucher: RefundVoucherResult;
  /** Add promos to a campaign */
  removeCampaignPromos: RemoveCampaignPromosResult;
  /** Cancels one or more adjustments on a draft order for one or more trips. */
  removeDraftOrderAdjustments: RemoveDraftOrderAdjustmentsResult;
  /** Removes airport transfer from draft order for specified trips. */
  removeDraftOrderAirportTransfer: RemoveDraftOrderAirportTransferResult;
  /** Removes excursion on draft order for specified trips. */
  removeDraftOrderExcursion: RemoveDraftOrderExcursionResult;
  /** Removes all excursions for either the main tour or the extension on the specified trips. */
  removeDraftOrderExcursions: RemoveDraftOrderExcursionsResult;
  /** Removes extension on draft order for specified trips. */
  removeDraftOrderExtension: RemoveDraftOrderExtensionResult;
  /** Removes one or more fees from a draft order for one or more trips. */
  removeDraftOrderFees: RemoveDraftOrderFeesResult;
  /** Removes insurance from one or more trips on a draft order. */
  removeDraftOrderInsurance: RemoveDraftOrderInsuranceResult;
  /** Removes insurance addon for one or more trips on a draft order. */
  removeDraftOrderInsuranceAddon: RemoveDraftOrderInsuranceAddonResult;
  /** Removes the insurance extension for one or more trips on a draft order. */
  removeDraftOrderInsuranceExtension: RemoveDraftOrderInsuranceExtensionResult;
  /** Removes post-stay on draft order for specified trips. */
  removeDraftOrderPostStay: RemoveDraftOrderPostStayResult;
  /** Removes pre-stay on draft order for specified trips. */
  removeDraftOrderPreStay: RemoveDraftOrderPreStayResult;
  /** Removes the private group fee from a draft order for one or more trips. */
  removeDraftOrderPrivateGroup: RemoveDraftOrderPrivateGroupResult;
  /** Removes a promo from one or more trips on a draft order. */
  removeDraftOrderPromo: RemoveDraftOrderPromoResult;
  /** Remove one or more trips from a Draft Quote */
  removeDraftQuoteTrips: RemoveDraftQuoteTripsResult;
  /** Removes the role for employees */
  removeEmployeesFromRole: RemoveEmployeesFromRoleResult;
  /** Remove the private group from a tour */
  removePrivateGroup: RemovePrivateGroupResult;
  /** Remove promo codes */
  removePromoCodes: RemovePromoCodesResult;
  /** Remove customers from a promo */
  removePromoCustomers: RemovePromoCustomersResult;
  /** Remove gc departure from a promo */
  removePromoGcDepartures: RemovePromoGcDeparturesResult;
  /** Remove targets from a promo */
  removePromoTargets: RemovePromoTargetsResult;
  /** Save an evaluation */
  saveEvaluation: SaveEvaluationResult;
  /** Generate a magic link, and send to a customer or tour director */
  sendMagicLink: SendMagicLinkResult;
  /** Sets customer on draft quote */
  setDraftQuoteCustomer: SetDraftQuoteCustomerResult;
  /** Set review as featured */
  setReviewFeatured: Review;
  /** Signin a customer from a magic link */
  signinCustomerWithMagicLink: SigninCustomerWithMagicLinkResult;
  /** Signin a customer from an email and password */
  signinCustomerWithPassword: SigninCustomerWithPasswordResult;
  /** Signin a tour director from a magic link */
  signinTourDirectorWithMagicLink: SigninTourDirectorWithMagicLinkResult;
  /** Signout the current user */
  signout: SignoutResult;
  /** Create a new customer from an email */
  signupCustomer: SignupCustomerResult;
  /** Start a Quote by creating a DraftOrder for a Quote that does not yet exist. Used to start the Quoting process. While this mutation creates a DraftOrder, the Quote is not saved and cannot be queried until the Draft has been published. By default, creates only a single Trip (The primary Trip). */
  startQuote: StartQuoteResult;
  /** Sets the booking to PENDING_REBOOKING status. */
  startRebooking: StartRebookingResult;
  /** Start a Quote by creating a DraftOrder for a Quote that does not yet exist. Used to start the Quoting process. While this mutation creates a DraftOrder, the Quote is not saved and cannot be queried until the Draft has been published. By default, creates only a single Trip (The primary Trip). */
  startRebookingQuote: StartRebookingQuoteResult;
  /** Submit an evaluation */
  submitEvaluation: SubmitEvaluationResult;
  /** Transfer the balance from one voucher to another */
  transferVoucherExternal: TransferVoucherExternalResult;
  /** Transfer the balance from one voucher to another */
  transferVoucherInternal: TransferVoucherInternalResult;
  /** Unchecks a trip checklist item */
  uncheckTripChecklistItem: UncheckTripChecklistItemResult;
  /** Unmatch trips from a room */
  unmatchRoommates: UnmatchRoommatesResult;
  /** Updates an adjustment. */
  updateAdjustment: UpdateAdjustmentResult;
  /** Updates the status of an adjustment. */
  updateAdjustmentStatus: UpdateAdjustmentStatusResult;
  /** Updates the current business's settings */
  updateBusinessSettings: UpdateBusinessSettingsResult;
  /** Update the details of a campaign */
  updateCampaign: UpdateCampaignResult;
  /** Publish or Unpublish a campaign. */
  updateCampaignStatus: UpdateCampaignStatusResult;
  /** Update the basic details of a category */
  updateCategory: UpdateCategoryResult;
  /** Updates a customer address */
  updateCustomerAddress: UpdateCustomerAddressResult;
  /** Update a customer's email used for logging in */
  updateCustomerEmail: UpdateCustomerEmailResult;
  /** Update a customer's Global Rewards ID (currently Phoenix CustomerNumber) */
  updateCustomerGlobalRewardsId: Customer;
  /** Update a customer's preferred name */
  updateCustomerName: UpdateCustomerNameResult;
  /** Change a customer's password */
  updateCustomerPassword: UpdateCustomerPasswordResult;
  /** Updates a customer phone number. */
  updateCustomerPhoneNumber: UpdateCustomerPhoneNumberResult;
  /** Update customer bio */
  updateCustomerProfileBio: Customer;
  /** Update customer's profile image */
  updateCustomerProfileImage: Customer;
  /** Update customer privacy */
  updateCustomerProfilePrivacy: Customer;
  /** Update customer's social media */
  updateCustomerProfileSocialMedia: Customer;
  updateDeparture: UpdateDepartureResult;
  updateDepartureExcursion: UpdateDepartureExcursionResult;
  updateDepartureExtension: UpdateDepartureExtensionResult;
  updateDepartureExtensionExcursion: UpdateDepartureExtensionExcursionResult;
  updateDepartureExtensionRoom: UpdateDepartureExtensionRoomResult;
  updateDepartureItemStatus: UpdateDepartureItemStatusResult;
  updateDepartureRoom: UpdateDepartureRoomResult;
  /** Updated the payment schedule of a draft booking */
  updateDraftBookingPaymentSchedule: UpdateDraftBookingPaymentScheduleResult;
  /** Updates the payment method for the active payment schedule of a draft booking. */
  updateDraftBookingPaymentSchedulePaymentMethod: UpdateDraftBookingPaymentSchedulePaymentMethodResult;
  /** Updates line item statuses on a draft booking. Can be one of CONFIRMED, REQUESTED, or WAITLISTED. Otherwise, please use the respective mutations for the rest of the statuses. */
  updateDraftBookingTripLineItemStatuses: UpdateDraftBookingTripLineItemStatusesResult;
  /** Updates trip statuses on a draft booking. Can be one of CONFIRMED, REQUESTED, or WAITLISTED. Otherwise, please use the respective mutations for the rest of the statuses. */
  updateDraftBookingTripStatuses: UpdateDraftBookingTripStatusesResult;
  /** Sets Address information on draft order. */
  updateDraftOrderAddress: UpdateDraftOrderAddressResult;
  /** Overrides the price of one or more adjustments from a draft order for one or more trips. */
  updateDraftOrderAdjustmentPrices: UpdateDraftOrderAdjustmentPricesResult;
  /** Update an orders airport transfer details for when a flight is LO1 and customer has purchased a transfer */
  updateDraftOrderAirportTransfer: UpdateDraftOrderAirportTransferResult;
  /** Update the emergency contacts for a traveler on a trip. */
  updateDraftOrderEmergencyContacts: UpdateDraftOrderEmergencyContactsResult;
  /** Updates extension rooms on a draft order. */
  updateDraftOrderExtensionRooms: UpdateDraftOrderExtensionRoomsResult;
  /** Overrides the price of one or more fees from a draft order for one or more trips. */
  updateDraftOrderFeePrices: UpdateDraftOrderFeePricesResult;
  /** Sets the final installment deadline on a draft order. */
  updateDraftOrderFinalInstallmentDeadline: UpdateDraftOrderFinalInstallmentDeadlineResult;
  /** Sets the final payment deadline on a draft order. */
  updateDraftOrderFinalPaymentDeadline: UpdateDraftOrderFinalPaymentDeadlineResult;
  /** Updates flight packages on a draft order. */
  updateDraftOrderFlightPackages: UpdateDraftOrderFlightPackagesResult;
  /** Updates insurance on draft order for specified trips. */
  updateDraftOrderInsurance: UpdateDraftOrderInsuranceResult;
  /** Overrides the price of one or more promos from a draft order for one or more trips. */
  updateDraftOrderPromoPrices: UpdateDraftOrderPromoPricesResult;
  /** Sets the requested roommates for a trip on a draft order. */
  updateDraftOrderRequestedRoommates: UpdateDraftOrderRequestedRoommatesResult;
  /** Sets the requested travel companions for a trip on a draft order. */
  updateDraftOrderRequestedTravelCompanions: UpdateDraftOrderRequestedTravelCompanionsResult;
  /** Updates rooms on a draft order */
  updateDraftOrderRooms: UpdateDraftOrderRoomsResult;
  /** Update the health notes for a traveler on a trip */
  updateDraftOrderTravelerNotes: UpdateDraftOrderTravelerNotesResult;
  /** Sets TSA information for a trip on a draft order. */
  updateDraftOrderTsaInformation: UpdateDraftOrderTsaInformationResult;
  /** Update a draft term */
  updateDraftTerm: UpdateDraftTermResult;
  /** Update an extended stay's base price */
  updateExtendedStay: UpdateExtendedStayResult;
  /** Updates an Extended Stay status */
  updateExtendedStayStatus: UpdateExtendedStayStatusResult;
  /** Update the base price of a fee. */
  updateFee: UpdateFeeResult;
  /** Updates a Fee status */
  updateFeeStatus: UpdateFeeStatusResult;
  /** Update a flight package details and base price */
  updateFlightPackage: UpdateFlightPackageResult;
  /** Updates a Flight Package status */
  updateFlightPackageStatus: UpdateFlightPackageStatusResult;
  updateGCDeparture: UpdateGcDepartureResult;
  /** Update a GC Departure Prospect  */
  updateGCDepartureProspect: UpdateGcDepartureProspectResult;
  updateGCDepartureStipendDetail: UpdateGcDepartureStipendDetailResult;
  /** Update insurance extension */
  updateInsuranceExtension: UpdateInsuranceExtensionResult;
  /** Publish or unpublish an insurance extension. */
  updateInsuranceExtensionStatus: UpdateInsuranceExtensionStatusResult;
  /** Update an existing insurance plan */
  updateInsurancePlan: UpdateInsurancePlanResult;
  /** Update an insurance plan addon */
  updateInsurancePlanAddon: UpdateInsurancePlanAddonResult;
  /** Publish or Unpublish a insurance plan. */
  updateInsurancePlanAddonStatus: UpdateInsurancePlanAddonStatusResult;
  /** Publish or Unpublish a insurance plan. */
  updateInsurancePlanStatus: UpdateInsurancePlanStatusResult;
  /** Update an existing note */
  updateNote: UpdateNoteResult;
  /** Update the base price of a private group. */
  updatePrivateGroup: UpdatePrivateGroupResult;
  /** Updates a tour's private group status */
  updatePrivateGroupStatus: UpdatePrivateGroupStatusResult;
  /** Update the details of a promo */
  updatePromo: UpdatePromoResult;
  /** Publish or Unpublish a promo. */
  updatePromoStatus: UpdatePromoStatusResult;
  /** Update the targets of a promo. Passing empty array will clear the targets. */
  updatePromoTargets: UpdatePromoTargetsResult;
  /** Updates a quote expiration date */
  updateQuoteExpiry: UpdateQuoteExpiryResult;
  /** Updates basic fields of an existing review */
  updateReview: UpdateReviewResult;
  /** Updates a review reply or creates one if it doesn't exist. If input is null, it clears the response. */
  updateReviewDetailResponse: UpdateReviewDetailResponseResult;
  /** Updates the review detail status */
  updateReviewDetailStatus: UpdateReviewDetailStatusResult;
  /** Updates the review images status */
  updateReviewImagesStatus: UpdateReviewImagesStatusResult;
  updateRole: UpdateRoleResult;
  /** Update the details of a service recovery promo. */
  updateServiceRecoveryPromo: UpdateServiceRecoveryPromoResult;
  /** Publish or Unpublish a service recovery promo. */
  updateServiceRecoveryPromoStatus: UpdateServiceRecoveryPromoStatusResult;
  /** Update the basic details of a tag */
  updateTag: UpdateTagResult;
  /** Update a tour by id. */
  updateTour: UpdateTourResult;
  /** Update profile info of a tour director such as bio and communication preferences */
  updateTourDirectorProfile: TourDirector;
  /** Updates a Tour Excursion basic details */
  updateTourExcursion: UpdateTourExcursionResult;
  /** Publish or Unpublish a tour excursion. */
  updateTourExcursionStatus: UpdateTourExcursionStatusResult;
  /** Updates details of an extension */
  updateTourExtension: UpdateTourExtensionResult;
  /** Updates details of a tour extension room. */
  updateTourExtensionRoom: UpdateTourExtensionRoomResult;
  /** Publish or Unpublish a tour extension room. */
  updateTourExtensionRoomStatus: UpdateTourExtensionRoomStatusResult;
  /** Publish or Unpublish a tour extension. */
  updateTourExtensionStatus: UpdateTourExtensionStatusResult;
  /** Update prices for any part of a tour including the tour itself */
  updateTourPricing: UpdateTourPricingResult;
  /** Updates details of a tour room. */
  updateTourRoom: UpdateTourRoomResult;
  /** Publish or Unpublish a tour. */
  updateTourRoomStatus: UpdateTourRoomStatusResult;
  /** Publish or Unpublish a tour. */
  updateTourStatus: UpdateTourStatusResult;
  /** Updates a TripChecklistItemTemplate */
  updateTripChecklistItemTemplate: UpdateTripChecklistItemTemplateResult;
  /** Updates the detail of a refund transaction on the specified Voucher, e.g. to add missing check detail */
  updateVoucherRefundDetail: UpdateVoucherRefundDetailResult;
  /** Void a transfer payment on a booking. */
  voidBookingTransferPayment: VoidBookingTransferPaymentResult;
  /** Voids a non-expired, non-voided voucher that has a balance remaining */
  voidVoucher: VoidVoucherResult;
};


export type MutationAcceptGcDepartureTermsArgs = {
  _id: Scalars['ObjectId'];
  termId: Scalars['ObjectId'];
};


export type MutationAcceptTripTermsArgs = {
  bookingId: Scalars['ObjectId'];
  termId: Scalars['ObjectId'];
  tripId: Scalars['ObjectId'];
};


export type MutationAddCampaignPromosArgs = {
  _id: Scalars['ObjectId'];
  input: AddCampaignPromosInput;
};


export type MutationAddDraftBookingPaymentScheduleArgs = {
  _id: Scalars['ObjectId'];
  paymentSchedule: PaymentScheduleInput;
};


export type MutationAddDraftOrderAdjustmentArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderAdjustmentInput>;
  lineItemCode: Scalars['LineItemCode'];
};


export type MutationAddDraftOrderCampaignArgs = {
  _id: Scalars['ObjectId'];
  campaignCode: Scalars['String'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationAddDraftOrderExcursionsArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderExcursionInput>;
};


export type MutationAddDraftOrderExtensionArgs = {
  _id: Scalars['ObjectId'];
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationAddDraftOrderFeeArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderFeeInput>;
  lineItemCode: Scalars['LineItemCode'];
};


export type MutationAddDraftOrderInsuranceArgs = {
  _id: Scalars['ObjectId'];
  addOnLineItemCodes?: InputMaybe<Array<Scalars['LineItemCode']>>;
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationAddDraftOrderInsuranceAddonArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderInsuranceAddonInput>;
};


export type MutationAddDraftOrderInsuranceExtensionArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderInsuranceExtensionInput>;
};


export type MutationAddDraftOrderPostStayArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderExtendedStayInput>;
  lineItemCode: Scalars['LineItemCode'];
};


export type MutationAddDraftOrderPreStayArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<AddDraftOrderExtendedStayInput>;
  lineItemCode: Scalars['LineItemCode'];
};


export type MutationAddDraftOrderPrivateGroupArgs = {
  _id: Scalars['ObjectId'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationAddDraftOrderPromoArgs = {
  _id: Scalars['ObjectId'];
  promoCode: Scalars['String'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationAddDraftQuoteTripArgs = {
  input: AddDraftQuoteTripInput;
};


export type MutationAddEmployeesToRoleArgs = {
  _id: Scalars['ObjectId'];
  input: AddEmployeesToRoleInput;
};


export type MutationAddPrivateGroupArgs = {
  _id: Scalars['ObjectId'];
  input: AddPrivateGroupInput;
};


export type MutationAddPromoCodesArgs = {
  _id: Scalars['ObjectId'];
  input: AddPromoCodesInput;
};


export type MutationAddPromoCustomersArgs = {
  _id: Scalars['ObjectId'];
  input: AddPromoCustomersInput;
};


export type MutationAddPromoGcDeparturesArgs = {
  _id: Scalars['ObjectId'];
  input: AddPromoGcDepartureInput;
};


export type MutationAddPromoTargetsArgs = {
  _id: Scalars['ObjectId'];
  input: AddPromoTargetsInput;
};


export type MutationAddTourExcursionArgs = {
  _id: Scalars['ObjectId'];
  tourExcursionId: Scalars['ObjectId'];
};


export type MutationAddTourExtensionExcursionArgs = {
  _id: Scalars['ObjectId'];
  tourExcursionId: Scalars['ObjectId'];
};


export type MutationAdminCreateMagicLinkArgs = {
  input: AdminCreateMagicLinkInput;
};


export type MutationApplyGcDeparturePrivateGroupFeeArgs = {
  _id: Scalars['ObjectId'];
  useEstimatedGroupSize?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBulkCreateDepartureExcursionsArgs = {
  input: Array<BulkCreateDepartureExcursionInput>;
};


export type MutationBulkCreateDepartureExtensionExcursionsArgs = {
  input: Array<BulkCreateDepartureExtensionExcursionInput>;
};


export type MutationBulkCreateDepartureExtensionRoomsArgs = {
  input: Array<BulkCreateDepartureExtensionRoomInput>;
};


export type MutationBulkCreateDepartureExtensionsArgs = {
  input: Array<BulkCreateDepartureExtensionInput>;
};


export type MutationBulkCreateDepartureRoomsArgs = {
  input: Array<BulkCreateDepartureRoomInput>;
};


export type MutationBulkCreateDeparturesArgs = {
  input: Array<BulkCreateDepartureInput>;
};


export type MutationBulkCreateFlightPackagesArgs = {
  input: BulkCreateFlightPackagesInput;
};


export type MutationBulkCreateGcDepartureProspectsArgs = {
  groupCoordinatorCustomerId: Scalars['ObjectId'];
  input: Array<BulkCreateGcDepartureProspectsInput>;
};


export type MutationBulkCreatePrivateGroupsArgs = {
  input: Array<BulkCreatePrivateGroupsInput>;
};


export type MutationBulkCreatePromoTargetsArgs = {
  input: Array<BulkCreatePromoTargetInput>;
};


export type MutationBulkCreatePromosArgs = {
  input: Array<CreatePromoInput>;
};


export type MutationBulkUpdateDepartureExcursionsArgs = {
  input: Array<BulkUpdateDepartureExcursionInput>;
};


export type MutationBulkUpdateDepartureExtensionExcursionsArgs = {
  input: Array<BulkUpdateDepartureExtensionExcursionInput>;
};


export type MutationBulkUpdateDepartureExtensionRoomsArgs = {
  input: Array<BulkUpdateDepartureExtensionRoomInput>;
};


export type MutationBulkUpdateDepartureExtensionsArgs = {
  input: Array<BulkUpdateDepartureExtensionInput>;
};


export type MutationBulkUpdateDepartureOwnersArgs = {
  input: Array<BulkUpdateDepartureOwnerInput>;
};


export type MutationBulkUpdateDepartureRoomsArgs = {
  input: Array<BulkUpdateDepartureRoomInput>;
};


export type MutationBulkUpdateDeparturesArgs = {
  input: Array<BulkUpdateDepartureInput>;
};


export type MutationBulkUpdateExtendedStayPriceRulesArgs = {
  input: Array<BulkUpdateExtendedStayPriceRulesInput>;
};


export type MutationBulkUpdateFeePriceRulesArgs = {
  input: Array<BulkUpdateFeePriceRulesInput>;
};


export type MutationBulkUpdateFlightPackagePriceRulesArgs = {
  input: Array<BulkUpdateFlightPackagePriceRulesInput>;
};


export type MutationBulkUpdateInsurancePlanAddonPriceRulesArgs = {
  input: Array<BulkUpdateInsurancePlanAddonPriceRulesInput>;
};


export type MutationBulkUpdateInsurancePlanPriceRulesArgs = {
  input: Array<BulkUpdateInsurancePlanPriceRulesInput>;
};


export type MutationBulkUpdatePrivateGroupPriceRulesArgs = {
  input: Array<BulkUpdatePrivateGroupPriceRulesInput>;
};


export type MutationBulkUpdatePrivateGroupsArgs = {
  input: Array<BulkUpdatePrivateGroupsInput>;
};


export type MutationBulkUpdatePromosArgs = {
  input: Array<BulkUpdatePromoInput>;
};


export type MutationBulkUpdateTourExcursionPriceRulesArgs = {
  input: Array<BulkUpdateTourExcursionPriceRulesInput>;
};


export type MutationBulkUpdateTourExcursionsArgs = {
  input: Array<BulkUpdateTourExcursionInput>;
};


export type MutationBulkUpdateTourExtensionPriceRulesArgs = {
  input: Array<BulkUpdateTourExtensionPriceRulesInput>;
};


export type MutationBulkUpdateTourExtensionRoomPriceRulesArgs = {
  input: Array<BulkUpdateTourExtensionRoomPriceRulesInput>;
};


export type MutationBulkUpdateTourExtensionRoomsArgs = {
  input: Array<BulkUpdateTourExtensionRoomInput>;
};


export type MutationBulkUpdateTourExtensionsArgs = {
  input: Array<BulkUpdateTourExtensionInput>;
};


export type MutationBulkUpdateTourPriceRulesArgs = {
  input: Array<BulkUpdateTourPriceRulesInput>;
};


export type MutationBulkUpdateTourRoomPriceRulesArgs = {
  input: Array<BulkUpdateTourRoomPriceRulesInput>;
};


export type MutationBulkUpdateTourRoomsArgs = {
  input: Array<BulkUpdateTourRoomInput>;
};


export type MutationBulkUpdateToursArgs = {
  input: Array<BulkUpdateTourInput>;
};


export type MutationCalculatePaymentScheduleArgs = {
  draftOrderId: Scalars['ObjectId'];
  input: CalculatePaymentScheduleInput;
};


export type MutationCancelDraftBookingArgs = {
  input: CancelDraftBookingInput;
};


export type MutationCancelDraftBookingPaymentScheduleArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationCancelDraftBookingTripsArgs = {
  input: CancelDraftBookingTripsInput;
};


export type MutationCancelGcDepartureArgs = {
  input: CancelGcDepartureInput;
};


export type MutationChangeVoucherExpirationArgs = {
  input: ChangeVoucherExpirationInput;
};


export type MutationCheckTripChecklistItemArgs = {
  checklistItemTemplateId: Scalars['ObjectId'];
  tripId: Scalars['ObjectId'];
};


export type MutationConfirmGcDepartureArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationCreateAdjustmentArgs = {
  input: CreateAdjustmentInput;
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateChatTokenArgs = {
  businessCode: BusinessCode;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateDepartureExcursionArgs = {
  _id: Scalars['ObjectId'];
  input: CreateDepartureExcursionInput;
};


export type MutationCreateDepartureExtensionArgs = {
  _id: Scalars['ObjectId'];
  input: CreateDepartureExtensionInput;
};


export type MutationCreateDepartureExtensionExcursionArgs = {
  _id: Scalars['ObjectId'];
  extensionId: Scalars['ObjectId'];
  input: CreateDepartureExtensionExcursionInput;
};


export type MutationCreateDepartureExtensionRoomArgs = {
  _id: Scalars['ObjectId'];
  extensionId: Scalars['ObjectId'];
  input: CreateDepartureExtensionRoomInput;
};


export type MutationCreateDepartureRoomArgs = {
  _id: Scalars['ObjectId'];
  input: CreateDepartureRoomInput;
};


export type MutationCreateDraftOrderArgs = {
  orderId: Scalars['ObjectId'];
};


export type MutationCreateDraftTermArgs = {
  input: CreateDraftTermInput;
};


export type MutationCreateExtendedStayArgs = {
  input: CreateExtendedStayInput;
};


export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};


export type MutationCreateFlightPackageArgs = {
  input: CreateFlightPackageInput;
};


export type MutationCreateGcDepartureArgs = {
  input: CreateGcDepartureInput;
};


export type MutationCreateInsuranceExtensionArgs = {
  _id: Scalars['ObjectId'];
  basePrice: Array<MoneyInput>;
};


export type MutationCreateInsurancePlanArgs = {
  input: CreateInsurancePlanInput;
};


export type MutationCreateInsurancePlanAddonArgs = {
  _id: Scalars['ObjectId'];
  input: CreateInsurancePlanAddonInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreatePromoArgs = {
  input: CreatePromoInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateServiceRecoveryPromoArgs = {
  input: CreateServiceRecoveryPromoInput;
};


export type MutationCreateSupportTicketArgs = {
  businessCode: BusinessCode;
  input: SupportTicketInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTourArgs = {
  input: CreateTourInput;
};


export type MutationCreateTourExcursionArgs = {
  input: CreateTourExcursionInput;
};


export type MutationCreateTourExtensionArgs = {
  input: CreateTourExtensionInput;
};


export type MutationCreateTourExtensionRoomArgs = {
  input: CreateTourExtensionRoomInput;
  tourExtensionId: Scalars['ObjectId'];
};


export type MutationCreateTourRoomArgs = {
  input: CreateTourRoomInput;
  tourId: Scalars['ObjectId'];
};


export type MutationCreateTripChecklistItemTemplateArgs = {
  input: CreateTripChecklistItemTemplateInput;
};


export type MutationCreateVoucherFromBookingArgs = {
  input: CreateVoucherFromBookingInput;
};


export type MutationCreateVoucherFromExternalArgs = {
  input: CreateVoucherFromExternalInput;
};


export type MutationCreateWishlistItemArgs = {
  input: CreateWishlistItemInput;
};


export type MutationDeleteCategoryArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationDeleteGcDepartureProspectArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationDeleteReviewImageArgs = {
  reviewId: Scalars['ObjectId'];
  reviewImageIds: Array<Scalars['ObjectId']>;
};


export type MutationDeleteTagArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationDeleteWishlistItemArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationForkBookingArgs = {
  input: ForkBookingInput;
};


export type MutationIncreaseVoucherArgs = {
  input: IncreaseVoucherInput;
};


export type MutationIssueARefundArgs = {
  bookingId: Scalars['ObjectId'];
  overrideOrderBalanceRestrictions?: InputMaybe<Scalars['Boolean']>;
  refundInput: RefundInput;
};


export type MutationMakeAPaymentArgs = {
  bookingId: Scalars['ObjectId'];
  payments: Array<PaymentInput>;
};


export type MutationMatchRoommatesArgs = {
  departureId: Scalars['ObjectId'];
  roomTourType: RoomTourType;
  tripIds: Array<Scalars['ObjectId']>;
};


export type MutationMergeBookingArgs = {
  input: MergeBookingInput;
};


export type MutationMergeCustomerArgs = {
  input: MergeCustomerInput;
};


export type MutationPayAndBookArgs = {
  input: PayAndBookInput;
  quoteId: Scalars['ObjectId'];
};


export type MutationPublishDraftBookingArgs = {
  _id: Scalars['ObjectId'];
  input?: InputMaybe<PublishDraftBookingInput>;
};


export type MutationPublishDraftQuoteArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationPublishDraftTermArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationReconcileCardOrAchPaymentArgs = {
  bookingId: Scalars['ObjectId'];
  settlementDate?: InputMaybe<Scalars['Date']>;
  status: PaymentTransactionStatus;
  transactionId: Scalars['ObjectId'];
};


export type MutationReconcileCheckPaymentArgs = {
  bookingId: Scalars['ObjectId'];
  checkPaymentMethodInput?: InputMaybe<CheckPaymentMethodInput>;
  checkTransactionId: Scalars['ObjectId'];
  status: PaymentTransactionStatus;
};


export type MutationReconcilePaymentArgs = {
  bookingId: Scalars['ObjectId'];
  settlementDate?: InputMaybe<Scalars['Date']>;
  status: PaymentTransactionStatus;
  transactionId: Scalars['ObjectId'];
};


export type MutationReconcilePaymentsArgs = {
  businessCode: BusinessCode;
  correlationId: Scalars['String'];
  currencyCode: CurrencyCode;
  sourceProcessorOrAcquirer: SourceProcessorOrAcquirer;
  transactions: Array<ReconcileTransactionInput>;
};


export type MutationReduceVoucherArgs = {
  input: ReduceVoucherInput;
};


export type MutationRefundVoucherArgs = {
  input: RefundVoucherInput;
};


export type MutationRemoveCampaignPromosArgs = {
  _id: Scalars['ObjectId'];
  input: RemoveCampaignPromosInput;
};


export type MutationRemoveDraftOrderAdjustmentsArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<RemoveDraftOrderAdjustmentInput>;
};


export type MutationRemoveDraftOrderAirportTransferArgs = {
  _id: Scalars['ObjectId'];
  input: Array<RemoveDraftOrderAirportTransferInput>;
};


export type MutationRemoveDraftOrderExcursionArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderExcursionsArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tourTypes: Array<ExcursionTourType>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderExtensionArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderFeesArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  inputs: Array<RemoveDraftOrderFeesInput>;
};


export type MutationRemoveDraftOrderInsuranceArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderInsuranceAddonArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderInsuranceExtensionArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderPostStayArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderPreStayArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderPrivateGroupArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftOrderPromoArgs = {
  _id: Scalars['ObjectId'];
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationRemoveDraftQuoteTripsArgs = {
  _id: Scalars['ObjectId'];
  tripIds: Array<Scalars['ObjectId']>;
};


export type MutationRemoveEmployeesFromRoleArgs = {
  _id: Scalars['ObjectId'];
  input: RemoveEmployeesFromRoleInput;
};


export type MutationRemovePrivateGroupArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationRemovePromoCodesArgs = {
  _id: Scalars['ObjectId'];
  input: RemovePromoCodesInput;
};


export type MutationRemovePromoCustomersArgs = {
  _id: Scalars['ObjectId'];
  input: RemovePromoCustomersInput;
};


export type MutationRemovePromoGcDeparturesArgs = {
  _id: Scalars['ObjectId'];
  input: RemovePromoGcDeparturesInput;
};


export type MutationRemovePromoTargetsArgs = {
  _id: Scalars['ObjectId'];
  input: RemovePromoTargetsInput;
};


export type MutationSaveEvaluationArgs = {
  _id: Scalars['ObjectId'];
  freeTextQuestions?: InputMaybe<Array<AnswerFreeTextQuestionInput>>;
  matrixQuestions?: InputMaybe<Array<AnswerMatrixQuestionInput>>;
  multipleChoiceQuestions?: InputMaybe<Array<AnswerMultipleChoiceQuestionInput>>;
};


export type MutationSendMagicLinkArgs = {
  input: SendMagicLinkInput;
};


export type MutationSetDraftQuoteCustomerArgs = {
  _id: Scalars['ObjectId'];
  existingCustomerInput?: InputMaybe<TripExistingCustomerInput>;
  newCustomerInput?: InputMaybe<TripNewCustomerInput>;
  tripId: Scalars['ObjectId'];
};


export type MutationSetReviewFeaturedArgs = {
  _id: Scalars['ObjectId'];
  isFeatured: Scalars['Boolean'];
};


export type MutationSigninCustomerWithMagicLinkArgs = {
  token: Scalars['String'];
};


export type MutationSigninCustomerWithPasswordArgs = {
  input: SigninWithPasswordInput;
};


export type MutationSigninTourDirectorWithMagicLinkArgs = {
  token: Scalars['String'];
};


export type MutationSignupCustomerArgs = {
  input: SignupCustomerInput;
  skipSessionCookie?: Scalars['Boolean'];
};


export type MutationStartQuoteArgs = {
  input: StartQuoteInput;
};


export type MutationStartRebookingArgs = {
  input: StartRebookingInput;
};


export type MutationStartRebookingQuoteArgs = {
  input: StartRebookingQuoteInput;
};


export type MutationSubmitEvaluationArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationTransferVoucherExternalArgs = {
  input: TransferVoucherExternalInput;
};


export type MutationTransferVoucherInternalArgs = {
  input: TransferVoucherInternalInput;
};


export type MutationUncheckTripChecklistItemArgs = {
  checklistItemTemplateId: Scalars['ObjectId'];
  tripId: Scalars['ObjectId'];
};


export type MutationUnmatchRoommatesArgs = {
  departureId: Scalars['ObjectId'];
  roomTourType: RoomTourType;
  tripIds: Array<Scalars['ObjectId']>;
};


export type MutationUpdateAdjustmentArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateAdjustmentInput;
};


export type MutationUpdateAdjustmentStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateAdjustmentStatusInput;
};


export type MutationUpdateBusinessSettingsArgs = {
  businessCode: BusinessCode;
  input: UpdateBusinessSettingsInput;
};


export type MutationUpdateCampaignArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateCampaignInput;
};


export type MutationUpdateCampaignStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateCampaignStatusInput;
};


export type MutationUpdateCategoryArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateCategoryInput;
};


export type MutationUpdateCustomerAddressArgs = {
  customerId: Scalars['ObjectId'];
  input: UpdateCustomerAddressInput;
};


export type MutationUpdateCustomerEmailArgs = {
  customerId: Scalars['ObjectId'];
  input: UpdateCustomerEmailInput;
};


export type MutationUpdateCustomerGlobalRewardsIdArgs = {
  _id: Scalars['ObjectId'];
  globalRewardsId: Scalars['String'];
};


export type MutationUpdateCustomerNameArgs = {
  customerId: Scalars['ObjectId'];
  input: UpdateCustomerNameInput;
};


export type MutationUpdateCustomerPasswordArgs = {
  input: UpdateCustomerPasswordInput;
};


export type MutationUpdateCustomerPhoneNumberArgs = {
  customerId: Scalars['ObjectId'];
  input: UpdateCustomerPhoneNumberInput;
};


export type MutationUpdateCustomerProfileBioArgs = {
  _id: Scalars['ObjectId'];
  bio: Scalars['String'];
};


export type MutationUpdateCustomerProfileImageArgs = {
  _id: Scalars['ObjectId'];
  profileImageAssetId: Scalars['ObjectId'];
};


export type MutationUpdateCustomerProfilePrivacyArgs = {
  _id: Scalars['ObjectId'];
  isPrivate: Scalars['Boolean'];
};


export type MutationUpdateCustomerProfileSocialMediaArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateCustomerProfileSocialMediaInput;
};


export type MutationUpdateDepartureArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateDepartureInput;
};


export type MutationUpdateDepartureExcursionArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateDepartureExcursionInput;
};


export type MutationUpdateDepartureExtensionArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateDepartureExtensionInput;
};


export type MutationUpdateDepartureExtensionExcursionArgs = {
  _id: Scalars['ObjectId'];
  extensionId: Scalars['ObjectId'];
  input: UpdateDepartureExtensionExcursionInput;
};


export type MutationUpdateDepartureExtensionRoomArgs = {
  _id: Scalars['ObjectId'];
  extensionId: Scalars['ObjectId'];
  input: UpdateDepartureExtensionRoomInput;
};


export type MutationUpdateDepartureItemStatusArgs = {
  departureId: Scalars['ObjectId'];
  input: Array<UpdateDepartureItemStatusInput>;
};


export type MutationUpdateDepartureRoomArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateDepartureRoomInput;
};


export type MutationUpdateDraftBookingPaymentScheduleArgs = {
  _id: Scalars['ObjectId'];
  frequency: PaymentScheduleFrequency;
  startDate: Scalars['Date'];
};


export type MutationUpdateDraftBookingPaymentSchedulePaymentMethodArgs = {
  _id: Scalars['ObjectId'];
  paymentSchedulePaymentMethod: PaymentSchedulePaymentMethodInput;
};


export type MutationUpdateDraftBookingTripLineItemStatusesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftBookingTripLineItemStatusesInput>;
};


export type MutationUpdateDraftBookingTripStatusesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftBookingTripStatusesInput>;
};


export type MutationUpdateDraftOrderAddressArgs = {
  _id: Scalars['ObjectId'];
  address: AddressInput;
  tripId: Scalars['ObjectId'];
};


export type MutationUpdateDraftOrderAdjustmentPricesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderAdjustmentPricesInput>;
};


export type MutationUpdateDraftOrderAirportTransferArgs = {
  _id: Scalars['ObjectId'];
  input: Array<UpdateDraftOrderAirportTransferInput>;
};


export type MutationUpdateDraftOrderEmergencyContactsArgs = {
  _id: Scalars['ObjectId'];
  emergencyContacts: Array<EmergencyContactInput>;
  tripId: Scalars['ObjectId'];
};


export type MutationUpdateDraftOrderExtensionRoomsArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderRoomInput>;
};


export type MutationUpdateDraftOrderFeePricesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderFeePricesInput>;
};


export type MutationUpdateDraftOrderFinalInstallmentDeadlineArgs = {
  _id: Scalars['ObjectId'];
  finalInstallmentDeadline: Scalars['Date'];
};


export type MutationUpdateDraftOrderFinalPaymentDeadlineArgs = {
  _id: Scalars['ObjectId'];
  finalPaymentDeadline: Scalars['Date'];
};


export type MutationUpdateDraftOrderFlightPackagesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderFlightPackageInput>;
};


export type MutationUpdateDraftOrderInsuranceArgs = {
  _id: Scalars['ObjectId'];
  addOnLineItemCodes?: InputMaybe<Array<Scalars['LineItemCode']>>;
  lineItemCode: Scalars['LineItemCode'];
  tripIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type MutationUpdateDraftOrderPromoPricesArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderPromoPricesInput>;
};


export type MutationUpdateDraftOrderRequestedRoommatesArgs = {
  _id: Scalars['ObjectId'];
  requestedRoommates: Array<Scalars['String']>;
  tripId: Scalars['ObjectId'];
};


export type MutationUpdateDraftOrderRequestedTravelCompanionsArgs = {
  _id: Scalars['ObjectId'];
  requestedTravelCompanions: Array<Scalars['String']>;
  tripId: Scalars['ObjectId'];
};


export type MutationUpdateDraftOrderRoomsArgs = {
  _id: Scalars['ObjectId'];
  inputs: Array<UpdateDraftOrderRoomInput>;
};


export type MutationUpdateDraftOrderTravelerNotesArgs = {
  _id: Scalars['ObjectId'];
  input: TravelerNotesInput;
  tripId: Scalars['ObjectId'];
};


export type MutationUpdateDraftOrderTsaInformationArgs = {
  _id: Scalars['ObjectId'];
  overrideExpirationDateCheck?: Scalars['Boolean'];
  tripId: Scalars['ObjectId'];
  tsaInformation: TsaInformationInput;
};


export type MutationUpdateDraftTermArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateDraftTermInput;
};


export type MutationUpdateExtendedStayArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateExtendedStayInput;
};


export type MutationUpdateExtendedStayStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateExtendedStayStatusInput;
};


export type MutationUpdateFeeArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateFeeInput;
};


export type MutationUpdateFeeStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateFeeStatusInput;
};


export type MutationUpdateFlightPackageArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateFlightPackageInput;
};


export type MutationUpdateFlightPackageStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateFlightPackageStatusInput;
};


export type MutationUpdateGcDepartureArgs = {
  input: UpdateGcDepartureInput;
};


export type MutationUpdateGcDepartureProspectArgs = {
  input: UpdateGcDepartureProspectInput;
};


export type MutationUpdateGcDepartureStipendDetailArgs = {
  input: UpdateGcDepartureStipendDetailInput;
};


export type MutationUpdateInsuranceExtensionArgs = {
  input: UpdateInsuranceExtensionInput;
  insurancePlanId: Scalars['ObjectId'];
};


export type MutationUpdateInsuranceExtensionStatusArgs = {
  input: UpdateInsuranceExtensionStatusInput;
  insurancePlanId: Scalars['ObjectId'];
};


export type MutationUpdateInsurancePlanArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateInsurancePlanInput;
};


export type MutationUpdateInsurancePlanAddonArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateInsurancePlanAddonInput;
  insurancePlanId: Scalars['ObjectId'];
};


export type MutationUpdateInsurancePlanAddonStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateInsurancePlanAddonStatusInput;
};


export type MutationUpdateInsurancePlanStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateInsurancePlanStatusInput;
};


export type MutationUpdateNoteArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateNoteInput;
};


export type MutationUpdatePrivateGroupArgs = {
  _id: Scalars['ObjectId'];
  input: UpdatePrivateGroupInput;
};


export type MutationUpdatePrivateGroupStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdatePrivateGroupStatusInput;
};


export type MutationUpdatePromoArgs = {
  _id: Scalars['ObjectId'];
  input: UpdatePromoInput;
};


export type MutationUpdatePromoStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdatePromoStatusInput;
};


export type MutationUpdatePromoTargetsArgs = {
  _id: Scalars['ObjectId'];
  input: UpdatePromoTargetsInput;
};


export type MutationUpdateQuoteExpiryArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateQuoteExpiryInput;
};


export type MutationUpdateReviewArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateReviewInput;
};


export type MutationUpdateReviewDetailResponseArgs = {
  input?: InputMaybe<UpdateReviewDetailResponseInput>;
  reviewId: Scalars['ObjectId'];
};


export type MutationUpdateReviewDetailStatusArgs = {
  reviewId: Scalars['ObjectId'];
  status: ReviewStatus;
};


export type MutationUpdateReviewImagesStatusArgs = {
  input: Array<UpdateReviewImagesStatusInput>;
  reviewId: Scalars['ObjectId'];
};


export type MutationUpdateRoleArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateRoleInput;
};


export type MutationUpdateServiceRecoveryPromoArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateServiceRecoveryPromoInput;
};


export type MutationUpdateServiceRecoveryPromoStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateServiceRecoveryPromoStatusInput;
};


export type MutationUpdateTagArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTagInput;
};


export type MutationUpdateTourArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourInput;
};


export type MutationUpdateTourDirectorProfileArgs = {
  _id: Scalars['ObjectId'];
  input: SetTourDirectorProfileInput;
};


export type MutationUpdateTourExcursionArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExcursionInput;
};


export type MutationUpdateTourExcursionStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExcursionStatusInput;
};


export type MutationUpdateTourExtensionArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExtensionInput;
};


export type MutationUpdateTourExtensionRoomArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExtensionRoomInput;
};


export type MutationUpdateTourExtensionRoomStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExtensionRoomStatusInput;
};


export type MutationUpdateTourExtensionStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourExtensionStatusInput;
};


export type MutationUpdateTourPricingArgs = {
  input: UpdateTourPricingInput;
};


export type MutationUpdateTourRoomArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourRoomInput;
};


export type MutationUpdateTourRoomStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourRoomStatusInput;
};


export type MutationUpdateTourStatusArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTourStatusInput;
};


export type MutationUpdateTripChecklistItemTemplateArgs = {
  _id: Scalars['ObjectId'];
  input: UpdateTripChecklistItemTemplateInput;
};


export type MutationUpdateVoucherRefundDetailArgs = {
  input: UpdateVoucherRefundDetailInput;
};


export type MutationVoidBookingTransferPaymentArgs = {
  bookingId: Scalars['ObjectId'];
  transferTransactionId: Scalars['ObjectId'];
};


export type MutationVoidVoucherArgs = {
  input: VoidVoucherInput;
};

/** The CurrentUser is not authorized to perform the requested action */
export type NotAuthorizedError = DomainError & {
  __typename?: 'NotAuthorizedError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** A required entity was not found during a mutation process */
export type NotFoundError = DomainError & {
  __typename?: 'NotFoundError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Notes attached to any other entity type */
export type Note = {
  __typename?: 'Note';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<Employee>;
  employeeId: Scalars['ObjectId'];
  targetId: Scalars['ObjectId'];
};

export type NotePaginatedResult = {
  __typename?: 'NotePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Note>;
};

export type OffsetPaginateInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** A pre-2018 legacy trip that does not contain as much information as a BookingTrip */
export type OldTrip = {
  __typename?: 'OldTrip';
  businessCode: Scalars['String'];
  customerId: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isValidForLoyalty: Scalars['Boolean'];
  legacyTripId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  tourCode: Scalars['String'];
  tourInternalName?: Maybe<Scalars['String']>;
};

/** An order */
export type Order = {
  _id: Scalars['ObjectId'];
  /** Sum of total successful transactions */
  amountPaid: Money;
  /** Grand total less the amount already paid */
  balance: Money;
  businessCode: BusinessCode;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency code of the order. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer who initiated the booking. */
  customerId?: Maybe<Scalars['ObjectId']>;
  departure?: Maybe<Departure>;
  /** The departure id of the order. */
  departureId: Scalars['ObjectId'];
  /** The extended date the final payment is due for the order. */
  extendedFinalPaymentDate?: Maybe<Scalars['Date']>;
  /** The date the final installment for app is due for the order. */
  finalInstallmentDate: Scalars['Date'];
  /** The date the final payment is due for the order. */
  finalPaymentDate: Scalars['Date'];
  gcDeparture?: Maybe<GcDeparture>;
  /** The id of the gc departure associated with the order. */
  gcDepartureId?: Maybe<Scalars['ObjectId']>;
  /** Sum of all line item prices */
  grandTotal: Money;
  /** Sum of all line item prices without discounts applied */
  grandTotalBeforeDiscount: Money;
  /** Sum of all line items excluding flights */
  grandTotalWithoutFlights: Money;
  /** Indicates if the final payment date has been extended. */
  isFinalPaymentDateExtended: Scalars['Boolean'];
  /** The latest date the final installment for app is due for the order. */
  latestFinalInstallmentDate: Scalars['Date'];
  /** The latest date the final payment is due for the order. */
  latestFinalPaymentDate: Scalars['Date'];
  opportunityId?: Maybe<Scalars['String']>;
  /** The order that this order was rebooked from. */
  rebookedFromOrder?: Maybe<Order>;
  /** The order id that this order was rebooked from. */
  rebookedFromOrderId?: Maybe<Scalars['ObjectId']>;
  /** The stage of the order. */
  stage: OrderStage;
  /** Sum of all discount line items */
  totalDiscount: Money;
  tourLineItemCode?: Maybe<Scalars['LineItemCode']>;
  /** The transactions applied to the order, sorted by ascending transaction date. */
  transactions: Array<PaymentTransaction>;
  /** The trips of the order. */
  trips: Array<Trip>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of the order. */
  versionId: Scalars['Int'];
};

export enum OrderLineItemHistoryAction {
  Added = 'ADDED',
  AddedFlights = 'ADDED_FLIGHTS',
  AddedLo1Flights = 'ADDED_LO1_FLIGHTS',
  AddedLo1Transfer = 'ADDED_LO1_TRANSFER',
  AmountChanged = 'AMOUNT_CHANGED',
  DateGatewayChange = 'DATE_GATEWAY_CHANGE',
  Lo1TransferChange = 'LO1_TRANSFER_CHANGE',
  Removed = 'REMOVED',
  RoommateChanged = 'ROOMMATE_CHANGED',
  RoomTypeChanged = 'ROOM_TYPE_CHANGED',
  StatusChanged = 'STATUS_CHANGED',
  SwitchedFlightsToLo1 = 'SWITCHED_FLIGHTS_TO_LO1',
  SwitchedLo1ToFlights = 'SWITCHED_LO1_TO_FLIGHTS'
}

export type OrderLineItemHistoryChange = {
  __typename?: 'OrderLineItemHistoryChange';
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ObjectId']>;
  displayName?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  lineItem: LineItem;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type OrderLineItemHistoryEntry = {
  __typename?: 'OrderLineItemHistoryEntry';
  /** The action that was taken on the line item */
  action: OrderLineItemHistoryAction;
  /** The changes that were made to the line item */
  changeSet: Array<OrderLineItemHistoryChange>;
  /** The customer id of the trip that the line item belongs to */
  customerIds: Array<Scalars['ObjectId']>;
  customers: Array<Customer>;
  /** The date the change was made */
  date: Scalars['DateTime'];
  /** The line item type that the history entry is for */
  lineItemType: TripComponentType;
  /** The user that made the change */
  modifiedBy?: Maybe<AuditIdentity>;
  orderId: Scalars['ObjectId'];
};

export type OrderPaginatedResult = {
  __typename?: 'OrderPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Order>;
};

/** Settings related to the order management. */
export type OrderSettings = {
  __typename?: 'OrderSettings';
  achCheck: PaymentMethodSettings;
  creditCard: PaymentMethodSettings;
  daysToMeetMinimumInstallments: Scalars['Int'];
  debitCard: PaymentMethodSettings;
  departurePriorityWindowDpd: Scalars['Int'];
  earliestPaymentScheduleStartDays: Scalars['Int'];
  /** Number of days prior to departure that the emergency contact information is no longer allowed to be submitted by the user */
  emergencyContactSelfServiceDeadlineDpd: Scalars['Int'];
  /** Number of days prior to departure that health notes are no longer allowed to be submitted by the user */
  healthNotesSelfServiceDeadlineDpd: Scalars['Int'];
  latestPaymentScheduleStartDays: Scalars['Int'];
  maximumPerTripPromotionAmount: Amount;
  /** Number of days prior to departure that passport information is no longer allowed to be submitted by the user */
  passportInformationSelfServiceDeadlineDpd: Scalars['Int'];
  physicalCheck: PaymentMethodSettings;
  riskFreePeriodSettings: RiskFreePeriodSettings;
  /** Number of days prior to departure that tsa identifiers is no longer allowed to be submitted by the user */
  tsaIdentifiersSelfServiceDeadlineDpd: Scalars['Int'];
};

export enum OrderStage {
  Booking = 'BOOKING',
  Quote = 'QUOTE'
}

export enum OrderTsaHistoryAction {
  Added = 'ADDED',
  Updated = 'UPDATED'
}

export type OrderTsaHistoryChange = {
  __typename?: 'OrderTSAHistoryChange';
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ObjectId']>;
  fieldName: Scalars['String'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  tsaInformation: TsaInformation;
};

export type OrderTsaHistoryEntry = {
  __typename?: 'OrderTSAHistoryEntry';
  /** The action that was taken on the tsa information */
  action: OrderTsaHistoryAction;
  /** The changes that were made to the tsa information */
  changeSet: Array<OrderTsaHistoryChange>;
  /** The customer id of the trip that the tsa information belongs to */
  customerIds: Array<Scalars['ObjectId']>;
  customers: Array<Customer>;
  /** The date the change was made */
  date: Scalars['DateTime'];
  /** The type of information that was changed, ID or TSA */
  infoType: OrderTsaInfoType;
  /** The user that made the change */
  modifiedBy?: Maybe<AuditIdentity>;
  orderId: Scalars['ObjectId'];
};

export enum OrderTsaInfoType {
  Id = 'ID',
  Tsa = 'TSA'
}

export type PaginateInput = {
  limit?: InputMaybe<Scalars['Int']>;
  next?: InputMaybe<Scalars['String']>;
  previous?: InputMaybe<Scalars['String']>;
};

export type PassportInformationChecklistItem = SystemChecklistItem & {
  __typename?: 'PassportInformationChecklistItem';
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

export type PayAndBookInput = {
  acceptWaitlist?: Scalars['Boolean'];
  /** Where this booking was made. */
  bookingMethod: BookingMethod;
  paymentSchedule?: InputMaybe<PaymentScheduleInput>;
  payments: Array<PaymentInput>;
  staffMinimumBalanceOverrideRequested?: InputMaybe<Scalars['Boolean']>;
};

export type PayAndBookResult = Booking | BusinessNotSpecifiedError | DepartureNotBookableError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidOrderError | InvalidPaymentError | NotAuthorizedError | NotFoundError | ValidationError;

export type PaymentBalanceByPaymentMethod = {
  __typename?: 'PaymentBalanceByPaymentMethod';
  amount: Money;
  customerId: Scalars['ObjectId'];
  paymentMethod: TransactionPaymentMethod;
  paymentMethodHash: Scalars['String'];
  savedPaymentMethodId?: Maybe<Scalars['ObjectId']>;
};

export type PaymentInput = {
  amount: Scalars['Float'];
  method: PaymentMethodInput;
};

export type PaymentInstallment = {
  __typename?: 'PaymentInstallment';
  _id: Scalars['ObjectId'];
  amount: Money;
  /** The date the payment is scheduled to be processed on. */
  scheduledDate: Scalars['Date'];
  status: PaymentInstallmentStatus;
};

export enum PaymentInstallmentStatus {
  /** The payment installment has been attempted. */
  Attempted = 'ATTEMPTED',
  /** The payment installment has been cancelled. */
  Cancelled = 'CANCELLED',
  /** The payment installment has been processed. */
  Processed = 'PROCESSED',
  /** The payment installment has been skipped. */
  Skipped = 'SKIPPED',
  /** The payment installment has not been processed. */
  Unprocessed = 'UNPROCESSED'
}

/** Payment method input. Only specify one of the payment methods. */
export type PaymentMethodInput = {
  /** Affirm payment method */
  affirm?: InputMaybe<AffirmPaymentMethodInput>;
  /** Physical check payment method */
  check?: InputMaybe<CheckPaymentMethodInput>;
  /** Saved payment method */
  savedPayment?: InputMaybe<SavedPaymentMethodInput>;
  /** Tokenized payment method. Provided by the tokenizer iframe */
  tokenized?: InputMaybe<TokenizedPaymentMethodInput>;
  /** Transfer funds from another booking */
  transfer?: InputMaybe<TransferPaymentMethodInput>;
  /** Voucher payment method */
  voucher?: InputMaybe<VoucherPaymentMethodInput>;
};

/** Payment method settings for the order. */
export type PaymentMethodSettings = {
  __typename?: 'PaymentMethodSettings';
  isAutopayAllowed: Scalars['Boolean'];
  isManualAllowed: Scalars['Boolean'];
};

/** A summary info for a single reconciled payment */
export type PaymentReconciliationTransaction = {
  __typename?: 'PaymentReconciliationTransaction';
  acquirerId?: Maybe<Scalars['String']>;
  amount: Money;
  bookingId?: Maybe<Scalars['ObjectId']>;
  businessCode: BusinessCode;
  processDate: Scalars['Date'];
  processorMerchantReferenceNumber?: Maybe<Scalars['String']>;
  reconciliationStatus: ReconciliationStatus;
  reconciliationStatusMessage?: Maybe<Scalars['String']>;
  returnReasonCode?: Maybe<Scalars['String']>;
  returnReasonString?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['Int']>;
  transactionType: ReconciliationTransactionType;
  uploadId: Scalars['ObjectId'];
};

export type PaymentReconciliationTransactionPaginatedResult = {
  __typename?: 'PaymentReconciliationTransactionPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<PaymentReconciliationTransaction>;
};

export enum PaymentReconciliationTransactionSortField {
  BookingId = 'bookingId',
  ReconciliationStatus = 'reconciliationStatus',
  TransactionId = 'transactionId'
}

/** A summary info for a single reconciled payment */
export type PaymentReconciliationUpload = {
  __typename?: 'PaymentReconciliationUpload';
  _id: Scalars['ObjectId'];
  businessCode: BusinessCode;
  correlationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  nbFailedTransactions: Scalars['Int'];
  nbSkippedTransactions: Scalars['Int'];
  nbSuccessfulTransactions: Scalars['Int'];
  nbUnprocessedTransactions: Scalars['Int'];
  sourceProcessorOrAcquirer: SourceProcessorOrAcquirer;
};

export type PaymentReconciliationUploadPaginatedResult = {
  __typename?: 'PaymentReconciliationUploadPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<PaymentReconciliationUpload>;
};

export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  _id: Scalars['ObjectId'];
  /** The plan's installments which are either in status UNPROCESSED or PROCESSED. Most customer or sales/service facing applications will want to use this. */
  activeInstallments: Array<PaymentInstallment>;
  /** The current payment plan balance for unprocessed installments. */
  balance?: Maybe<Money>;
  /** The frequency of the payment plan. */
  frequency: PaymentScheduleFrequency;
  /** The payment plan installments, regardless of status. If you are displaying installments to a customer or sales/service staff, consider using activeInstallments instead. */
  installments: Array<PaymentInstallment>;
  /** The maximum number of attempts to process the payment installment. */
  maxAttempts: Scalars['Int'];
  /** The plan's installments in status PROCESSED. */
  processedInstallments: Array<PaymentInstallment>;
  /** The saved payment method. */
  savedPaymentMethod?: Maybe<SavedPaymentMethod>;
  savedPaymentMethodId?: Maybe<Scalars['ObjectId']>;
  /** The date the payment plan starts. */
  startDate: Scalars['Date'];
  /** The status of the payment plan. */
  status: PaymentScheduleStatus;
  /** The payment plan total, from the first installment to the last. */
  total?: Maybe<Money>;
  /** The plan's installments in status UNPROCESSED. */
  unprocessedInstallments: Array<PaymentInstallment>;
};

export enum PaymentScheduleFrequency {
  /** The payment installment is processed biweekly. */
  Biweekly = 'BIWEEKLY',
  /** The payment installment is processed monthly. */
  Monthly = 'MONTHLY'
}

export type PaymentScheduleInput = {
  frequency: PaymentScheduleFrequency;
  method: PaymentSchedulePaymentMethodInput;
  startDate: Scalars['Date'];
};

export type PaymentSchedulePaymentMethodInput = {
  /** Saved payment method */
  savedPayment?: InputMaybe<SavedPaymentMethodInput>;
  /** Tokenized payment method. Provided by the tokenizer iframe */
  tokenized?: InputMaybe<TokenizedPaymentMethodInput>;
};

export enum PaymentScheduleStatus {
  /** The payment plan is active */
  Active = 'ACTIVE',
  /** The payment plan is cancelled and payment may have been refunded. */
  Cancelled = 'CANCELLED',
  /** The payment plan is completed and all payments have been made. */
  Completed = 'COMPLETED',
  /** The payment plan is pending */
  Pending = 'PENDING',
  PendingWithdrawal = 'PENDING_WITHDRAWAL',
  /** The payment plan is withdrawn */
  Withdrawn = 'WITHDRAWN'
}

export type PaymentTransaction = {
  _id: Scalars['ObjectId'];
  amount: Money;
  /** The customer of this transaction. */
  customerId: Scalars['ObjectId'];
  displayStatus: PaymentTransactionDisplayStatus;
  paymentMethod: TransactionPaymentMethod;
  paymentSysResponseCode?: Maybe<Scalars['String']>;
  paymentSysResponseMessage?: Maybe<Scalars['String']>;
  processorTransactionId?: Maybe<Scalars['Int']>;
  reconciledBy?: Maybe<Employee>;
  referenceNumber?: Maybe<Scalars['String']>;
  settlementDate?: Maybe<Scalars['Date']>;
  /** The status of this transaction. */
  status: PaymentTransactionStatus;
  transactionDate: Scalars['DateTime'];
};

/** The display status of a transaction */
export enum PaymentTransactionDisplayStatus {
  /** The transaction was succesfully processed. */
  Accepted = 'ACCEPTED',
  /** The transaction has not been processed yet. */
  Pending = 'PENDING',
  /** The transaction was rejected. */
  Rejected = 'REJECTED'
}

export type PaymentTransactionPaginatedResult = {
  __typename?: 'PaymentTransactionPaginatedResult';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  results: Array<PaymentTransaction>;
};

/** The status of a transaction */
export enum PaymentTransactionStatus {
  /** The transaction was attempted, but something went wrong during processing. */
  Attempted = 'ATTEMPTED',
  /**  The transactions was settled by the processor, the amount was successfully processed. */
  Confirmed = 'CONFIRMED',
  /** The transaction failed at the processor. See the fields: `paymentSysResponseCode` and `paymentSysReponseMessage` for details. */
  Failed = 'FAILED',
  /** The transaction is pending, yet to be processed. */
  Pending = 'PENDING',
  /** (ACH transactions only) The check was returned by the bank (due to insufficient funds). */
  Returned = 'RETURNED',
  /** The pending transaction was voided thus never processed by payment processor. */
  Voided = 'VOIDED'
}

export type Permission = {
  __typename?: 'Permission';
  description: Scalars['String'];
  id: Scalars['String'];
  key: PermissionKey;
  name: Scalars['String'];
};

export enum PermissionKey {
  /** Allows you to create a adjustment */
  AdjustmentCreate = 'ADJUSTMENT_CREATE',
  /** Allows you to manage adjustments */
  AdjustmentManageAll = 'ADJUSTMENT_MANAGE_ALL',
  /** Allows you to manage adjustment prices */
  AdjustmentManagePricing = 'ADJUSTMENT_MANAGE_PRICING',
  /** Allows you to read adjustments */
  AdjustmentReadAll = 'ADJUSTMENT_READ_ALL',
  /** Admins have full access to the business and pass all permissions checks for that business */
  Admin = 'ADMIN',
  /** Allows you to create a booking */
  BookingCreate = 'BOOKING_CREATE',
  /** Allows you to read bookings */
  BookingReadAll = 'BOOKING_READ_ALL',
  /** Allows you to update business settings */
  BusinessUpdateSettings = 'BUSINESS_UPDATE_SETTINGS',
  /** Allows you to create a campaign */
  CampaignCreate = 'CAMPAIGN_CREATE',
  /** Allows you to manage campaigns */
  CampaignManageAll = 'CAMPAIGN_MANAGE_ALL',
  /** Allows you to read campaigns */
  CampaignReadAll = 'CAMPAIGN_READ_ALL',
  /** Allows you to merge customers */
  CustomerManageMerge = 'CUSTOMER_MANAGE_MERGE',
  /** Allows you to read customer data */
  CustomerRead = 'CUSTOMER_READ',
  /** Allows you to create a departure */
  DepartureCreate = 'DEPARTURE_CREATE',
  /** Allows you to create a departure excursion */
  DepartureExcursionCreate = 'DEPARTURE_EXCURSION_CREATE',
  /** Allows you to manage departure excursions */
  DepartureExcursionManageAll = 'DEPARTURE_EXCURSION_MANAGE_ALL',
  /** Allows you to manage departure excursion prices */
  DepartureExcursionManagePricing = 'DEPARTURE_EXCURSION_MANAGE_PRICING',
  /** Allows you to create a departure extension */
  DepartureExtensionCreate = 'DEPARTURE_EXTENSION_CREATE',
  /** Allows you to manage departure extensions */
  DepartureExtensionManageAll = 'DEPARTURE_EXTENSION_MANAGE_ALL',
  /** Allows you to manage departure extension prices */
  DepartureExtensionManagePricing = 'DEPARTURE_EXTENSION_MANAGE_PRICING',
  /** Allows you to manage departures */
  DepartureManageAll = 'DEPARTURE_MANAGE_ALL',
  /** Allows you to change the status of a CLOSED departure */
  DepartureManageClosed = 'DEPARTURE_MANAGE_CLOSED',
  /** Allows you to change the departure owner */
  DepartureManageOwner = 'DEPARTURE_MANAGE_OWNER',
  /** Allows you to manage departure prices */
  DepartureManagePricing = 'DEPARTURE_MANAGE_PRICING',
  /** Allows you to view the departure TDI report */
  DepartureManageViewTdi = 'DEPARTURE_MANAGE_VIEW_TDI',
  /** Allows you to read departures */
  DepartureReadAll = 'DEPARTURE_READ_ALL',
  /** Allows you to create a departure room */
  DepartureRoomCreate = 'DEPARTURE_ROOM_CREATE',
  /** Allows you to manage departure rooms */
  DepartureRoomManageAll = 'DEPARTURE_ROOM_MANAGE_ALL',
  /** Allows you to manage departure room prices */
  DepartureRoomManagePricing = 'DEPARTURE_ROOM_MANAGE_PRICING',
  /** Allows you to create a new extended stay */
  ExtendedStayCreate = 'EXTENDED_STAY_CREATE',
  /** Allows you to create a fee */
  FeeCreate = 'FEE_CREATE',
  /** Allows you to manage fees */
  FeeManageAll = 'FEE_MANAGE_ALL',
  /** Allows you to manage fee prices */
  FeeManagePricing = 'FEE_MANAGE_PRICING',
  /** Allows you to read fees */
  FeeReadAll = 'FEE_READ_ALL',
  /** Allows you to create a flight package */
  FlightPackageCreate = 'FLIGHT_PACKAGE_CREATE',
  /** Allows you to manage flight packages */
  FlightPackageManageAll = 'FLIGHT_PACKAGE_MANAGE_ALL',
  /** Allows you to manage flight package prices */
  FlightPackageManagePricing = 'FLIGHT_PACKAGE_MANAGE_PRICING',
  /** Allows you to read flight packages */
  FlightPackageReadAll = 'FLIGHT_PACKAGE_READ_ALL',
  /** Allows you to book flights */
  FlightShoppingBook = 'FLIGHT_SHOPPING_BOOK',
  /** Allows you to search for flights */
  FlightShoppingSearch = 'FLIGHT_SHOPPING_SEARCH',
  /** Allows you to cancel a GC Departure */
  GcDepartureCancel = 'GC_DEPARTURE_CANCEL',
  /** Allows you to create a new GC Departure */
  GcDepartureCreate = 'GC_DEPARTURE_CREATE',
  /** Allows you to update a GC Departure */
  GcDepartureManage = 'GC_DEPARTURE_MANAGE',
  /** Allows you to create a insurance */
  InsuranceCreate = 'INSURANCE_CREATE',
  /** Allows you to manage insurances */
  InsuranceManageAll = 'INSURANCE_MANAGE_ALL',
  /** Allows you to manage insurance prices */
  InsuranceManagePricing = 'INSURANCE_MANAGE_PRICING',
  /** Allows you to read insurances */
  InsuranceReadAll = 'INSURANCE_READ_ALL',
  /** Allows you to create a draft order */
  OrderDraft = 'ORDER_DRAFT',
  /** Allows you to read draft orders */
  OrderDraftReadAll = 'ORDER_DRAFT_READ_ALL',
  /** Allows you to manage adjustments on orders */
  OrderManageAdjustments = 'ORDER_MANAGE_ADJUSTMENTS',
  /** Allows you to manage cancelling orders */
  OrderManageCancel = 'ORDER_MANAGE_CANCEL',
  /** Allows you to manage closed excursions on orders */
  OrderManageClosedExcursions = 'ORDER_MANAGE_CLOSED_EXCURSIONS',
  /** Allows you to manage closed extensions on orders */
  OrderManageClosedExtensions = 'ORDER_MANAGE_CLOSED_EXTENSIONS',
  /** Allows you to manage excursions on orders */
  OrderManageExcursions = 'ORDER_MANAGE_EXCURSIONS',
  /** Allows you to manage extended stays on orders */
  OrderManageExtendedStays = 'ORDER_MANAGE_EXTENDED_STAYS',
  /** Allows you to manage extensions on orders */
  OrderManageExtensions = 'ORDER_MANAGE_EXTENSIONS',
  /** Allows you to manage fees on orders */
  OrderManageFees = 'ORDER_MANAGE_FEES',
  /** Allows you to manage flights on orders */
  OrderManageFlights = 'ORDER_MANAGE_FLIGHTS',
  /** Allows you to manage forking orders */
  OrderManageFork = 'ORDER_MANAGE_FORK',
  /** Allows you to manage insurance on orders */
  OrderManageInsurance = 'ORDER_MANAGE_INSURANCE',
  /** Allows you to remove insurance after the removal window */
  OrderManageInsuranceOverrideRemovalWindow = 'ORDER_MANAGE_INSURANCE_OVERRIDE_REMOVAL_WINDOW',
  /** Allows you to manage merging orders */
  OrderManageMerge = 'ORDER_MANAGE_MERGE',
  /** Allows you to manage private group fees on orders */
  OrderManagePrivateGroup = 'ORDER_MANAGE_PRIVATE_GROUP',
  /** Allows you to manage promos on orders */
  OrderManagePromos = 'ORDER_MANAGE_PROMOS',
  /** Allows you to manage rebooking orders */
  OrderManageRebook = 'ORDER_MANAGE_REBOOK',
  /** Allows you to manage roommates on orders */
  OrderManageRoommates = 'ORDER_MANAGE_ROOMMATES',
  /** Allows you to manage rooms on orders */
  OrderManageRooms = 'ORDER_MANAGE_ROOMS',
  /** Allows you to manually change the status of a trip on an order */
  OrderManageStatus = 'ORDER_MANAGE_STATUS',
  /** Allows you to manage traveler information on orders */
  OrderManageTravelerInformation = 'ORDER_MANAGE_TRAVELER_INFORMATION',
  /** Allows you to override TSA expiration date check */
  OrderOverrideTsaExpiration = 'ORDER_OVERRIDE_TSA_EXPIRATION',
  /** Allows you to adjust an orders' autopay */
  OrderPaymentsAutopay = 'ORDER_PAYMENTS_AUTOPAY',
  /** Allows you to make payments for an order */
  OrderPaymentsMakePayment = 'ORDER_PAYMENTS_MAKE_PAYMENT',
  /** Allows you to set a later final installment date for a booking */
  OrderPaymentsOverrideFinalInstallmentDate = 'ORDER_PAYMENTS_OVERRIDE_FINAL_INSTALLMENT_DATE',
  /** Allows you to set a later final payment date for a booking */
  OrderPaymentsOverrideFinalPaymentDate = 'ORDER_PAYMENTS_OVERRIDE_FINAL_PAYMENT_DATE',
  /** Allows you to take a payment for less than the minimum due on booking */
  OrderPaymentsOverrideMinimum = 'ORDER_PAYMENTS_OVERRIDE_MINIMUM',
  /** Allows you to read the transactions on an order */
  OrderPaymentsReadAll = 'ORDER_PAYMENTS_READ_ALL',
  /** Allows you to manually change the status of a payment on an order */
  OrderPaymentsReconcile = 'ORDER_PAYMENTS_RECONCILE',
  /** Allows you to make a refund from an order */
  OrderPaymentsRefund = 'ORDER_PAYMENTS_REFUND',
  /** Allows you to make a refund from an order that would take the order below the minimum balance owed */
  OrderPaymentsRefundOverrideMinimumBalance = 'ORDER_PAYMENTS_REFUND_OVERRIDE_MINIMUM_BALANCE',
  /** Allows you to make payment transfers between orders */
  OrderPaymentsTransfer = 'ORDER_PAYMENTS_TRANSFER',
  /** Allows you to read orders */
  OrderReadAll = 'ORDER_READ_ALL',
  /** Allows you to read order related documents */
  OrderReadDocument = 'ORDER_READ_DOCUMENT',
  /** Allows you to create a tour private group fee */
  PrivateGroupFeeCreate = 'PRIVATE_GROUP_FEE_CREATE',
  /** Allows you to manage tour private group fees */
  PrivateGroupFeeManageAll = 'PRIVATE_GROUP_FEE_MANAGE_ALL',
  /** Allows you to manage tour private group fee prices */
  PrivateGroupFeeManagePricing = 'PRIVATE_GROUP_FEE_MANAGE_PRICING',
  /** Allows you to create a promo */
  PromoCreate = 'PROMO_CREATE',
  /** Allows you to manage promos */
  PromoManageAll = 'PROMO_MANAGE_ALL',
  /** Allows you to manage promo prices */
  PromoManagePricing = 'PROMO_MANAGE_PRICING',
  /** Allows you to read promos */
  PromoReadAll = 'PROMO_READ_ALL',
  /** Allows you to create a quote */
  QuoteCreate = 'QUOTE_CREATE',
  /** Allows you to read quotes */
  QuoteReadAll = 'QUOTE_READ_ALL',
  /** Allows you to manage reviews */
  ReviewManageAll = 'REVIEW_MANAGE_ALL',
  /** Allows you to create a service recovery promo */
  ServiceRecoveryCreate = 'SERVICE_RECOVERY_CREATE',
  /** Allows you to update support ticket */
  SupportTicketUpdate = 'SUPPORT_TICKET_UPDATE',
  /** Allows you to create a new term */
  TermCreate = 'TERM_CREATE',
  /** Allows you to create a tour */
  TourCreate = 'TOUR_CREATE',
  /** Allows you to create a tour excursion */
  TourExcursionCreate = 'TOUR_EXCURSION_CREATE',
  /** Allows you to manage tour excursions */
  TourExcursionManageAll = 'TOUR_EXCURSION_MANAGE_ALL',
  /** Allows you to manage tour excursion prices */
  TourExcursionManagePricing = 'TOUR_EXCURSION_MANAGE_PRICING',
  /** Allows you to create a tour extension */
  TourExtensionCreate = 'TOUR_EXTENSION_CREATE',
  /** Allows you to manage tour extensions */
  TourExtensionManageAll = 'TOUR_EXTENSION_MANAGE_ALL',
  /** Allows you to manage tour extension prices */
  TourExtensionManagePricing = 'TOUR_EXTENSION_MANAGE_PRICING',
  /** Allows you to manage tours */
  TourManageAll = 'TOUR_MANAGE_ALL',
  /** Allows you to manage tour prices */
  TourManagePricing = 'TOUR_MANAGE_PRICING',
  /** Allows you to read tours */
  TourReadAll = 'TOUR_READ_ALL',
  /** Allows you to create a tour room */
  TourRoomCreate = 'TOUR_ROOM_CREATE',
  /** Allows you to manage tour rooms */
  TourRoomManageAll = 'TOUR_ROOM_MANAGE_ALL',
  /** Allows you to manage tour room prices */
  TourRoomManagePricing = 'TOUR_ROOM_MANAGE_PRICING',
  /** Allows you to read the TSA information for a trip */
  TripReadTsaInformation = 'TRIP_READ_TSA_INFORMATION',
  /** Allows you to create a new voucher */
  VoucherCreate = 'VOUCHER_CREATE',
  /** Allows you to update the expiration date of a voucher */
  VoucherManageExpiration = 'VOUCHER_MANAGE_EXPIRATION',
  /** Allows you to increase the balance of a voucher outside the context of a booking */
  VoucherManageIncrease = 'VOUCHER_MANAGE_INCREASE',
  /** Allows you to lower the balance of a voucher outside the context of a booking */
  VoucherManageReduce = 'VOUCHER_MANAGE_REDUCE',
  /** Allows you to mark part of all of the balance of a refundable voucher as refunded to a customer, and edit refund details */
  VoucherManageRefund = 'VOUCHER_MANAGE_REFUND',
  /** Allows you to transfer the balance of a voucher to another voucher or outside of WOJO */
  VoucherManageTransfer = 'VOUCHER_MANAGE_TRANSFER',
  /** Allows you to void a voucher */
  VoucherManageVoid = 'VOUCHER_MANAGE_VOID'
}

export type Place = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  type: PlaceType;
};

/** Filters for places query */
export type PlaceFilterInput = {
  parentId?: InputMaybe<Scalars['ObjectId']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PlaceType>;
};

/** A paginated result of Places */
export type PlacePaginatedResult = {
  __typename?: 'PlacePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Place>;
};

export enum PlaceSortField {
  Name = 'name'
}

export type PlaceSortInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: PlaceSortField;
};

/** The type of place. E.g. country, city, continent, etc. */
export enum PlaceType {
  City = 'CITY',
  Continent = 'CONTINENT',
  Country = 'COUNTRY',
  CountryRegion = 'COUNTRY_REGION',
  GeographicRegion = 'GEOGRAPHIC_REGION'
}

export type PriceRule = {
  __typename?: 'PriceRule';
  dayOfWeek?: Maybe<Scalars['Int']>;
  departureDateFrom?: Maybe<Scalars['Date']>;
  departureDateTo?: Maybe<Scalars['Date']>;
  dpdFrom?: Maybe<Scalars['Int']>;
  dpdTo?: Maybe<Scalars['Int']>;
  effectiveFrom?: Maybe<Scalars['Date']>;
  effectiveTo?: Maybe<Scalars['Date']>;
  nbSpotsBookedFrom?: Maybe<Scalars['Int']>;
  nbSpotsBookedTo?: Maybe<Scalars['Int']>;
  nbSpotsLeftFrom?: Maybe<Scalars['Int']>;
  nbSpotsLeftTo?: Maybe<Scalars['Int']>;
  price: Amount;
  tripLengthFrom?: Maybe<Scalars['Int']>;
  tripLengthTo?: Maybe<Scalars['Int']>;
  tripPriceFrom?: Maybe<Amount>;
  tripPriceTo?: Maybe<Amount>;
};

export type PriceRuleInput = {
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  departureDateFrom?: InputMaybe<Scalars['Date']>;
  departureDateTo?: InputMaybe<Scalars['Date']>;
  dpdFrom?: InputMaybe<Scalars['Int']>;
  dpdTo?: InputMaybe<Scalars['Int']>;
  effectiveFrom?: InputMaybe<Scalars['Date']>;
  effectiveTo?: InputMaybe<Scalars['Date']>;
  nbSpotsBookedFrom?: InputMaybe<Scalars['Int']>;
  nbSpotsBookedTo?: InputMaybe<Scalars['Int']>;
  nbSpotsLeftFrom?: InputMaybe<Scalars['Int']>;
  nbSpotsLeftTo?: InputMaybe<Scalars['Int']>;
  prices: Array<MoneyInput>;
  tripLengthFrom?: InputMaybe<Scalars['Int']>;
  tripLengthTo?: InputMaybe<Scalars['Int']>;
  tripPriceFrom?: InputMaybe<Array<MoneyInput>>;
  tripPriceTo?: InputMaybe<Array<MoneyInput>>;
};

export type PriceYear = {
  __typename?: 'PriceYear';
  year: Scalars['Int'];
  yearCode: Scalars['String'];
  yearEnd: Scalars['Date'];
  yearStart: Scalars['Date'];
};

/** Root fields for all priced trip components */
export type PricedTripComponent = {
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  status: TripComponentStatus;
  type: TripComponentType;
};

export type PrivateGroup = PricedTripComponent & TripComponent & {
  __typename?: 'PrivateGroup';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  rollsUpToTarget: Scalars['Boolean'];
  status: TripComponentStatus;
  tourCode: Scalars['String'];
  type: TripComponentType;
};

export type PrivateGroupLineItem = LineItem & {
  __typename?: 'PrivateGroupLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

export type ProcessorPaymentMethodRefundInput = {
  amount: Scalars['Float'];
  paymentMethodHash: Scalars['String'];
  /** @deprecated Use paymentMethodHash instead */
  processorPaymentMethodId?: InputMaybe<Scalars['Int']>;
};

export type ProductBadgeContent = {
  __typename?: 'ProductBadgeContent';
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

/** A promotion. */
export type Promo = TripComponent & {
  __typename?: 'Promo';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  /** When present this promo can only be redeemed by this specific customer. */
  customerIds?: Maybe<Array<Scalars['ObjectId']>>;
  customers: Array<Customer>;
  /** The name of the promo. */
  displayName: Scalars['String'];
  /** The date when the promo is applicable and can be used */
  effectiveFrom: Scalars['DateTime'];
  /** The date when the promo expires and is no longer valid. */
  effectiveTo: Scalars['DateTime'];
  /** Determines the combinability of promotion */
  exclusivity: PromoExclusivity;
  gcDepartureIds?: Maybe<Array<Scalars['ObjectId']>>;
  gcDepartures: Array<GcDeparture>;
  isGroupsOnly: Scalars['Boolean'];
  isHiddenFromSales: Scalars['Boolean'];
  isIndiesOnly: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  /** If true and the promotion targets specific customers, the promotion can be shared with all trips on the customers order. */
  isShareable: Scalars['Boolean'];
  /** Is the promo valid for use. */
  isValid: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  /** The maximum number of times the promo can be used. */
  maxRedemptions?: Maybe<Scalars['Int']>;
  /** The promo offer's unique code. */
  offerCode: Scalars['String'];
  /** Denotes the promotion as either marketing, service recovery, loyalty, or none. */
  promoCategory: PromoCategory;
  /** The promo codes used to apply the promotion. */
  promoCodes: Array<Scalars['String']>;
  /** The number of times the promo has been used. */
  redemptions: Scalars['Int'];
  status: TripComponentStatus;
  /** The targets of the promo. */
  targets: Array<PromoTarget>;
  type: TripComponentType;
};

export enum PromoCategory {
  Loyalty = 'LOYALTY',
  Marketing = 'MARKETING',
  None = 'NONE',
  ServiceRecovery = 'SERVICE_RECOVERY'
}

export type PromoConfigContent = {
  __typename?: 'PromoConfigContent';
  campaignCode?: Maybe<Scalars['String']>;
  groupsCampaignCode?: Maybe<Scalars['String']>;
  groupsPromoCode?: Maybe<Scalars['String']>;
  maxSpotsRemainingThreshold?: Maybe<Scalars['Int']>;
  promoCode?: Maybe<Scalars['String']>;
};

export enum PromoExclusivity {
  Exclusive = 'EXCLUSIVE',
  None = 'NONE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  SecondaryExclusive = 'SECONDARY_EXCLUSIVE'
}

export type PromoLineItem = LineItem & {
  __typename?: 'PromoLineItem';
  _id: Scalars['ObjectId'];
  campaignCode?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  offerCode: Scalars['String'];
  price: Money;
  promoCode: Scalars['String'];
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemCode?: Maybe<Scalars['LineItemCode']>;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  targetType?: Maybe<TripComponentType>;
  type: TripComponentType;
};

export type PromoPaginatedResult = {
  __typename?: 'PromoPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Promo>;
};

/** Settings related to promotions and service recovery. */
export type PromoSettings = {
  __typename?: 'PromoSettings';
  advancedRepeatPromoCode: Scalars['String'];
  advancedRepeatPromoName: Scalars['String'];
  /** List of reasons for service recovery promotions. */
  serviceRecoveryReasons: Array<Scalars['String']>;
  useAdvancedRepeatPromo: Scalars['Boolean'];
};

/** A product promo target. */
export type PromoTarget = {
  __typename?: 'PromoTarget';
  _id: Scalars['ObjectId'];
  /** The amount of the discount. */
  amount?: Maybe<Amount>;
  /** The offer code of the associated promo offer. */
  componentType: Scalars['String'];
  departureDateFrom?: Maybe<Scalars['Date']>;
  departureDateTo?: Maybe<Scalars['Date']>;
  /** The line item code being targeted. */
  lineItemCode?: Maybe<Scalars['LineItemCode']>;
  series?: Maybe<Scalars['Series']>;
};

export type PublishDraftBookingInput = {
  payments?: InputMaybe<Array<PaymentInput>>;
};

export type PublishDraftBookingResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidOrderError | InvalidPaymentError | NotAuthorizedError | NotFoundError | ValidationError;

export type PublishDraftQuoteResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidOrderError | NotAuthorizedError | NotFoundError | Quote | ValidationError;

export type PublishDraftTermResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | ImmutableTermError | InvalidDateError | NotAuthorizedError | NotFoundError | Term | ValidationError;

export type Query = {
  __typename?: 'Query';
  adjustment?: Maybe<Adjustment>;
  adjustmentByLineItemCode?: Maybe<Adjustment>;
  adjustments: AdjustmentPaginatedResult;
  airline?: Maybe<Airline>;
  /** Get an API key metadata by _id */
  apiKey?: Maybe<ApiKey>;
  asset?: Maybe<Asset>;
  /** Filter and paginate Assets */
  assets: AssetPaginatedResult;
  /** Returns a single booking by id */
  booking?: Maybe<Booking>;
  /** Returns a single trip by id */
  bookingTrip?: Maybe<BookingTrip>;
  /**
   * Returns a single trip by legacy id
   * @deprecated Use bookingTripById instead
   */
  bookingTripByLegacyId?: Maybe<BookingTrip>;
  /** Returns a paginated list of bookings */
  bookings: BookingPaginatedResult;
  /** Get a business by its code */
  business?: Maybe<Business>;
  /** Return all businesses */
  businesses: Array<Business>;
  campaign?: Maybe<Campaign>;
  /** Returns a paginated list of campaigns. */
  campaigns: CampaignPaginatedResult;
  /** Get a paginated list of categories */
  categories: CategoryPaginatedResult;
  category?: Maybe<Category>;
  checkCustomerEmail?: Maybe<CustomerCredentialOptions>;
  checkTourDirectorEmail?: Maybe<TourDirectorCredentialOptions>;
  /** Contact information for the business and customer-facing departments */
  contactInfoContent?: Maybe<ContactInfoContent>;
  contentAncestors: Array<ContentAncestor>;
  /** Get a customer by id */
  customer?: Maybe<Customer>;
  /** Get a customer by id or legacy id */
  customerByLegacyId?: Maybe<Customer>;
  customerEvaluation?: Maybe<CustomerEvaluation>;
  /** Returns a paginated list of customers */
  customers: CustomerPaginatedResult;
  departure?: Maybe<Departure>;
  departureByProdTour?: Maybe<Departure>;
  departureBySalesTour?: Maybe<Departure>;
  departures: DeparturePaginatedResult;
  /** Returns a single draft order by id */
  draftOrder?: Maybe<DraftOrder>;
  employee?: Maybe<Employee>;
  employees: EmployeePaginatedResult;
  evaluation?: Maybe<EvaluationResult>;
  extendedStay?: Maybe<ExtendedStay>;
  extendedStays: ExtendedStayPaginatedResult;
  faqContent?: Maybe<FaqContent>;
  fee?: Maybe<Fee>;
  feeByType?: Maybe<Fee>;
  fees: FeePaginatedResult;
  flightPackage?: Maybe<FlightPackage>;
  flightPackageByLineItemCode?: Maybe<FlightPackage>;
  flightPackages: FlightPackagePaginatedResult;
  futureGCDepartureProspects: Array<GcDepartureProspect>;
  futureGCDepartureProspectsByGCAndProspect: Array<GcDepartureProspect>;
  gateway?: Maybe<Gateway>;
  gateways: GatewayPaginatedResult;
  gcDeparture?: Maybe<GcDeparture>;
  gcDepartureProspect?: Maybe<GcDepartureProspect>;
  gcDepartureProspects: GcDepartureProspectPaginatedResult;
  gcDepartureQuoteSummary?: Maybe<GcDepartureQuoteSummary>;
  gcDepartures: GcDeparturePaginatedResult;
  gcProfileContent?: Maybe<GcProfileContent>;
  /** Get a summary of a customer's global rewards status and points. Returns null if the provided ID is invalid or not enrolled in Global Rewards */
  globalRewardsSummary?: Maybe<GlobalRewardsSummary>;
  insurancePlan?: Maybe<InsurancePlan>;
  insurancePlans: InsurancePlanPaginatedResult;
  /** Find the latest published term by market and type. */
  latestTerm?: Maybe<Term>;
  /** Returns the current user associated with the given session */
  me?: Maybe<User>;
  micrositeContent?: Maybe<MicrositeContent>;
  /** Get a summary of the current customer. Returns null if the current user is not a customer or the customer is not enrolled in Global Rewards */
  myGlobalRewardsSummary?: Maybe<GlobalRewardsSummary>;
  notes: NotePaginatedResult;
  /** Returns a single pre-2018 trip by id */
  oldTrip?: Maybe<OldTrip>;
  /** Returns a single order by id */
  order?: Maybe<Order>;
  /**
   * Returns line item history for a single order by order id
   * @deprecated Use orderLineItemHistory instead
   */
  orderHistory?: Maybe<Array<OrderLineItemHistoryEntry>>;
  /** Returns line item history for a single order by order id */
  orderLineItemHistory?: Maybe<Array<OrderLineItemHistoryEntry>>;
  /** Returns tsa history for a single order by order id */
  orderTSAHistory?: Maybe<Array<OrderTsaHistoryEntry>>;
  /** Returns a paginated list of orders */
  orders: OrderPaginatedResult;
  pastGCDepartureProspects: Array<GcDepartureProspect>;
  paymentReconciliationTransactions: PaymentReconciliationTransactionPaginatedResult;
  paymentReconciliationUpload?: Maybe<PaymentReconciliationUpload>;
  paymentReconciliationUploads: PaymentReconciliationUploadPaginatedResult;
  /** Returns a single trip by id */
  paymentTransactions: PaymentTransactionPaginatedResult;
  permissions: Array<Permission>;
  /** Get a place by _id */
  place?: Maybe<Place>;
  /** Get a list of places */
  places: PlacePaginatedResult;
  promo?: Maybe<Promo>;
  promoConfigContent?: Maybe<PromoConfigContent>;
  /** Returns a paginated list of promos. */
  promos: PromoPaginatedResult;
  /** Returns a single quote by id */
  quote?: Maybe<Quote>;
  /** Returns a paginated list of quotes */
  quotes: QuotePaginatedResult;
  /** Returns a set of reservation document file metadata. */
  reservationDocuments: Array<ReservationDocument>;
  /** Returns a single review */
  review?: Maybe<Review>;
  /** Get the summary of reviews for a set of tours codes */
  reviewAggregate?: Maybe<ReviewAggregate>;
  reviews: ReviewPaginatedResult;
  role?: Maybe<Role>;
  roles: RolePaginatedResult;
  savedPaymentMethod?: Maybe<SavedPaymentMethod>;
  searchFeeCategoryCodes: Array<Scalars['String']>;
  /** Get public help center articles that match the query string. */
  searchHelpCenter?: Maybe<HelpCenterArticleResponse>;
  /** Returns a paginated list of service recoveries. */
  serviceRecoveries: ServiceRecoveryPaginatedResult;
  serviceRecovery?: Maybe<ServiceRecovery>;
  tag?: Maybe<Tag>;
  /** Get a paginated list of tags */
  tags: TagPaginatedResult;
  term?: Maybe<Term>;
  /** Get terms paginated with filters */
  terms: TermPaginatedResult;
  tour?: Maybe<Tour>;
  tourContent?: Maybe<TourContent>;
  tourContentByFullSlug?: Maybe<TourContent>;
  tourContentByTourCode?: Maybe<TourContent>;
  /** Get a tour director by id */
  tourDirector?: Maybe<TourDirector>;
  tourDirectorDepartureEvaluationSummary?: Maybe<DepartureEvaluationSummary>;
  tourDirectorEvaluationSummary?: Maybe<TourDirectorEvaluationSummary>;
  tourDirectorTourEndReport?: Maybe<TourDirectorTourEndReport>;
  /** Get a list of tour end reports based on the provided filters */
  tourDirectorTourEndReports?: Maybe<TourEndReportPaginatedResult>;
  /** Get a list of tour directors based on the provided filters */
  tourDirectors: TourDirectorPaginatedResult;
  tourExcursion?: Maybe<TourExcursion>;
  tourExcursionSpec?: Maybe<TourExcursionSpec>;
  tourExcursionSpecs: TourExcursionSpecPaginatedResult;
  tourExcursions: TourExcursionPaginatedResult;
  tourExtension?: Maybe<TourExtension>;
  /** Get a paginated list of Tour Extensions */
  tourExtensions: TourExtensionPaginatedResult;
  tourPageContent?: Maybe<TourPageContent>;
  tourPagesContent: TourPagePaginatedResult;
  tourSpec?: Maybe<TourSpec>;
  tourSpecs: TourSpecPaginatedResult;
  tours: TourPaginatedResult;
  /** Returns a paginated list of TripChecklistItemTemplates */
  tripChecklistItemTemplates: TripChecklistItemTemplatePaginatedResult;
  voucher?: Maybe<Voucher>;
  vouchers: VoucherPaginatedResult;
};


export type QueryAdjustmentArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryAdjustmentByLineItemCodeArgs = {
  lineItemCode: Scalars['LineItemCode'];
};


export type QueryAdjustmentsArgs = {
  filter?: InputMaybe<FilterAdjustmentInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortAdjustmentInput>;
};


export type QueryAirlineArgs = {
  code: Scalars['String'];
};


export type QueryApiKeyArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryAssetArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilterInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryBookingArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryBookingTripArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryBookingTripByLegacyIdArgs = {
  id: Scalars['String'];
};


export type QueryBookingsArgs = {
  filter?: InputMaybe<FilterBookingInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortBookingInput>;
};


export type QueryBusinessArgs = {
  code: BusinessCode;
};


export type QueryCampaignArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryCampaignsArgs = {
  filter?: InputMaybe<FilterCampaignInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryCategoriesArgs = {
  filter?: InputMaybe<FilterCategoryInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryCategoryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryCheckCustomerEmailArgs = {
  email: Scalars['String'];
};


export type QueryCheckTourDirectorEmailArgs = {
  email: Scalars['String'];
};


export type QueryContactInfoContentArgs = {
  businessCode: BusinessCode;
  language: Scalars['String'];
};


export type QueryContentAncestorsArgs = {
  businessCode: BusinessCode;
  fullSlug: Scalars['String'];
  language: Scalars['String'];
};


export type QueryCustomerArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryCustomerByLegacyIdArgs = {
  id: Scalars['String'];
};


export type QueryCustomerEvaluationArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<FilterCustomerInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryDepartureArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryDepartureByProdTourArgs = {
  prodTourId: Scalars['Int'];
};


export type QueryDepartureBySalesTourArgs = {
  salesTourId: Scalars['String'];
};


export type QueryDeparturesArgs = {
  filter?: InputMaybe<FilterDepartureInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortDepartureInput>;
};


export type QueryDraftOrderArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryEmployeeArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryEmployeesArgs = {
  filter?: InputMaybe<FilterEmployeeInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortEmployeeInput>;
};


export type QueryEvaluationArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryExtendedStayArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryExtendedStaysArgs = {
  filter?: InputMaybe<FilterExtendedStayInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryFaqContentArgs = {
  businessCode: BusinessCode;
  language: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryFeeArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryFeeByTypeArgs = {
  businessCode: BusinessCode;
  categoryCode: Scalars['String'];
  typeCode: FeeTypeCode;
};


export type QueryFeesArgs = {
  filter?: InputMaybe<FilterFeeInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortFeeInput>;
};


export type QueryFlightPackageArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryFlightPackageByLineItemCodeArgs = {
  lineItemCode: Scalars['LineItemCode'];
};


export type QueryFlightPackagesArgs = {
  filter?: InputMaybe<FilterFlightPackageInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryFutureGcDepartureProspectsArgs = {
  businessCode: BusinessCode;
  groupCoordinatorCustomerId: Scalars['ObjectId'];
};


export type QueryFutureGcDepartureProspectsByGcAndProspectArgs = {
  groupCoordinatorCustomerId: Scalars['ObjectId'];
  prospectCustomerId: Scalars['ObjectId'];
};


export type QueryGatewayArgs = {
  code: Scalars['String'];
};


export type QueryGatewaysArgs = {
  filter?: InputMaybe<FilterGatewayInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryGcDepartureArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGcDepartureProspectArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryGcDepartureProspectsArgs = {
  filter?: InputMaybe<FilterGcDepartureProspectInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortGcDepartureProspectInput>;
};


export type QueryGcDepartureQuoteSummaryArgs = {
  input: GcDepartureQuoteSummaryInput;
};


export type QueryGcDeparturesArgs = {
  filter?: InputMaybe<FilterGcDepartureInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortGcDepartureInput>;
};


export type QueryGcProfileContentArgs = {
  businessCode: BusinessCode;
  fullSlug: Scalars['String'];
  language: Scalars['String'];
};


export type QueryGlobalRewardsSummaryArgs = {
  globalRewardsId: Scalars['String'];
};


export type QueryInsurancePlanArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryInsurancePlansArgs = {
  filter?: InputMaybe<FilterInsurancePlanInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortInsurancePlanInput>;
};


export type QueryLatestTermArgs = {
  businessCode: BusinessCode;
  market: TermMarket;
  termType: TermType;
};


export type QueryMicrositeContentArgs = {
  businessCode: BusinessCode;
  fullSlug: Scalars['String'];
  language: Scalars['String'];
};


export type QueryNotesArgs = {
  filter?: InputMaybe<FilterNoteInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryOldTripArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryOrderArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryOrderHistoryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryOrderLineItemHistoryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryOrderTsaHistoryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<FilterOrderInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryPastGcDepartureProspectsArgs = {
  businessCodes?: InputMaybe<Array<BusinessCode>>;
  groupCoordinatorCustomerId: Scalars['ObjectId'];
};


export type QueryPaymentReconciliationTransactionsArgs = {
  filter?: InputMaybe<FilterPaymentReconciliationTransactionInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortPaymentReconciliationTransactionInput>;
};


export type QueryPaymentReconciliationUploadArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryPaymentReconciliationUploadsArgs = {
  filter?: InputMaybe<FilterPaymentReconciliationUploadInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryPaymentTransactionsArgs = {
  filter?: InputMaybe<FilterPaymentTransactionInput>;
  pagination?: InputMaybe<OffsetPaginateInput>;
};


export type QueryPlaceArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryPlacesArgs = {
  filter?: InputMaybe<PlaceFilterInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<PlaceSortInput>;
};


export type QueryPromoArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryPromoConfigContentArgs = {
  businessCode: BusinessCode;
  language: Scalars['String'];
};


export type QueryPromosArgs = {
  filter?: InputMaybe<FilterPromoInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryQuoteArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryQuotesArgs = {
  filter?: InputMaybe<FilterQuoteInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortQuoteInput>;
};


export type QueryReservationDocumentsArgs = {
  reservationIds: Array<Scalars['Int']>;
};


export type QueryReviewArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryReviewAggregateArgs = {
  businessCode: BusinessCode;
  tourCodes: Array<Scalars['String']>;
};


export type QueryReviewsArgs = {
  filter?: InputMaybe<ReviewFilterInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<ReviewSortInput>;
};


export type QueryRoleArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryRolesArgs = {
  filter?: InputMaybe<FilterRoleInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QuerySavedPaymentMethodArgs = {
  _id: Scalars['ObjectId'];
};


export type QuerySearchFeeCategoryCodesArgs = {
  businessCode?: InputMaybe<BusinessCode>;
  query: Scalars['String'];
};


export type QuerySearchHelpCenterArgs = {
  businessCode: BusinessCode;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QueryServiceRecoveriesArgs = {
  filter?: InputMaybe<FilterServiceRecoveryInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryServiceRecoveryArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTagArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTagsArgs = {
  filter?: InputMaybe<FilterTagInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryTermArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTermsArgs = {
  filter?: InputMaybe<TermFilterInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<TermSortInput>;
};


export type QueryTourArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourContentArgs = {
  businessCode: BusinessCode;
  id: Scalars['String'];
  language: Scalars['String'];
};


export type QueryTourContentByFullSlugArgs = {
  businessCode: BusinessCode;
  fullSlug: Scalars['String'];
  language: Scalars['String'];
};


export type QueryTourContentByTourCodeArgs = {
  businessCode: BusinessCode;
  language: Scalars['String'];
  tourCode: Scalars['String'];
  tourYear: Scalars['Int'];
};


export type QueryTourDirectorArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourDirectorDepartureEvaluationSummaryArgs = {
  departureId: Scalars['ObjectId'];
  tourDirectorId: Scalars['ObjectId'];
};


export type QueryTourDirectorEvaluationSummaryArgs = {
  businessCode?: InputMaybe<BusinessCode>;
  tourDirectorId: Scalars['ObjectId'];
};


export type QueryTourDirectorTourEndReportArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourDirectorTourEndReportsArgs = {
  filter?: InputMaybe<FilterTourEndReportInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortTourEndReportInput>;
};


export type QueryTourDirectorsArgs = {
  filter?: InputMaybe<FilterTourDirectorInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortTourDirectorInput>;
};


export type QueryTourExcursionArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourExcursionSpecArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourExcursionSpecsArgs = {
  filter?: InputMaybe<FilterTourExcursionSpecInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryTourExcursionsArgs = {
  filter?: InputMaybe<FilterTourExcursionInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortTourExcursionInput>;
};


export type QueryTourExtensionArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourExtensionsArgs = {
  filter?: InputMaybe<FilterTourExtensionInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortTourExtensionInput>;
};


export type QueryTourPageContentArgs = {
  alternateYearTourId?: InputMaybe<Scalars['String']>;
  businessCode: BusinessCode;
  fullSlug: Scalars['String'];
  language: Scalars['String'];
};


export type QueryTourPagesContentArgs = {
  filter: FilterTourPageContentInput;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryTourSpecArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryTourSpecsArgs = {
  filter?: InputMaybe<FilterTourSpecInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryToursArgs = {
  filter?: InputMaybe<FilterTourInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortTourInput>;
};


export type QueryTripChecklistItemTemplatesArgs = {
  filter?: InputMaybe<FilterTripChecklistItemTemplateInput>;
  pagination?: InputMaybe<PaginateInput>;
};


export type QueryVoucherArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryVouchersArgs = {
  filter?: InputMaybe<FilterVoucherInput>;
  pagination?: InputMaybe<PaginateInput>;
  sort?: InputMaybe<SortVoucherInput>;
};

/** An order in the Quote stage */
export type Quote = Order & {
  __typename?: 'Quote';
  _id: Scalars['ObjectId'];
  /** Sum of total successful transactions */
  amountPaid: Money;
  averageTotalPerTrip: Money;
  /** Grand total less the amount already paid */
  balance: Money;
  businessCode: BusinessCode;
  checkoutOptions: Array<QuoteCheckoutOption>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency code of the order. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer who initiated the booking. */
  customerId?: Maybe<Scalars['ObjectId']>;
  departure?: Maybe<Departure>;
  /** The departure id of the order. */
  departureId: Scalars['ObjectId'];
  expirationDate: Scalars['DateTime'];
  /** The extended date the final payment is due for the order. */
  extendedFinalPaymentDate?: Maybe<Scalars['Date']>;
  /** The date the final installment for app is due for the order. */
  finalInstallmentDate: Scalars['Date'];
  /** The date the final payment is due for the order. */
  finalPaymentDate: Scalars['Date'];
  gcDeparture?: Maybe<GcDeparture>;
  /** The id of the gc departure associated with the order. */
  gcDepartureId?: Maybe<Scalars['ObjectId']>;
  /** Sum of all line item prices */
  grandTotal: Money;
  /** Sum of all line item prices without discounts applied */
  grandTotalBeforeDiscount: Money;
  /** Sum of all line items excluding flights */
  grandTotalWithoutFlights: Money;
  isExpired: Scalars['Boolean'];
  /** Indicates if the final payment date has been extended. */
  isFinalPaymentDateExtended: Scalars['Boolean'];
  /** The latest date the final installment for app is due for the order. */
  latestFinalInstallmentDate: Scalars['Date'];
  /** The latest date the final payment is due for the order. */
  latestFinalPaymentDate: Scalars['Date'];
  opportunityId?: Maybe<Scalars['String']>;
  /** The order that this order was rebooked from. */
  rebookedFromOrder?: Maybe<Order>;
  /** The order id that this order was rebooked from. */
  rebookedFromOrderId?: Maybe<Scalars['ObjectId']>;
  /** The stage of the order. */
  stage: OrderStage;
  /** Sum of all discount line items */
  totalDiscount: Money;
  tourLineItemCode?: Maybe<Scalars['LineItemCode']>;
  /** The transactions applied to the order, sorted by ascending transaction date. */
  transactions: Array<PaymentTransaction>;
  trips: Array<QuoteTrip>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The version of the order. */
  versionId: Scalars['Int'];
  /** Whether the quote will be waitlisted based on whether waitlist is allowed and the remaining capacity. If waitlist is accepted when booking and capacities have not changed, the booking will be waitlisted */
  willWaitlist: Scalars['Boolean'];
};

export type QuoteAutopayCheckoutOption = {
  __typename?: 'QuoteAutopayCheckoutOption';
  allowedPaymentMethods: Array<QuoteCheckoutAllowedPaymentMethodType>;
  amount: Money;
  earliestPaymentScheduleStartDate: Scalars['Date'];
  latestPaymentScheduleStartDate: Scalars['Date'];
  riskFreePeriodEndDate: Scalars['Date'];
  type: QuoteCheckoutType;
};

/** The type of payment methods allows for a checkout option for a Quote */
export enum QuoteCheckoutAllowedPaymentMethodType {
  Ach = 'ACH',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD'
}

export type QuoteCheckoutOption = QuoteAutopayCheckoutOption | QuoteManualCheckoutOption;

/** The type of checkout option for a Quote */
export enum QuoteCheckoutType {
  AutoPay = 'AUTO_PAY',
  Manual = 'MANUAL',
  PayInFull = 'PAY_IN_FULL'
}

export type QuoteManualCheckoutOption = {
  __typename?: 'QuoteManualCheckoutOption';
  allowedPaymentMethods: Array<QuoteCheckoutAllowedPaymentMethodType>;
  /** The amount required to book based on checkout option. For AUTO_PAY, this is the APP deposit. For PAY_IN_FULL, this is the grand total. */
  amount: Money;
  commonFees: Array<FeeApplicationInfo>;
  riskFreePeriodEndDate: Scalars['Date'];
  type: QuoteCheckoutType;
};

export type QuotePaginatedResult = {
  __typename?: 'QuotePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Quote>;
};

export enum QuoteSortField {
  CreatedAt = 'createdAt',
  DepartureDate = 'departureDate',
  ExpirationDate = 'expirationDate',
  UpdatedAt = 'updatedAt'
}

/** A trip on a quote */
export type QuoteTrip = Trip & {
  __typename?: 'QuoteTrip';
  _id: Scalars['ObjectId'];
  /** The address for the trip. */
  address?: Maybe<Address>;
  businessCode?: Maybe<BusinessCode>;
  /** The currency code of the trip. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer id of the trip. */
  customerId?: Maybe<Scalars['ObjectId']>;
  customerType: TripCustomerType;
  departure?: Maybe<Departure>;
  departureDate: Scalars['Date'];
  /** The deposit amount for the trip. */
  depositAmount: Money;
  /** The emergency contacts for the trip. */
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  endDate: Scalars['Date'];
  /** Sum of all open excursion line items */
  excursionsTotal: Money;
  flightPackagePrice?: Maybe<Money>;
  grandTotal: Money;
  hasAddress: Scalars['Boolean'];
  hasDepartingFlightDeviation: Scalars['Boolean'];
  hasEmergencyContacts: Scalars['Boolean'];
  /** Whether the trip has an open line item for an extension */
  hasExtension: Scalars['Boolean'];
  hasReturningFlightDeviation: Scalars['Boolean'];
  hasTsaInformation: Scalars['Boolean'];
  insurancePlanOffers: Array<InsurancePlanOffer>;
  /** Whether the trip is cancelled. */
  isCancelled: Scalars['Boolean'];
  /** Whether the trip is confirmed. */
  isConfirmed: Scalars['Boolean'];
  isLandOnly: Scalars['Boolean'];
  /** Whether the trip is open. */
  isOpen: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  /** Whether the trip is quoted. */
  isQuoted: Scalars['Boolean'];
  /** Whether the trip is rebooked. */
  isRebooked: Scalars['Boolean'];
  /** Whether the trip is requested. */
  isRequested: Scalars['Boolean'];
  /** Whether the trip is waitlisted. */
  isWaitlisted: Scalars['Boolean'];
  /** The line items that compose the trip. */
  lineItems: Array<LineItem>;
  /** The REBOOKED + OPEN line items if the trip is rebooked, the CANCELLED + OPEN line items with PARENT_TRIP_CANCELLED cancellation metadata if the trip is cancelled, or the open line items in all other cases.  */
  lineItemsMatchingTripStatus: Array<LineItem>;
  openAdjustmentLineItems: Array<AdjustmentLineItem>;
  openDepartingFlightLineItem?: Maybe<FlightPackageLineItem>;
  openExcursionLineItems: Array<TourExcursionLineItem>;
  openExtensionLineItem?: Maybe<TourExtensionLineItem>;
  openExtensionRoomLineItem?: Maybe<TourRoomLineItem>;
  openFeeLineItems: Array<FeeLineItem>;
  openInsuranceAddonLineItems: Array<InsuranceAddonLineItem>;
  openInsuranceLineItem?: Maybe<InsuranceLineItem>;
  /** The line items that compose the trip which are not CANCELLED or REBOOKED */
  openLineItems: Array<LineItem>;
  openPostStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPreStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPrivateGroupLineItem?: Maybe<PrivateGroupLineItem>;
  openPromoLineItems: Array<PromoLineItem>;
  openReturningFlightLineItem?: Maybe<FlightPackageLineItem>;
  openRoomLineItem?: Maybe<TourRoomLineItem>;
  /** The trip ID for the trip that this trip was rebooked from. */
  rebookedFromTripId?: Maybe<Scalars['ObjectId']>;
  requestedRoommates?: Maybe<Array<Scalars['String']>>;
  requestedTravelCompanions?: Maybe<Array<Scalars['String']>>;
  /** The status of the trip */
  status: TripStatus;
  timingStatus: TripTimingStatus;
  tourLineItem?: Maybe<TourLineItem>;
  travelerNotes?: Maybe<TravelerNotes>;
  tripCustomer?: Maybe<TripCustomer>;
  /** The TSA information for the trip. */
  tsaInformation?: Maybe<TsaInformation>;
};

export enum RebookingReasonCategory {
  Financial = 'FINANCIAL',
  LifeEvent = 'LIFE_EVENT',
  ProductTransition = 'PRODUCT_TRANSITION',
  TravelerDiscontent = 'TRAVELER_DISCONTENT',
  TravelRequirementNotMet = 'TRAVEL_REQUIREMENT_NOT_MET',
  Unknown = 'UNKNOWN',
  WojoCancellation = 'WOJO_CANCELLATION',
  WorldEvent = 'WORLD_EVENT'
}

export type ReconcileCardOrAchPaymentResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type ReconcileCheckPaymentResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type ReconcilePaymentResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type ReconcilePaymentsResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | PaymentReconciliationUpload | ValidationError;

export type ReconcileTransactionInput = {
  amount: MoneyInput;
  processDate: Scalars['Date'];
  processorMerchantReferenceNumber: Scalars['String'];
  returnReasonCode?: InputMaybe<Scalars['String']>;
  returnReasonString?: InputMaybe<Scalars['String']>;
  transactionType: ReconciliationTransactionType;
};

export enum ReconciliationStatus {
  Failure = 'FAILURE',
  Skipped = 'SKIPPED',
  Success = 'SUCCESS',
  Unprocessed = 'UNPROCESSED'
}

export enum ReconciliationTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Return = 'RETURN'
}

/** Reduces the  non-expired voucher by a dollar amount */
export type ReduceVoucherInput = {
  /** The ID of the Voucher */
  _id: Scalars['ObjectId'];
  /** The amount to reduce the Voucher by */
  amount: MoneyInput;
  /** The reason that the voucher is being reduced */
  reason: Scalars['String'];
};

export type ReduceVoucherResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

export type RefundInput = {
  checkRefundAmount?: InputMaybe<Scalars['Float']>;
  processorPaymentMethodRefunds?: InputMaybe<Array<ProcessorPaymentMethodRefundInput>>;
  refundReason: RefundReasonCode;
  savedPaymentMethodRefunds?: InputMaybe<Array<SavedPaymentMethodRefundInput>>;
  voucherRefunds?: InputMaybe<Array<VoucherRefundInput>>;
};

export enum RefundReasonCode {
  Cancellation = 'CANCELLATION',
  Optional = 'OPTIONAL',
  Overpayment = 'OVERPAYMENT'
}

export type RefundVoucherInput = {
  /** The ID of the Voucher */
  _id: Scalars['ObjectId'];
  /** The date the check was issued */
  checkIssuedOn?: InputMaybe<Scalars['Date']>;
  /** The check number */
  checkNumber?: InputMaybe<Scalars['String']>;
  /** Information about the employee who approved the refund check */
  issuedBy?: InputMaybe<Scalars['String']>;
  /** The reason for the refund */
  reason?: InputMaybe<Scalars['String']>;
  /** The amount to refund. If not provided, the remaining amount of the voucher will be used */
  refundAmount?: InputMaybe<Scalars['Int']>;
};

export type RefundVoucherResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

export type ReleaseDetails = {
  __typename?: 'ReleaseDetails';
  isReleased: Scalars['Boolean'];
  releaseDate?: Maybe<Scalars['DateTime']>;
};

/** Removes promos to a campaign */
export type RemoveCampaignPromosInput = {
  promoIds: Array<Scalars['ObjectId']>;
};

export type RemoveCampaignPromosResult = BusinessNotSpecifiedError | Campaign | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderAdjustmentInput = {
  /** The adjustment lineItemIds to remove from this trip. */
  adjustmentLineItemIds: Array<Scalars['ObjectId']>;
  /** The reason for removing the adjustment. */
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  /** The trip to remove the adjustment(s) from. */
  tripId: Scalars['ObjectId'];
};

export type RemoveDraftOrderAdjustmentsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderAirportTransferInput = {
  flightType: FlightType;
  tripId: Scalars['ObjectId'];
};

export type RemoveDraftOrderAirportTransferResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderExcursionResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderExcursionsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderExtensionResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderFeesInput = {
  /** The fee lineItemIds to remove from this trip */
  feeLineItemIds: Array<Scalars['ObjectId']>;
  /** The trip to remove the fee(s) from */
  tripId: Scalars['ObjectId'];
};

export type RemoveDraftOrderFeesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderInsuranceAddonResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderInsuranceExtensionResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidInsuranceExtensionError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderInsuranceResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderPostStayResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderPreStayResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderPrivateGroupResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftOrderPromoResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveDraftQuoteTripsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type RemoveEmployeesFromRoleInput = {
  employeeIds: Array<Scalars['ObjectId']>;
};

export type RemoveEmployeesFromRoleResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Role | ValidationError;

export type RemovePrivateGroupResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type RemovePromoCodesInput = {
  promoCodes: Array<Scalars['String']>;
};

export type RemovePromoCodesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type RemovePromoCustomersInput = {
  customerIds: Array<Scalars['ObjectId']>;
};

export type RemovePromoCustomersResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type RemovePromoGcDeparturesInput = {
  gcDepartureIds: Array<Scalars['ObjectId']>;
};

export type RemovePromoGcDeparturesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type RemovePromoTargetsInput = {
  targetIds: Array<Scalars['ObjectId']>;
};

export type RemovePromoTargetsResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

/** The reservation of a departure itinerary component */
export type Reservation = {
  __typename?: 'Reservation';
  reservationId?: Maybe<Scalars['Int']>;
  timeFrom?: Maybe<Scalars['Time']>;
};

/** Reservation document file metadata. */
export type ReservationDocument = {
  __typename?: 'ReservationDocument';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  externalDocumentId: Scalars['String'];
  externalReservationId: Scalars['Int'];
  filename?: Maybe<Scalars['String']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sizeInBytes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

/** A review consists of a rating and an optional review detail */
export type Review = {
  __typename?: 'Review';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['ObjectId'];
  detail?: Maybe<ReviewDetail>;
  images: Array<ReviewImage>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isFeatured: Scalars['Boolean'];
  nbPastTrips: Scalars['Int'];
  rating: Scalars['Int'];
  tour?: Maybe<Tour>;
  tourCode: Scalars['String'];
  travelDate: Scalars['Date'];
  tripId: Scalars['ObjectId'];
};


/** A review consists of a rating and an optional review detail */
export type ReviewImagesArgs = {
  status?: InputMaybe<ReviewStatus>;
};

/** Summary of reviews for a tour */
export type ReviewAggregate = {
  __typename?: 'ReviewAggregate';
  avgRating: Scalars['Float'];
  nbReviews: Scalars['Int'];
};

/** Details of a review by a customer */
export type ReviewDetail = {
  __typename?: 'ReviewDetail';
  _id: Scalars['ObjectId'];
  author?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  response?: Maybe<ReviewResponse>;
  status: ReviewStatus;
  title?: Maybe<Scalars['String']>;
};

/** Filters for review query */
export type ReviewFilterInput = {
  businessCode?: InputMaybe<BusinessCode>;
  customerId?: InputMaybe<Scalars['ObjectId']>;
  hasDetail?: InputMaybe<Scalars['Boolean']>;
  hasImages?: InputMaybe<Scalars['Boolean']>;
  hasResponse?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  ratingFrom?: InputMaybe<Scalars['Int']>;
  ratingTo?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ReviewStatus>;
  tourCodes?: InputMaybe<Array<Scalars['String']>>;
  travelDateFrom?: InputMaybe<Scalars['Date']>;
  travelDateTo?: InputMaybe<Scalars['Date']>;
};

/** Images that have been uploaded as part of a review */
export type ReviewImage = {
  __typename?: 'ReviewImage';
  _id: Scalars['ObjectId'];
  asset?: Maybe<Asset>;
  assetId: Scalars['ObjectId'];
  status: ReviewStatus;
};

/** A paginated set of Reviews */
export type ReviewPaginatedResult = {
  __typename?: 'ReviewPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Review>;
};

/** A response to the customer about their review */
export type ReviewResponse = {
  __typename?: 'ReviewResponse';
  author: Scalars['String'];
  body: Scalars['String'];
};

/** Review settings */
export type ReviewSettings = {
  __typename?: 'ReviewSettings';
  /** Number of days post departure after which a review can be submitted */
  reviewCutoffDays: Scalars['Int'];
};

export enum ReviewSortField {
  IsFeatured = 'isFeatured',
  Rating = 'rating',
  TravelDate = 'travelDate'
}

export type ReviewSortInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: ReviewSortField;
};

/** The detail and images of a review need to be moderated before being visible publicly */
export enum ReviewStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Risk free period settings for the order. */
export type RiskFreePeriodSettings = {
  __typename?: 'RiskFreePeriodSettings';
  minimumEligibleDpd: Scalars['Int'];
  nbAutopayDays: Scalars['Int'];
  nbManualDays: Scalars['Int'];
  nbPayInFullDays: Scalars['Int'];
  preserveRiskFreePeriodOnRebooking: Scalars['Boolean'];
  useMinimumEligibleDpd: Scalars['Boolean'];
};

/** A collection of permissions that can be assigned to a user */
export type Role = {
  __typename?: 'Role';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  name: Scalars['String'];
  permissionKeys: Array<PermissionKey>;
  permissions: Array<Permission>;
};

/** A paginated result of Roles */
export type RolePaginatedResult = {
  __typename?: 'RolePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Role>;
};

export type RoomContent = {
  __typename?: 'RoomContent';
  bedCode?: Maybe<Scalars['String']>;
  cabinCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum RoomTourType {
  Extension = 'EXTENSION',
  MainTour = 'MAIN_TOUR'
}

/** Status, ID and occupants of a room */
export type Roommate = {
  __typename?: 'Roommate';
  roommateId: Scalars['ObjectId'];
  status: RoommateStatus;
};

export enum RoommateStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type SaveEvaluationResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidEvaluationError | NotAuthorizedError | NotFoundError | SaveEvaluationSuccess | ValidationError;

export type SaveEvaluationSuccess = {
  __typename?: 'SaveEvaluationSuccess';
  data: Evaluation;
};

export type SaveablePaymentMethod = AchPaymentMethod | CardPaymentMethod;

/** Saved payment method for a customer */
export type SavedPaymentMethod = {
  __typename?: 'SavedPaymentMethod';
  _id: Scalars['ObjectId'];
  customerId: Scalars['ObjectId'];
  /** Whether the payment method is active, returns false if the payment method is expired */
  isActive: Scalars['Boolean'];
  /** Whether the payment method is expired */
  isExpired: Scalars['Boolean'];
  paymentMethod: SaveablePaymentMethod;
};

export type SavedPaymentMethodInput = {
  savedPaymentMethodId: Scalars['ObjectId'];
};

export type SavedPaymentMethodRefundInput = {
  amount: Scalars['Float'];
  savedPaymentMethodId: Scalars['ObjectId'];
};

/** Generate a magic link, and send to a customer or tour director */
export type SendMagicLinkInput = {
  email: Scalars['String'];
  emailTemplateId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  isTourDirector?: InputMaybe<Scalars['Boolean']>;
  returnURL: Scalars['String'];
};

export type SendMagicLinkResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | EmailSendFailedError | InvalidDateError | InvalidMagicLinkError | MagicLinkResult | NotAuthorizedError | NotFoundError | ValidationError;

/** A service recovery promotion. */
export type ServiceRecovery = {
  __typename?: 'ServiceRecovery';
  _id: Scalars['ObjectId'];
  bookingTrip?: Maybe<BookingTrip>;
  businessCode: BusinessCode;
  orderId: Scalars['ObjectId'];
  promo?: Maybe<Promo>;
  promoId: Scalars['ObjectId'];
  recoveryReason: Scalars['String'];
  recoveryReasons?: Maybe<Array<Scalars['String']>>;
  tripId: Scalars['ObjectId'];
};

export type ServiceRecoveryPaginatedResult = {
  __typename?: 'ServiceRecoveryPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<ServiceRecovery>;
};

export type SetDraftQuoteCustomerResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type SetTourDirectorProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  hideEmail?: InputMaybe<Scalars['Boolean']>;
  hidePhoneNumber?: InputMaybe<Scalars['Boolean']>;
  profileImageAssetId?: InputMaybe<Scalars['ObjectId']>;
};

export type SigninCustomerWithMagicLinkResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidSigninError | NotAuthorizedError | NotFoundError | SigninResult | ValidationError;

export type SigninCustomerWithPasswordResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidSigninError | NotAuthorizedError | NotFoundError | SigninResult | ValidationError;

export type SigninResult = {
  __typename?: 'SigninResult';
  hasPassword?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  tokenExpiresAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type SigninTourDirectorWithMagicLinkResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidSigninError | NotAuthorizedError | NotFoundError | SigninResult | ValidationError;

/** Signin with a password */
export type SigninWithPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignoutResult = {
  __typename?: 'SignoutResult';
  success: Scalars['Boolean'];
};

/** Signup as a new customer */
export type SignupCustomerInput = {
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
};

export type SignupCustomerResult = BusinessNotSpecifiedError | CustomerSignupResult | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type SortAdjustmentInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: AdjustmentSortField;
};

export type SortBookingInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: BookingSortField;
};

export type SortDepartureInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: DepartureSortField;
};

export type SortEmployeeInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: EmployeeSortField;
};

export type SortFeeInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: FeeSortField;
};

export type SortGcDepartureInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: GcDepartureSortField;
};

export type SortGcDepartureProspectInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: GcDepartureProspectSortField;
};

export type SortInsurancePlanInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: InsurancePlanSortField;
};

export type SortPaymentReconciliationTransactionInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: PaymentReconciliationTransactionSortField;
};

export type SortQuoteInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: QuoteSortField;
};

export type SortTourDirectorInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TourDirectorSortField;
};

export type SortTourEndReportInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TourEndReportSortField;
};

export type SortTourExcursionInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TourExcursionSortField;
};

export type SortTourExtensionInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TourExtensionSortField;
};

export type SortTourInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TourSortField;
};

export type SortVoucherInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: VoucherSortField;
};

export enum SourceProcessorOrAcquirer {
  Cybersource = 'CYBERSOURCE',
  Telecheck = 'TELECHECK',
  Worldpaycreditcard = 'WORLDPAYCREDITCARD',
  Worldpayecheck = 'WORLDPAYECHECK'
}

export type StartQuoteInput = {
  applyAdvancedRepeatPromo?: InputMaybe<Scalars['Boolean']>;
  campaignCode?: InputMaybe<Scalars['String']>;
  currency: CurrencyCode;
  /** The Customer ID for the primary traveler, if such a Customer ID already exists.  */
  customerId?: InputMaybe<Scalars['ObjectId']>;
  departureId: Scalars['ObjectId'];
  expirationDate?: InputMaybe<Scalars['Date']>;
  gatewayCode?: InputMaybe<Scalars['String']>;
  gcDepartureId?: InputMaybe<Scalars['ObjectId']>;
  /** The number of additional Trips to create beyond the primary Trip. If omitted, only the primary Trip will be created. */
  nbSecondaryTravelers?: InputMaybe<Scalars['Int']>;
  opportunityId?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
};

export type StartQuoteResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidAdjustmentError | InvalidDateError | InvalidDepartureError | InvalidGcDepartureError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

/** Puts a booking into `PENDING_REBOOKING` status */
export type StartRebookingInput = {
  /** The booking ID */
  _id: Scalars['ObjectId'];
  /** The amount to charge as a rebooking fee for extensions */
  extensionsRebookingFeeAmount?: InputMaybe<Scalars['Float']>;
  /** The amount to charge as a rebooking fee for flights */
  flightRebookingFeeAmount?: InputMaybe<Scalars['Float']>;
  /** The categorization of the rebooking reason */
  rebookingReasonCategory: RebookingReasonCategory;
  /** The rebooking reason */
  rebookingReasonDescription: Scalars['String'];
  /** Whether or not to waive the rebooking fee */
  waiveFee?: InputMaybe<Scalars['Boolean']>;
};

export type StartRebookingQuoteInput = {
  currency: CurrencyCode;
  departureId: Scalars['ObjectId'];
  expirationDate?: InputMaybe<Scalars['Date']>;
  gatewayCode?: InputMaybe<Scalars['String']>;
  gcDepartureId?: InputMaybe<Scalars['ObjectId']>;
  opportunityId?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  rebookingFromOrderId: Scalars['ObjectId'];
};

export type StartRebookingQuoteResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidGcDepartureError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type StartRebookingResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type StopOver = {
  __typename?: 'StopOver';
  endDate: Scalars['Date'];
  gatewayCode: Scalars['String'];
  startDate: Scalars['Date'];
};

export type StopOverInput = {
  /** Stop over end date */
  endDate: Scalars['Date'];
  /** Stop over gateway code */
  gatewayCode: Scalars['String'];
  /** Stop over start date */
  startDate: Scalars['Date'];
};

export type SubmitEvaluationResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidEvaluationError | NotAuthorizedError | NotFoundError | SubmitEvaluationSuccess | ValidationError;

export type SubmitEvaluationSuccess = {
  __typename?: 'SubmitEvaluationSuccess';
  data: Evaluation;
};

export type SuccessResult = {
  __typename?: 'SuccessResult';
  success: Scalars['Boolean'];
};

/** The supplier of a departure itinerary component */
export type Supplier = {
  __typename?: 'Supplier';
  addresses: Array<Address>;
  code?: Maybe<Scalars['String']>;
  contacts: Array<SupplierContact>;
  name?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** The contact details of a supplier */
export type SupplierContact = {
  __typename?: 'SupplierContact';
  emails: Array<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumbers: Array<Scalars['String']>;
};

/** Invalid support ticket query */
export type SupportTicketError = DomainError & {
  __typename?: 'SupportTicketError';
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type SupportTicketInput = {
  body: Scalars['String'];
  customFields?: InputMaybe<Array<SupportTicketInputCustomField>>;
  public?: InputMaybe<Scalars['Boolean']>;
  requesterEmail: Scalars['String'];
  requesterName: Scalars['String'];
  subject: Scalars['String'];
  ticketFormId: Scalars['String'];
  tripId: Scalars['String'];
};

export type SupportTicketInputCustomField = {
  id: Scalars['String'];
  value: Scalars['String'];
};

/** Response from the support ticket API */
export type SupportTicketSuccess = {
  __typename?: 'SupportTicketSuccess';
  ticketId: Scalars['String'];
};

export type SystemChecklistItem = {
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

export enum TsaGender {
  F = 'F',
  M = 'M',
  X = 'X'
}

/** TSA information for a traveler */
export type TsaInformation = {
  __typename?: 'TSAInformation';
  _id: Scalars['ObjectId'];
  confirmationDate?: Maybe<Scalars['DateTime']>;
  countryOfIssue?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<TsaGender>;
  hasCompletedPassportInformation: Scalars['Boolean'];
  hasConfirmedTsaIdentifiers: Scalars['Boolean'];
  identityDocumentNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  maskedIdentityDocumentNumber?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  stateOfIssue?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  type?: Maybe<IdentityDocumentType>;
};

/** A tag that can be associated to a variety of other entities */
export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  category?: Maybe<Category>;
  categoryId: Scalars['ObjectId'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** A paginated set of Tags */
export type TagPaginatedResult = {
  __typename?: 'TagPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Tag>;
};

/** Terms that can be signed */
export type Term = {
  __typename?: 'Term';
  _id: Scalars['ObjectId'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  certificationText?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  market: TermMarket;
  publishedAt?: Maybe<Scalars['DateTime']>;
  stage: TermStage;
  termType: TermType;
};

/** Apply filters to a terms query */
export type TermFilterInput = {
  businessCode?: InputMaybe<BusinessCode>;
  market?: InputMaybe<TermMarket>;
  stage?: InputMaybe<TermStage>;
  termType?: InputMaybe<TermType>;
};

/** The market the term is applicable to */
export enum TermMarket {
  Ca = 'CA',
  Us = 'US'
}

/** A paginated result of Terms */
export type TermPaginatedResult = {
  __typename?: 'TermPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Term>;
};

export enum TermSortField {
  PublishedAt = 'publishedAt'
}

export type TermSortInput = {
  sortAscending: Scalars['Boolean'];
  sortBy: TermSortField;
};

/** Whether the term is published, draft, or archived */
export enum TermStage {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** The type of term */
export enum TermType {
  General = 'GENERAL',
  GroupCoordinator = 'GROUP_COORDINATOR'
}

/** Signature for terms and conditions */
export type TermsSignature = {
  __typename?: 'TermsSignature';
  acceptedAt: Scalars['DateTime'];
  ipAddress?: Maybe<Scalars['String']>;
  termId: Scalars['ObjectId'];
};

export type TermsSignatureChecklistItem = SystemChecklistItem & {
  __typename?: 'TermsSignatureChecklistItem';
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

export type TokenizedPaymentMethodInput = {
  token: Scalars['String'];
};

/** Tour product with rooms, extensions, excursions 1-1 with Atlas Tour Division Spec */
export type Tour = PricedTripComponent & TripComponent & {
  __typename?: 'Tour';
  _id: Scalars['ObjectId'];
  activityLevel: Scalars['Int'];
  activityLevelContent?: Maybe<TourActivityLevelContent>;
  allowedStopoverGatewayCodes?: Maybe<Array<Scalars['String']>>;
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  classLevel: Scalars['Int'];
  classLevelContent?: Maybe<TourClassLevelContent>;
  content?: Maybe<TourContent>;
  defaultCapacity?: Maybe<Scalars['Int']>;
  departures: Array<Departure>;
  deposit: Amount;
  depositForAutomaticPlan: Amount;
  depositForLateAdd: Amount;
  endGateway?: Maybe<Gateway>;
  endGatewayCode: Scalars['String'];
  excursionIds: Array<Scalars['ObjectId']>;
  excursions: Array<TourExcursion>;
  extendedFamilyCode: Scalars['String'];
  extensionIds: Array<Scalars['ObjectId']>;
  extensions: Array<TourExtension>;
  familyCode: Scalars['String'];
  feeCategoryCode?: Maybe<Scalars['FeeCategoryCode']>;
  feePrice?: Maybe<Money>;
  fees: Array<Fee>;
  finalInstallmentDeadlineDpd: Scalars['Int'];
  finalPaymentDeadlineDpd: Scalars['Int'];
  hasPriceRules: Scalars['Boolean'];
  internalName: Scalars['String'];
  isFlightShoppingAllowed: Scalars['Boolean'];
  isNonRevenueTour: Scalars['Boolean'];
  isPassportRequired: Scalars['Boolean'];
  isPostStayAllowed: Scalars['Boolean'];
  isPreStayAllowed: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isVisaRequired: Scalars['Boolean'];
  isWaitlistAllowed: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  minimumCapacity?: Maybe<Scalars['Int']>;
  /** Returns the list of tour excursions that are valid for the given tour's spec but are not yet associated with it. */
  missingExcursions: Array<TourExcursion>;
  nbCruiseNights: Scalars['Int'];
  nbDepartureFlightDays: Scalars['Int'];
  nbHotelNights: Scalars['Int'];
  nbReturningFlightDays: Scalars['Int'];
  nbTourDays: Scalars['Int'];
  overnightCountries: Array<Country>;
  placeIds: Array<Scalars['ObjectId']>;
  places: Array<Place>;
  /** Places saved on the tour, including their resolved ancestors (e.g. parent places, grandparent places, etc.) */
  placesWithAncestry: Array<Place>;
  priceRules: Array<PriceRule>;
  priceYear: PriceYear;
  privateGroup?: Maybe<PrivateGroup>;
  /**
   * The url of the tour itinerary page on the website. This will be null if the tour is CTT. Use the GC Departure microsite url instead.
   * @deprecated Use publicWebsiteUrls instead
   */
  publicWebsiteUrl?: Maybe<Scalars['String']>;
  /** The url of the tour itinerary page on the website. This will be null if the tour is CTT. Use the GC Departure microsite url instead. */
  publicWebsiteUrls: TourPublicUrls;
  rooms: Array<TourRoom>;
  startGateway?: Maybe<Gateway>;
  startGatewayCode: Scalars['String'];
  status: TripComponentStatus;
  tagIds: Array<Scalars['ObjectId']>;
  tags: Array<Tag>;
  tourCode: Scalars['String'];
  tourDivisionSpecId: Scalars['Int'];
  tourSpecId: Scalars['Int'];
  tourType: TourType;
  tourTypeCode: TourTypeCode;
  type: TripComponentType;
};


/** Tour product with rooms, extensions, excursions 1-1 with Atlas Tour Division Spec */
export type TourActivityLevelContentArgs = {
  language: Scalars['String'];
};


/** Tour product with rooms, extensions, excursions 1-1 with Atlas Tour Division Spec */
export type TourClassLevelContentArgs = {
  language: Scalars['String'];
};


/** Tour product with rooms, extensions, excursions 1-1 with Atlas Tour Division Spec */
export type TourContentArgs = {
  language: Scalars['String'];
};


/** Tour product with rooms, extensions, excursions 1-1 with Atlas Tour Division Spec */
export type TourFeePriceArgs = {
  currencyCode: CurrencyCode;
  departureDate: Scalars['Date'];
  typeCode: FeeTypeCode;
};

/** Accommodation content of a Tour */
export type TourAccommodationContent = {
  __typename?: 'TourAccommodationContent';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<ContentAsset>;
  name?: Maybe<Scalars['String']>;
};

export type TourActivityLevelContent = {
  __typename?: 'TourActivityLevelContent';
  activityLevel?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<ContentAsset>;
  name?: Maybe<Scalars['String']>;
};

export type TourAssetUnion = TourImage | TourVideo;

export type TourBed = {
  __typename?: 'TourBed';
  capacity: Scalars['Int'];
  code: TourBedCode;
  count: Scalars['Int'];
  name: Scalars['String'];
};

export enum TourBedCode {
  /** Double */
  Db = 'DB',
  /** Family */
  Fa = 'FA',
  /** Quad */
  Qu = 'QU',
  /** Single */
  Si = 'SI',
  /** Student */
  St = 'ST',
  /** Twin */
  Tw = 'TW'
}

export type TourClassLevelContent = {
  __typename?: 'TourClassLevelContent';
  classLevel?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<ContentAsset>;
  name?: Maybe<Scalars['String']>;
  roomingDescription?: Maybe<Scalars['String']>;
  roomingDetailedDescription?: Maybe<Scalars['String']>;
  roomingUpgradeDescription?: Maybe<Scalars['String']>;
  tripBuilderSelectedMessage?: Maybe<Scalars['String']>;
};

/** Content describing a Tour for a specific year */
export type TourContent = {
  __typename?: 'TourContent';
  accommodationIds?: Maybe<Array<Scalars['String']>>;
  accommodations: Array<TourAccommodationContent>;
  assets: Array<TourAssetUnion>;
  badge?: Maybe<ProductBadgeContent>;
  badgeId?: Maybe<Scalars['String']>;
  businessCode?: Maybe<BusinessCode>;
  cardImage?: Maybe<ContentAsset>;
  /** Equipment offered on the tour */
  equipment?: Maybe<Array<TourEquipmentContent>>;
  equipmentDescription?: Maybe<Scalars['String']>;
  /** Summary of the types of equipment offered on the tour */
  equipmentSummary?: Maybe<TourDetailSummaryContent>;
  extension?: Maybe<TourExtensionContent>;
  extensionId?: Maybe<Scalars['String']>;
  /** Marketing FAQ content */
  faq?: Maybe<FaqContent>;
  faqId?: Maybe<Scalars['String']>;
  highlights: Array<TourHighlightContent>;
  id: Scalars['String'];
  includesBreakfast?: Maybe<Scalars['Boolean']>;
  inclusions: Array<TourHighlightContent>;
  itineraryItems: Array<TourItineraryElementUnion>;
  language?: Maybe<Scalars['String']>;
  mapImage?: Maybe<ContentAsset>;
  maximumGroupSize?: Maybe<Scalars['Int']>;
  minimumGroupSize?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nbCities?: Maybe<Scalars['Int']>;
  nbDays?: Maybe<Scalars['Int']>;
  nbDaysWithExtension?: Maybe<Scalars['Int']>;
  overviewDescription?: Maybe<Scalars['String']>;
  overviewTitle?: Maybe<Scalars['String']>;
  pace?: Maybe<Scalars['String']>;
  /** FAQ content for customers who are booked */
  postBookingFaq?: Maybe<FaqContent>;
  priceYear?: Maybe<Scalars['Int']>;
  publicDepartures: Array<Departure>;
  specialDeparturesIcon?: Maybe<ContentAsset>;
  tour?: Maybe<Tour>;
  tourCode?: Maybe<Scalars['String']>;
  tourPartnershipLogo?: Maybe<ContentAsset>;
};

/** Itinerary day content of a Tour */
export type TourDayContent = {
  __typename?: 'TourDayContent';
  description?: Maybe<Scalars['String']>;
  excursionIds: Array<Scalars['String']>;
  excursions?: Maybe<Array<TourExcursionContent>>;
  freeTimeSuggestions?: Maybe<Scalars['String']>;
  highlights?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  meals: Array<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  physicalActivity?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type TourDetailSummaryContent = {
  __typename?: 'TourDetailSummaryContent';
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Tour Director */
export type TourDirector = User & {
  __typename?: 'TourDirector';
  _id: Scalars['ObjectId'];
  bio?: Maybe<Scalars['String']>;
  /** Get the chat user ID for the Tour Director for the given business code */
  chatUserId?: Maybe<Scalars['String']>;
  /** The Departures with which the Tour Director is associated */
  departures?: Maybe<Array<Departure>>;
  /** Email, not returned to a customer if hidden by the TD */
  email?: Maybe<Scalars['Email']>;
  entraId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hideEmail?: Maybe<Scalars['Boolean']>;
  hidePhoneNumber?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  /** Phone number, not returned to a customer if hidden by the TD */
  phoneNumber?: Maybe<Scalars['PhoneNumber']>;
  /** The tour director's profile image asset */
  profileImageAsset?: Maybe<Asset>;
  supplierId: Scalars['Int'];
  tourDirectorId: Scalars['ObjectId'];
};


/** Tour Director */
export type TourDirectorChatUserIdArgs = {
  businessCode: BusinessCode;
};


/** Tour Director */
export type TourDirectorDeparturesArgs = {
  businessCodes?: InputMaybe<Array<BusinessCode>>;
};

/** Tour director annual evaluation summary */
export type TourDirectorAnnualEvaluationSummary = {
  __typename?: 'TourDirectorAnnualEvaluationSummary';
  /** The average percentage based on the average score and the maximum score */
  averagePercentage: Scalars['Float'];
  /** The average score based on all questions for a give year */
  averageScore: Scalars['Float'];
  /** Departure evaluation statistics */
  departureEvaluationSummaries: Array<DepartureEvaluationSummary>;
  /** List of departures */
  departures: Array<Departure>;
  /** List of score ranges, based on the average score of each departure */
  ranges: Array<EvaluationScoreRange>;
  /** Year of the evaluation */
  year: Scalars['String'];
};

export type TourDirectorCredentialOptions = {
  __typename?: 'TourDirectorCredentialOptions';
  hasEntra: Scalars['Boolean'];
};

/** Tour director evaluation summary */
export type TourDirectorEvaluationSummary = {
  __typename?: 'TourDirectorEvaluationSummary';
  /** List of annual evaluation summaries */
  annualEvaluationSummaries: Array<TourDirectorAnnualEvaluationSummary>;
  /** Tour director ID */
  tourDirectorId: Scalars['ObjectId'];
};

/** A paginated result of Tour Directors */
export type TourDirectorPaginatedResult = {
  __typename?: 'TourDirectorPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourDirector>;
};

export enum TourDirectorSortField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  SupplierId = 'supplierId'
}

/** Tour director tour end report */
export type TourDirectorTourEndReport = Evaluation & {
  __typename?: 'TourDirectorTourEndReport';
  _id: Scalars['ObjectId'];
  /** The date that the evaluation is available */
  availabilityDate: Scalars['DateTime'];
  businessCode?: Maybe<BusinessCode>;
  /** The date that the evaluation is due */
  deadlineDate: Scalars['DateTime'];
  /** The departure ID of the departure that this evaluation belong to */
  departureId: Scalars['ObjectId'];
  evaluationType: EvaluationType;
  /** The date that the evaluation was finalized */
  finalizationDate?: Maybe<Scalars['DateTime']>;
  hasAnsweredQuestions: Scalars['Boolean'];
  questions: Array<EvaluationQuestion>;
  /** The date that the evaluation was submitted */
  submissionDate?: Maybe<Scalars['DateTime']>;
  templateId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

/** Tour director tour end report settings */
export type TourDirectorTourEndReportSettings = {
  __typename?: 'TourDirectorTourEndReportSettings';
  /** the number of days before a departure or before a departure end that eval availabilityDate should be set */
  nbDaysUntilEvaluationAvailable?: Maybe<Scalars['Int']>;
  /** the number of days after a departure that an eval is due */
  nbDaysUntilEvaluationDue?: Maybe<Scalars['Int']>;
};

/** A paginated result of Tour Directors */
export type TourEndReportPaginatedResult = {
  __typename?: 'TourEndReportPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourDirectorTourEndReport>;
};

export enum TourEndReportSortField {
  AvailabilityDate = 'availabilityDate'
}

export type TourEquipmentContent = {
  __typename?: 'TourEquipmentContent';
  assets: Array<TourAssetUnion>;
  cardImage?: Maybe<ContentAsset>;
  description?: Maybe<Scalars['String']>;
  detailedName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
};

/** Excursion belonging to either a tour or extension */
export type TourExcursion = PricedTripComponent & TripComponent & {
  __typename?: 'TourExcursion';
  _id: Scalars['ObjectId'];
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  content?: Maybe<TourExcursionContent>;
  hasPriceRules: Scalars['Boolean'];
  internalName: Scalars['String'];
  /** Whether or not this excursion has a restricted purchase window. */
  isPrebook: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  matchCode: Scalars['String'];
  /** The minimum number of travelers required for this excursion to be confirmed. */
  minNbTravelersRequired: Scalars['Int'];
  nbTourExtensions: Scalars['Int'];
  nbTours: Scalars['Int'];
  placeIds: Array<Scalars['ObjectId']>;
  places: Array<Place>;
  priceRules: Array<PriceRule>;
  priceYear: PriceYear;
  status: TripComponentStatus;
  tagIds: Array<Scalars['ObjectId']>;
  tags: Array<Tag>;
  tourExtensions: Array<TourExtension>;
  tours: Array<Tour>;
  type: TripComponentType;
};


/** Excursion belonging to either a tour or extension */
export type TourExcursionContentArgs = {
  language: Scalars['String'];
};

/** Content describing a tour excursion */
export type TourExcursionContent = {
  __typename?: 'TourExcursionContent';
  departureInfo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<ContentAsset>;
  /** The lowest price for this excursion on a future, SELL departure. */
  lowestPrice?: Maybe<Money>;
  matchCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};


/** Content describing a tour excursion */
export type TourExcursionContentLowestPriceArgs = {
  currencyCode: CurrencyCode;
};

export type TourExcursionLineItem = LineItem & {
  __typename?: 'TourExcursionLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  departureExcursionId: Scalars['ObjectId'];
  excursionTourType: ExcursionTourType;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** A paginated result of Tours */
export type TourExcursionPaginatedResult = {
  __typename?: 'TourExcursionPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourExcursion>;
};

export enum TourExcursionSortField {
  AutoApproveDeadlineDpd = 'autoApproveDeadlineDpd',
  BasePriceCad = 'basePriceCAD',
  BasePriceUsd = 'basePriceUSD',
  InternalName = 'internalName',
  LineItemCode = 'lineItemCode',
  MatchCode = 'matchCode',
  MinNbTravelersRequired = 'minNbTravelersRequired',
  PriceYear = 'priceYear'
}

export type TourExcursionSpec = {
  __typename?: 'TourExcursionSpec';
  _id: Scalars['ObjectId'];
  businessCode: BusinessCode;
  internalName: Scalars['String'];
  matchCode: Scalars['String'];
  year: Scalars['Int'];
};

/** A paginated result of Tour Excursion Specs */
export type TourExcursionSpecPaginatedResult = {
  __typename?: 'TourExcursionSpecPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourExcursionSpec>;
};

/** Pre or Post extension */
export type TourExtension = PricedTripComponent & TripComponent & {
  __typename?: 'TourExtension';
  _id: Scalars['ObjectId'];
  autoApproveDeadlineDpd: Scalars['Int'];
  basePrice: Amount;
  businessCode?: Maybe<BusinessCode>;
  defaultCapacity: Scalars['Int'];
  endGatewayCode: Scalars['String'];
  excursionIds: Array<Scalars['ObjectId']>;
  excursions: Array<TourExcursion>;
  extendedFamilyCode: Scalars['String'];
  familyCode: Scalars['String'];
  hasPriceRules: Scalars['Boolean'];
  internalName: Scalars['String'];
  isFlightShoppingAllowed: Scalars['Boolean'];
  isPreExtension: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  minimumCapacity?: Maybe<Scalars['Int']>;
  /** Returns tue list of tour extension excursions that are valid for the given tour's extension spec but are not yet associated with it. */
  missingExcursions: Array<TourExcursion>;
  nbTourDays: Scalars['Int'];
  overnightCountries: Array<Country>;
  placeIds: Array<Scalars['ObjectId']>;
  places: Array<Place>;
  priceRules: Array<PriceRule>;
  priceYear: PriceYear;
  rooms: Array<TourRoom>;
  startGatewayCode: Scalars['String'];
  status: TripComponentStatus;
  tagIds: Array<Scalars['ObjectId']>;
  tags: Array<Tag>;
  tour?: Maybe<Tour>;
  tourCode: Scalars['String'];
  tourSpecId: Scalars['Int'];
  tourType: TourType;
  tourTypeCode: TourTypeCode;
  type: TripComponentType;
};

export type TourExtensionContent = {
  __typename?: 'TourExtensionContent';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<ContentAsset>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isPreExtension?: Maybe<Scalars['Boolean']>;
  itineraryItems: Array<TourItineraryElementUnion>;
  name?: Maybe<Scalars['String']>;
  nbDaysOverlap?: Maybe<Scalars['Int']>;
  nbNights?: Maybe<Scalars['Int']>;
  tourCode?: Maybe<Scalars['String']>;
};

/** Line item for the extension of a trip */
export type TourExtensionLineItem = LineItem & {
  __typename?: 'TourExtensionLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  departureDate: Scalars['Date'];
  departureExtensionId: Scalars['ObjectId'];
  endDate: Scalars['Date'];
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** A paginated result of Tours */
export type TourExtensionPaginatedResult = {
  __typename?: 'TourExtensionPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourExtension>;
};

export enum TourExtensionSortField {
  AutoApproveDeadlineDpd = 'autoApproveDeadlineDpd',
  BasePriceCad = 'basePriceCAD',
  BasePriceUsd = 'basePriceUSD',
  DefaultCapacity = 'defaultCapacity',
  EndGatewayCode = 'endGatewayCode',
  InternalName = 'internalName',
  LineItemCode = 'lineItemCode',
  NbTourDays = 'nbTourDays',
  PriceYear = 'priceYear',
  StartGatewayCode = 'startGatewayCode',
  TourCode = 'tourCode',
  TourType = 'tourType',
  TourTypeCode = 'tourTypeCode'
}

/** Highlight content of a Tour */
export type TourHighlightContent = {
  __typename?: 'TourHighlightContent';
  isPrintable?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

/** Image asset of a Tour */
export type TourImage = {
  __typename?: 'TourImage';
  caption?: Maybe<Scalars['String']>;
  image?: Maybe<ContentAsset>;
  kind: Scalars['String'];
};

export type TourItineraryElementUnion = TourDayContent | TourItineraryItemContent;

/** Itinerary item content of a Tour */
export type TourItineraryItemContent = {
  __typename?: 'TourItineraryItemContent';
  days: Array<TourDayContent>;
  kind: Scalars['String'];
  nbNights?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** Line item that represents the main tour */
export type TourLineItem = LineItem & {
  __typename?: 'TourLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  departureDate: Scalars['Date'];
  departureId: Scalars['ObjectId'];
  endDate: Scalars['Date'];
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

/** Content describing a tour page */
export type TourPageContent = {
  __typename?: 'TourPageContent';
  alternateYears: Array<TourPageContentAlternateYear>;
  breadcrumbParent?: Maybe<TourPageContentBreadcrumbParent>;
  breadcrumbParentId?: Maybe<Scalars['String']>;
  colorPalette?: Maybe<Scalars['String']>;
  fullSlug: Scalars['String'];
  id: Scalars['String'];
  isHiddenFromSearch?: Maybe<Scalars['Boolean']>;
  primaryMessageBody?: Maybe<Scalars['String']>;
  primaryMessageStatus?: Maybe<Scalars['String']>;
  primaryMessageTitle?: Maybe<Scalars['String']>;
  reviewAggregate: ReviewAggregate;
  specialDepartures?: Maybe<Array<ContentTextLink>>;
  tourCodes: Array<Scalars['String']>;
  tourContent?: Maybe<TourContent>;
  tourId?: Maybe<Scalars['String']>;
  tripBuilderContentId?: Maybe<Scalars['String']>;
  tripBuilderPriceHelpText?: Maybe<Scalars['String']>;
};


/** Content describing a tour page */
export type TourPageContentTourContentArgs = {
  alternateYearTourId?: InputMaybe<Scalars['String']>;
};

/** Alternate year for a tour page */
export type TourPageContentAlternateYear = {
  __typename?: 'TourPageContentAlternateYear';
  tourId?: Maybe<Scalars['String']>;
  yearLabel?: Maybe<Scalars['String']>;
};

/** Parent of tour page used to create its ancestry */
export type TourPageContentBreadcrumbParent = {
  __typename?: 'TourPageContentBreadcrumbParent';
  ancestors: Array<ContentAncestor>;
  fullSlug: Scalars['String'];
  name: Scalars['String'];
};

/** A paginated result of TourPageContent */
export type TourPagePaginatedResult = {
  __typename?: 'TourPagePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourPageContent>;
};

/** A paginated result of Tours */
export type TourPaginatedResult = {
  __typename?: 'TourPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Tour>;
};

export type TourPublicUrls = {
  __typename?: 'TourPublicUrls';
  ca?: Maybe<Scalars['String']>;
  us?: Maybe<Scalars['String']>;
};

/** Room belonging to either a tour or extension */
export type TourRoom = PricedTripComponent & TripComponent & {
  __typename?: 'TourRoom';
  _id: Scalars['ObjectId'];
  basePrice: Amount;
  bed: TourBed;
  businessCode?: Maybe<BusinessCode>;
  cabinCode?: Maybe<Scalars['TourCabinCode']>;
  defaultCapacity: Scalars['Int'];
  enableRoommateMatching: Scalars['Boolean'];
  hasPriceRules: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRule>;
  priceYear: PriceYear;
  status: TripComponentStatus;
  type: TripComponentType;
};

/** Rooming for main tour or extension */
export type TourRoomLineItem = LineItem & {
  __typename?: 'TourRoomLineItem';
  _id: Scalars['ObjectId'];
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationMeta?: Maybe<LineItemCancellationMeta>;
  isOpen: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  isQuoted: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  price: Money;
  rollsUpToTarget: Scalars['Boolean'];
  roomTourType: RoomTourType;
  roommate?: Maybe<Roommate>;
  status: LineItemStatus;
  targetLineItemId?: Maybe<Scalars['ObjectId']>;
  type: TripComponentType;
};

export enum TourSortField {
  AutoApproveDeadlineDpd = 'autoApproveDeadlineDpd',
  DefaultCapacity = 'defaultCapacity',
  FinalPaymentDeadlineDpd = 'finalPaymentDeadlineDpd',
  InternalName = 'internalName',
  LineItemCode = 'lineItemCode',
  NbCruiseNights = 'nbCruiseNights',
  NbDepartureFlightDays = 'nbDepartureFlightDays',
  NbHotelNights = 'nbHotelNights',
  NbReturningFlightDays = 'nbReturningFlightDays',
  NbTourDays = 'nbTourDays',
  PriceYear = 'priceYear',
  TourCode = 'tourCode',
  TourType = 'tourType',
  TourTypeCode = 'tourTypeCode'
}

export type TourSpec = {
  __typename?: 'TourSpec';
  _id: Scalars['ObjectId'];
  businessCode: BusinessCode;
  endGatewayCode: Scalars['String'];
  excursionMatchCodes: Array<Scalars['String']>;
  extendedFamilyCode: Scalars['String'];
  familyCode: Scalars['String'];
  internalName: Scalars['String'];
  isExtension: Scalars['Boolean'];
  nbCruiseNights: Scalars['Int'];
  nbHotelNights: Scalars['Int'];
  nbTourDays: Scalars['Int'];
  overnightCountryCodes: Array<Scalars['String']>;
  startGatewayCode: Scalars['String'];
  tourCode: Scalars['String'];
  tourDivisionSpecId: Scalars['Int'];
  tourSpecId: Scalars['Int'];
  typeCode: Scalars['String'];
  year: Scalars['Int'];
};

/** A paginated result of Tours */
export type TourSpecPaginatedResult = {
  __typename?: 'TourSpecPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TourSpec>;
};

export enum TourType {
  Cruise = 'CRUISE',
  Land = 'LAND'
}

export enum TourTypeCode {
  Bro = 'BRO',
  Ctt = 'CTT'
}

/** Video asset of a Tour */
export type TourVideo = {
  __typename?: 'TourVideo';
  caption?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  wistiaVideoId?: Maybe<Scalars['String']>;
};

export type TransactionPaymentMethod = AchPaymentMethod | AffirmPaymentMethod | CardPaymentMethod | CheckPaymentMethod | EfTransferPaymentMethod | TransferPaymentMethod | VoucherPaymentMethod;

/** Settings for transactional emails. */
export type TransactionalEmailSettings = {
  __typename?: 'TransactionalEmailSettings';
  campaignId: Scalars['Int'];
  description: Scalars['String'];
  internalCode: Scalars['String'];
  isActive: Scalars['Boolean'];
  nbNotificationDaysInAdvance?: Maybe<Scalars['Int']>;
};

export type TransferPaymentMethod = {
  __typename?: 'TransferPaymentMethod';
  destinationOrderId: Scalars['ObjectId'];
  reasonCode: TransferReasonCode;
  sourceOrderId: Scalars['ObjectId'];
  sourcePaymentMethod: TransactionPaymentMethod;
};

export type TransferPaymentMethodInput = {
  paymentMethodHash?: InputMaybe<Scalars['String']>;
  /** @deprecated Please begin using paymentMethodHash */
  savedPaymentMethodId?: InputMaybe<Scalars['ObjectId']>;
  transferFromBookingId: Scalars['ObjectId'];
};

/** The reason code for a transfer payment */
export enum TransferReasonCode {
  /** The transfer was the result of a fork. */
  Fork = 'FORK',
  /** The transfer was the result of a merge. */
  Merge = 'MERGE',
  /** The transfer was the result of a rebooking. */
  Rebooking = 'REBOOKING',
  /** The transfer was done adhoc. */
  Transfer = 'TRANSFER'
}

/** Transfer part of all of the balance from one WOJO Voucher to another */
export type TransferVoucherExternalInput = {
  /** The ID of the source voucher to transfer from */
  fromVoucherId: Scalars['ObjectId'];
  /** The product that the voucher is being transferred to */
  transferBusinessUnit: Scalars['String'];
};

export type TransferVoucherExternalResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

/** Transfer part of all of the balance from one WOJO Voucher to another */
export type TransferVoucherInternalInput = {
  /** The amount to transfer. Cannot exceed the balance of the source voucher */
  amount: MoneyInput;
  /** The ID of the source voucher to transfer from */
  fromVoucherId: Scalars['ObjectId'];
  /** The ID of the target voucher to transfer to */
  toVoucherId: Scalars['ObjectId'];
};

export type TransferVoucherInternalResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | TransferVoucherSuccess | ValidationError;

/** The result of a voucher transfer, consisting of the source and target vouchers */
export type TransferVoucherSuccess = {
  __typename?: 'TransferVoucherSuccess';
  /** The voucher which had its balance transferred */
  fromVoucher: Voucher;
  /** The voucher which the balance was transferred to */
  toVoucher: Voucher;
};

export type TravelerNote = {
  __typename?: 'TravelerNote';
  acknowledged: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type TravelerNoteInput = {
  acknowledged: Scalars['Boolean'];
  text?: InputMaybe<Scalars['String']>;
};

export type TravelerNotes = {
  __typename?: 'TravelerNotes';
  _id: Scalars['ObjectId'];
  dietaryNotes?: Maybe<TravelerNote>;
  generalNotes?: Maybe<TravelerNote>;
  hasCompletedHealthNotes?: Maybe<Scalars['Boolean']>;
  medicalNotes?: Maybe<TravelerNote>;
  mobilityNotes?: Maybe<TravelerNote>;
  roomingNotes?: Maybe<TravelerNote>;
};

export type TravelerNotesInput = {
  dietaryNotes?: InputMaybe<TravelerNoteInput>;
  generalNotes?: InputMaybe<TravelerNoteInput>;
  medicalNotes?: InputMaybe<TravelerNoteInput>;
  mobilityNotes?: InputMaybe<TravelerNoteInput>;
  roomingNotes?: InputMaybe<TravelerNoteInput>;
};

/** Base of QuoteTrip or BookingTrip */
export type Trip = {
  _id: Scalars['ObjectId'];
  /** The address for the trip. */
  address?: Maybe<Address>;
  businessCode?: Maybe<BusinessCode>;
  /** The currency code of the trip. */
  currencyCode: CurrencyCode;
  customer?: Maybe<Customer>;
  /** The customer id of the trip. */
  customerId?: Maybe<Scalars['ObjectId']>;
  customerType: TripCustomerType;
  departure?: Maybe<Departure>;
  departureDate: Scalars['Date'];
  /** The deposit amount for the trip. */
  depositAmount: Money;
  /** The emergency contacts for the trip. */
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  endDate: Scalars['Date'];
  /** Sum of all open excursion line items */
  excursionsTotal: Money;
  flightPackagePrice?: Maybe<Money>;
  grandTotal: Money;
  hasAddress: Scalars['Boolean'];
  hasDepartingFlightDeviation: Scalars['Boolean'];
  hasEmergencyContacts: Scalars['Boolean'];
  /** Whether the trip has an open line item for an extension */
  hasExtension: Scalars['Boolean'];
  hasReturningFlightDeviation: Scalars['Boolean'];
  hasTsaInformation: Scalars['Boolean'];
  /** Whether the trip is cancelled. */
  isCancelled: Scalars['Boolean'];
  /** Whether the trip is confirmed. */
  isConfirmed: Scalars['Boolean'];
  isLandOnly: Scalars['Boolean'];
  /** Whether the trip is open. */
  isOpen: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  /** Whether the trip is quoted. */
  isQuoted: Scalars['Boolean'];
  /** Whether the trip is rebooked. */
  isRebooked: Scalars['Boolean'];
  /** Whether the trip is requested. */
  isRequested: Scalars['Boolean'];
  /** Whether the trip is waitlisted. */
  isWaitlisted: Scalars['Boolean'];
  /** The line items that compose the trip. */
  lineItems: Array<LineItem>;
  /** The REBOOKED + OPEN line items if the trip is rebooked, the CANCELLED + OPEN line items with PARENT_TRIP_CANCELLED cancellation metadata if the trip is cancelled, or the open line items in all other cases.  */
  lineItemsMatchingTripStatus: Array<LineItem>;
  openAdjustmentLineItems: Array<AdjustmentLineItem>;
  openDepartingFlightLineItem?: Maybe<FlightPackageLineItem>;
  openExcursionLineItems: Array<TourExcursionLineItem>;
  openExtensionLineItem?: Maybe<TourExtensionLineItem>;
  openExtensionRoomLineItem?: Maybe<TourRoomLineItem>;
  openFeeLineItems: Array<FeeLineItem>;
  openInsuranceAddonLineItems: Array<InsuranceAddonLineItem>;
  openInsuranceLineItem?: Maybe<InsuranceLineItem>;
  /** The line items that compose the trip which are not CANCELLED or REBOOKED */
  openLineItems: Array<LineItem>;
  openPostStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPreStayLineItem?: Maybe<ExtendedStayLineItem>;
  openPrivateGroupLineItem?: Maybe<PrivateGroupLineItem>;
  openPromoLineItems: Array<PromoLineItem>;
  openReturningFlightLineItem?: Maybe<FlightPackageLineItem>;
  openRoomLineItem?: Maybe<TourRoomLineItem>;
  /** The trip ID for the trip that this trip was rebooked from. */
  rebookedFromTripId?: Maybe<Scalars['ObjectId']>;
  requestedRoommates?: Maybe<Array<Scalars['String']>>;
  requestedTravelCompanions?: Maybe<Array<Scalars['String']>>;
  /** The status of the trip */
  status: TripStatus;
  timingStatus: TripTimingStatus;
  tourLineItem?: Maybe<TourLineItem>;
  travelerNotes?: Maybe<TravelerNotes>;
  /** The TSA information for the trip. */
  tsaInformation?: Maybe<TsaInformation>;
};

export enum TripCancellationCategory {
  Financial = 'FINANCIAL',
  LifeEvent = 'LIFE_EVENT',
  ProductTransition = 'PRODUCT_TRANSITION',
  StaffOnTour = 'STAFF_ON_TOUR',
  TravelerDiscontent = 'TRAVELER_DISCONTENT',
  TravelRequirementNotMet = 'TRAVEL_REQUIREMENT_NOT_MET',
  Unknown = 'UNKNOWN',
  WojoCancellation = 'WOJO_CANCELLATION',
  WorldEvent = 'WORLD_EVENT'
}

/** Meta information for a trip cancellation */
export type TripCancellationMeta = {
  __typename?: 'TripCancellationMeta';
  cancellationCategory: TripCancellationCategory;
  cancellationDate: Scalars['DateTime'];
  cancellationReason: Scalars['String'];
  cancelledBy?: Maybe<Scalars['ObjectId']>;
};

export type TripChecklistItem = {
  __typename?: 'TripChecklistItem';
  checklistItemTemplateId: Scalars['ObjectId'];
  description: Scalars['String'];
  dueDate: Scalars['Date'];
  id: Scalars['String'];
  importanceLevel: CheckListItemImportanceLevel;
  internalName: Scalars['String'];
  isChecked: Scalars['Boolean'];
  status: TripChecklistItemStatus;
  title: Scalars['String'];
};

/** Checklist item state */
export type TripChecklistItemState = {
  __typename?: 'TripChecklistItemState';
  _id: Scalars['ObjectId'];
  checklistItemTemplateId: Scalars['ObjectId'];
  isChecked: Scalars['Boolean'];
  tripId: Scalars['ObjectId'];
};

export enum TripChecklistItemStatus {
  Complete = 'COMPLETE',
  DueInFuture = 'DUE_IN_FUTURE',
  Overdue = 'OVERDUE'
}

/** Template for items that appear on trip checklists */
export type TripChecklistItemTemplate = {
  __typename?: 'TripChecklistItemTemplate';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  description: Scalars['String'];
  dpd: Scalars['Int'];
  effectiveFrom: Scalars['Date'];
  effectiveTo: Scalars['Date'];
  excludeTourCodes?: Maybe<Array<Scalars['String']>>;
  importanceLevel: CheckListItemImportanceLevel;
  includeTourCodes?: Maybe<Array<Scalars['String']>>;
  internalName: Scalars['String'];
  title: Scalars['String'];
};

export type TripChecklistItemTemplatePaginatedResult = {
  __typename?: 'TripChecklistItemTemplatePaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<TripChecklistItemTemplate>;
};

/** Trip checklist settings */
export type TripChecklistSettings = {
  __typename?: 'TripChecklistSettings';
  emergencyContactDueAtDpd?: Maybe<Scalars['Int']>;
  healthNotesDueAtDpd?: Maybe<Scalars['Int']>;
  passportInformationDueAtDpd?: Maybe<Scalars['Int']>;
  termsSignatureDaysDueAfterBooking?: Maybe<Scalars['Int']>;
  /** @deprecated Use the tsa identifier and passport info settings */
  travelerInfoDueAtDpd?: Maybe<Scalars['Int']>;
  tsaIdentifiersDueAtDpd?: Maybe<Scalars['Int']>;
};

export type TripComponent = {
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  isPublished: Scalars['Boolean'];
  lineItemCode: Scalars['LineItemCode'];
  status: TripComponentStatus;
  type: TripComponentType;
};

export enum TripComponentStatus {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export enum TripComponentType {
  Adjustment = 'Adjustment',
  ExtendedInsurance = 'ExtendedInsurance',
  ExtendedStay = 'ExtendedStay',
  Fee = 'Fee',
  FlightPackage = 'FlightPackage',
  Insurance = 'Insurance',
  InsuranceAddon = 'InsuranceAddon',
  PrivateGroup = 'PrivateGroup',
  Promo = 'Promo',
  Tour = 'Tour',
  TourExcursion = 'TourExcursion',
  TourExtension = 'TourExtension',
  TourRoom = 'TourRoom'
}

export type TripCustomer = TripExistingCustomer | TripNewCustomer;

/** The type of customer on the trip. This is as it applies to the trip itself. For example, a GC traveling on their own not leading a group would be an Individual */
export enum TripCustomerType {
  /** This is the trip for the group coordinator. */
  GroupCoordinator = 'GROUP_COORDINATOR',
  /** A member of the group on the tour for a Group Coordinator */
  GroupMember = 'GROUP_MEMBER',
  /** A member of the tour who is not part of the group trip. This does not mean they are a solo traveler. */
  Individual = 'INDIVIDUAL'
}

export type TripExistingCustomer = {
  __typename?: 'TripExistingCustomer';
  customerId: Scalars['ObjectId'];
};

export type TripExistingCustomerInput = {
  customerId: Scalars['ObjectId'];
};

/** The reservation system used to book the PNR this flight belongs to. */
export enum TripFlightReservationSystem {
  /** The flight was booked and provided to World Journeys by Atlas, and was either booked by a staff member manually, or using another system such as the RightRez autobooker */
  Atlas = 'ATLAS',
  /** The flight was booked with Hitchhiker, a sales and service facing system used to help customers select their own flights from 2018-2023 */
  Hitchhiker = 'HITCHHIKER',
  /** The flight was booked with RightFlight, a RightRez product similar to Hitchhiker that allows customers to select their own flights */
  RightFlight = 'RIGHT_FLIGHT'
}

/** Represents an actualized flight with a flight number, departure and arrival gateways, etc. that is booked for a customer on a booking trip */
export type TripFlightSegment = {
  __typename?: 'TripFlightSegment';
  /** The record locator used to check in with the carrier corresponding to this flight segment's carrierCode */
  airlineLocator?: Maybe<Scalars['String']>;
  /** Additional information about the arrival gateway for this flight segment */
  arrivalGateway?: Maybe<Gateway>;
  /** The IATA airport code for the arrival gateway for this flight */
  arrivalGatewayCode: Scalars['String'];
  /** A string representing the time (in local airport time) that the flight arrives at the arrival gateway. This string should be displayed by converting it to a date with no time zone adjustment, before formatting */
  arrivesAtLocalTime: Scalars['String'];
  /** The cabin that was purchased when this flight was reserved. May not be populated depending on the booking system used, so should not be relied upon */
  cabinClass?: Maybe<Scalars['String']>;
  /** Ticketing carrier */
  carrier?: Maybe<Airline>;
  /** The primary airline for this flight */
  carrierCode: Scalars['String'];
  /** The class of service, which is an internal representation of the type of ticket being sold by the airline. If you are looking for ECONOMY, PREMIUM, BUSINESS, please see cabinClass instead */
  classOfService?: Maybe<Scalars['String']>;
  /** A string representing the time (in local airport time) that the flight departs from the departure gateway. This string should be displayed by converting it to a date with no time zone adjustment, before formatting */
  departsAtLocalTime: Scalars['String'];
  /** Additional information about the departure gateway for this flight segment */
  departureGateway?: Maybe<Gateway>;
  /** The IATA airport code for the departure gateway for this flight */
  departureGatewayCode: Scalars['String'];
  /** The flight number for this flight */
  flightNumber: Scalars['String'];
  /** Operating carrier */
  operatedByCarrier?: Maybe<Airline>;
  /** The carrier that is operating this flight in the case of a code share flight */
  operatedByCarrierCode?: Maybe<Scalars['String']>;
  /** The internal record locator issued by the ticketing airline */
  pnrLocator: Scalars['String'];
  /** An ordering tool used to sort flights that share the same pnrLocator. Ordering flights within the same pnrLocator by pnrSegmentNumber will be ordered chronologically, since time-based ordering cannot be done without UTC timestamps */
  pnrSegmentNumber: Scalars['Int'];
  /** The reservation system used to book the PNR that this flight belongs to */
  reservationSystem: TripFlightReservationSystem;
  /** The e-ticket number, populated once this PNR is ticketed. Will usually be nullish until much closer to the departure date */
  ticketNumber?: Maybe<Scalars['String']>;
  /** The type or 'direction' of the flight. Another way of saying, 'Along what leg of the journey does this flight take place' */
  type: TripFlightType;
};

export enum TripFlightType {
  /** The flight happens at the start of tour from the traveler's home gateway to begin their tour */
  Departing = 'DEPARTING',
  /** The flight is 'internal' to the tour, used to move travelers from one country to another as part of the tour's itinerary. e.g. CDG to FCO for a London, Paris, Rome tour */
  Internal = 'INTERNAL',
  /** The flight happens at the end of their tour back to their home gateway */
  Returning = 'RETURNING'
}

/** The top level object containing metadata and information about the flights on a booking trip */
export type TripFlights = {
  __typename?: 'TripFlights';
  _id: Scalars['ObjectId'];
  bookingTrip?: Maybe<BookingTrip>;
  /** The traveler's flights from their home gateway to begin their tour, sorted by pnrSegmentNumber and departure date, when possible */
  departingFlights: Array<TripFlightSegment>;
  /** The unfiltered list of all known flight segments known for this trip. If specific flights are needed, consider using another resolver. Sorted by pnrSegmentNumber and departure date, when possible */
  flightSegments?: Maybe<Array<TripFlightSegment>>;
  /** Returns true if this trip has at least one DEPARTING and at least one RETURNING flight segment */
  hasFullItinerary: Scalars['Boolean'];
  /** Flights that are 'internal' to the tour, used to move travelers from one country to another as part of the tour's itinerary, sorted by pnrSegmentNumber and departure date, when possible. e.g. CDG to FCO for a London, Paris, Rome tour. */
  internalFlights: Array<TripFlightSegment>;
  /** Returns true if every flight segment for this trip has been assigned a ticket number */
  isFullyTicketed: Scalars['Boolean'];
  /** The traveler's flights from the end of their tour back to their home gateway, sorted by pnrSegmentNumber and departure date, when possible */
  returningFlights: Array<TripFlightSegment>;
  /** The trip ID of the booking trip that these flights belong to */
  tripId: Scalars['ObjectId'];
};

/** A hotel belonging to a trip. */
export type TripHotel = {
  __typename?: 'TripHotel';
  /** The addresses of the hotel. Suppliers can have more than one address. */
  address?: Maybe<Array<Address>>;
  /** The end date of the hotel stay. */
  endDate: Scalars['Date'];
  /** The name of the hotel. */
  name?: Maybe<Scalars['String']>;
  /** The phone numbers of the hotel. Suppliers can have more than one phone number */
  phoneNumber?: Maybe<Array<Scalars['PhoneNumber']>>;
  /** The start date of the hotel stay. */
  startDate: Scalars['Date'];
  /** The type of the hotel. */
  type: TripHotelType;
  /** The website of the hotel. */
  website?: Maybe<Scalars['String']>;
};

export enum TripHotelType {
  Base = 'BASE',
  Layover = 'LAYOVER',
  Poststay = 'POSTSTAY',
  Prestay = 'PRESTAY'
}

export type TripNewCustomer = {
  __typename?: 'TripNewCustomer';
  email?: Maybe<Scalars['Email']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
};

export type TripNewCustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Meta information for a trip rebooking */
export type TripRebookingMeta = {
  __typename?: 'TripRebookingMeta';
  pendingRebookingDate: Scalars['DateTime'];
  rebookedBy?: Maybe<Scalars['ObjectId']>;
  rebookingDate?: Maybe<Scalars['DateTime']>;
  rebookingReasonCategory: RebookingReasonCategory;
  rebookingReasonDescription: Scalars['String'];
};

export enum TripStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  PendingCancellation = 'PENDING_CANCELLATION',
  PendingRebooking = 'PENDING_REBOOKING',
  Quoted = 'QUOTED',
  Rebooked = 'REBOOKED',
  Requested = 'REQUESTED',
  Waitlisted = 'WAITLISTED'
}

export enum TripTimingStatus {
  OnTour = 'OnTour',
  PostTour = 'PostTour',
  PreTour = 'PreTour'
}

export type TsaIdentifiersChecklistItem = SystemChecklistItem & {
  __typename?: 'TsaIdentifiersChecklistItem';
  dueDate?: Maybe<Scalars['Date']>;
  isCompleted: Scalars['Boolean'];
  status?: Maybe<TripChecklistItemStatus>;
};

export type TsaInformationInput = {
  confirmationDate?: InputMaybe<Scalars['DateTime']>;
  countryOfIssue?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<TsaGender>;
  identityDocumentNumber?: InputMaybe<Scalars['String']>;
  issueDate?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  stateOfIssue?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IdentityDocumentType>;
};

/** TSA Settings */
export type TsaSettings = {
  __typename?: 'TsaSettings';
  /** Number of years in the future relative to the current date for valid date range */
  yearsInTheFuture: Scalars['Int'];
  /** Number of years in the past relative to the current date for valid date range */
  yearsInThePast: Scalars['Int'];
};

/** A list of unassigned travelers and their requested room type */
export type UnassignedTravelers = {
  __typename?: 'UnassignedTravelers';
  requestedBedCode: Scalars['String'];
  trips: Array<BookingTrip>;
};

export type UncheckTripChecklistItemResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTripChecklistItemTemplateError | NotAuthorizedError | NotFoundError | TripChecklistItemState | ValidationError;

export type UnmatchRoommatesResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | UnmatchRoommatesSuccess | ValidationError;

export type UnmatchRoommatesSuccess = {
  __typename?: 'UnmatchRoommatesSuccess';
  data: Array<Booking>;
};

export type UpdateAdjustmentInput = {
  displayName: Scalars['String'];
  isCompanionDiscount: Scalars['Boolean'];
  isFreePlace: Scalars['Boolean'];
  isRefundable?: InputMaybe<Scalars['Boolean']>;
  isStaffOnTour: Scalars['Boolean'];
  persistsOnCancelAndRebook?: InputMaybe<Scalars['Boolean']>;
  tagIds: Array<Scalars['ObjectId']>;
};

export type UpdateAdjustmentResult = Adjustment | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateAdjustmentStatusInput = {
  status: TripComponentStatus;
};

export type UpdateAdjustmentStatusResult = Adjustment | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateBusinessSettingsInput = {
  departures?: InputMaybe<UpdateDepartureSettingsInput>;
  fees?: InputMaybe<UpdateFeeSettingsInput>;
  gcDepartures?: InputMaybe<UpdateGcDepartureSettingsInput>;
  insurance?: InputMaybe<UpdateInsuranceSettingsInput>;
  integrations?: InputMaybe<UpdateIntegrationSettingsInput>;
  orders?: InputMaybe<UpdateOrderSettingsInput>;
  promos?: InputMaybe<UpdatePromoSettingsInput>;
  reviews?: InputMaybe<UpdateReviewSettingsInput>;
  tripChecklists?: InputMaybe<UpdateTripChecklistSettingsInput>;
};

export type UpdateBusinessSettingsResult = Business | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Update the details of a campaign */
export type UpdateCampaignInput = {
  displayName?: InputMaybe<Scalars['String']>;
  effectiveFrom?: InputMaybe<Scalars['DateTime']>;
  effectiveTo?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCampaignResult = BusinessNotSpecifiedError | Campaign | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateCampaignStatusInput = {
  status: CampaignStatus;
};

export type UpdateCampaignStatusResult = BusinessNotSpecifiedError | Campaign | DuplicateUniqueKeyError | InvalidCampaignError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Update the basic details of a category */
export type UpdateCategoryInput = {
  /** Passing null, undefined, or empty string will remove the description */
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateCategoryResult = BusinessNotSpecifiedError | Category | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Passing null or undefined will set the address to null */
export type UpdateCustomerAddressInput = {
  address?: InputMaybe<AddressInput>;
};

export type UpdateCustomerAddressResult = BusinessNotSpecifiedError | Customer | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** You must provide a unique email address. */
export type UpdateCustomerEmailInput = {
  email: Scalars['Email'];
};

export type UpdateCustomerEmailResult = BusinessNotSpecifiedError | Customer | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Setting a last name to null or undefined will set it to null */
export type UpdateCustomerNameInput = {
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerNameResult = BusinessNotSpecifiedError | Customer | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Update customer password input */
export type UpdateCustomerPasswordInput = {
  oldPassword?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  reset?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCustomerPasswordResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidSigninError | NotAuthorizedError | NotFoundError | UpdatePasswordResult | ValidationError;

/** Passing null or undefined will set the phone to null */
export type UpdateCustomerPhoneNumberInput = {
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
};

export type UpdateCustomerPhoneNumberResult = BusinessNotSpecifiedError | Customer | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateCustomerProfileSocialMediaInput = {
  facebookUsername: Scalars['String'];
  instagramUsername: Scalars['String'];
  snapchatUsername: Scalars['String'];
  tiktokUsername: Scalars['String'];
  xUsername: Scalars['String'];
};

export type UpdateDepartureDocumentMetadataSettingsInput = {
  /** The type of document */
  documentType: DocumentType;
  /** The url path to download the document */
  downloadPath: Scalars['String'];
  /** The title of the document to be displayed by clients */
  title: Scalars['String'];
};

export type UpdateDepartureExcursionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  capacity?: InputMaybe<Scalars['Int']>;
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
};

export type UpdateDepartureExcursionResult = BusinessNotSpecifiedError | DepartureExcursion | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureExtensionExcursionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  /** The maximum number of travelers that can reserve this excursion type for this Departure. */
  capacity: Scalars['Int'];
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
};

export type UpdateDepartureExtensionExcursionResult = BusinessNotSpecifiedError | DepartureExcursion | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureExtensionInput = {
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  capacity?: InputMaybe<Scalars['Int']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDepartureExtensionResult = BusinessNotSpecifiedError | DepartureExtension | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureExtensionRoomInput = {
  /** The maximum number of travelers that can reserve this room type for this Departure. */
  capacity: Scalars['Int'];
};

export type UpdateDepartureExtensionRoomResult = BusinessNotSpecifiedError | DepartureRoom | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureInput = {
  addonPrice?: InputMaybe<Array<MoneyInput>>;
  autoApproveDeadline?: InputMaybe<Scalars['Date']>;
  capacity?: InputMaybe<Scalars['Int']>;
  exclusivityType?: InputMaybe<DepartureExclusivityType>;
  finalInstallmentDeadline?: InputMaybe<Scalars['Date']>;
  finalPaymentDeadline?: InputMaybe<Scalars['Date']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['ObjectId']>;
  reservedCapacity?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<DepartureStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateDepartureItemStatusInput = {
  _id: Scalars['ObjectId'];
  status: DepartureStatus;
};

export type UpdateDepartureItemStatusResult = BusinessNotSpecifiedError | Departure | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureResult = BusinessNotSpecifiedError | Departure | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureRoomInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<DepartureStatus>;
};

export type UpdateDepartureRoomResult = BusinessNotSpecifiedError | DepartureRoom | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDepartureSettingsInput = {
  /** List document metadata settings */
  documentMetadata?: InputMaybe<Array<UpdateDepartureDocumentMetadataSettingsInput>>;
  eTicketsAvailabilityDpd?: InputMaybe<Scalars['Int']>;
  flightsReleasedDpd?: InputMaybe<Scalars['Int']>;
  flightsRequestDpd?: InputMaybe<Scalars['Int']>;
  flightsRequestMessagingDpd?: InputMaybe<Scalars['Int']>;
  hotelsReleasedDpd?: InputMaybe<Scalars['Int']>;
  hotelsRequestDpd?: InputMaybe<Scalars['Int']>;
  publicDepartureMinDpd?: InputMaybe<Scalars['Int']>;
};

export type UpdateDraftBookingPaymentSchedulePaymentMethodResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPaymentError | InvalidPaymentScheduleError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftBookingPaymentScheduleResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | InvalidPaymentError | InvalidPaymentScheduleError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftBookingTripLineItemStatusesInput = {
  /** The business reason for the cancellation */
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  /** The line item code on the draft order to modify the status of. */
  lineItemId: Scalars['ObjectId'];
  /** The new line item status. */
  status: LineItemStatus;
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftBookingTripLineItemStatusesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftBookingTripStatusesInput = {
  status: TripStatus;
  /** The tripId on the draft order. */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftBookingTripStatusesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderAddressResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderAdjustmentPricesInput = {
  /** The adjustment lineItemId to update */
  adjustmentLineItemId: Scalars['ObjectId'];
  /** The adjustment amount. Will override the results of buildPrice(). */
  overrideAmount: Scalars['Float'];
  /** The trip to override the adjustment price on. */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderAdjustmentPricesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderAirportTransferInput = {
  arriving?: InputMaybe<AirportTransferInput>;
  returning?: InputMaybe<AirportTransferInput>;
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderAirportTransferResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderEmergencyContactsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderExtensionRoomsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidDepartureError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderFeePricesInput = {
  /** The fee lineItemId to update */
  feeLineItemId: Scalars['ObjectId'];
  /** The fee amount. Will override the results of buildPrice(). */
  overrideAmount: Scalars['Float'];
  /** The trip to override the fee price on. */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderFeePricesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderFinalInstallmentDeadlineResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderFinalPaymentDeadlineResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderFlightPackageInput = {
  /** The reason for updating the flights. */
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  /** Departing flight package input */
  departing: UpdateDraftOrderFlightPackageItemInput;
  /** Returning flight package input */
  returning: UpdateDraftOrderFlightPackageItemInput;
  /** Trip to set flight packages on */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderFlightPackageItemInput = {
  /**
   * Flight departure date. If an extension exists, it defaults to the extension's flight date, otherwise, it defaults to departure's corresponding flight date.
   * @deprecated Use requestedDate instead
   */
  departureDate?: InputMaybe<Scalars['Date']>;
  /** Flight package product code */
  lineItemCode: Scalars['LineItemCode'];
  /** Date to extend the request to. If departing sets the departure date and will default to earlier of the tour or extensions departure date. If returning, sets the end date and will default to the later of the tour or extensions end dates. */
  requestedDate?: InputMaybe<Scalars['Date']>;
  /** Stop over information */
  stopOver?: InputMaybe<StopOverInput>;
};

export type UpdateDraftOrderFlightPackagesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidFlightPackageError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderInsuranceResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderPromoPricesInput = {
  /** The promo amount. Will override the results of buildPrice(). */
  overrideAmount: Scalars['Float'];
  /** The promo lineItemId to update */
  promoLineItemId: Scalars['ObjectId'];
  /** The trip to override the fee price on. */
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderPromoPricesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderRequestedRoommatesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderRequestedTravelCompanionsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderRoomInput = {
  /** The reason for cancelling the room */
  cancellationReason?: InputMaybe<LineItemCancellationReason>;
  /** The lineItemCode of the room. */
  lineItemCode: Scalars['LineItemCode'];
  tripId: Scalars['ObjectId'];
};

export type UpdateDraftOrderRoomsResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderTravelerNotesResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateDraftOrderTsaInformationResult = BusinessNotSpecifiedError | DraftOrder | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | ValidationError;

/** Update a draft term */
export type UpdateDraftTermInput = {
  certificationText?: InputMaybe<Scalars['String']>;
  content: Scalars['JSON'];
  market: TermMarket;
  termType: TermType;
};

export type UpdateDraftTermResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | ImmutableTermError | InvalidDateError | NotAuthorizedError | NotFoundError | Term | ValidationError;

export type UpdateExtendedStayInput = {
  basePrice: Array<MoneyInput>;
};

export type UpdateExtendedStayResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | ExtendedStay | InvalidDateError | InvalidFlightPackageError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateExtendedStayStatusInput = {
  status: TripComponentStatus;
};

export type UpdateExtendedStayStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | ExtendedStay | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateFeeCalculationRuleInput = {
  /** Defines the maximum DPD value for this rule to match */
  maxDpd: Scalars['Int'];
  /** The strategy to use when calculating the fee */
  strategy: FeeCalculationStrategy;
  /** The percentage of the trip cost to use for the fee */
  tripPercent: Scalars['Int'];
};

export type UpdateFeeCancellationSettingsInput = {
  /** Category specific rules for calculating the fee */
  categorySpecificRules?: InputMaybe<Array<UpdateFeeCategorySpecificCalculationRuleInput>>;
  /** Default rules for calculating the fee */
  defaultRules?: InputMaybe<Array<UpdateFeeCalculationRuleInput>>;
};

export type UpdateFeeCategorySpecificCalculationRuleInput = {
  /** List of fee category codes that should use the calculation rules */
  categoryCodes: Array<Scalars['FeeCategoryCode']>;
  /** List of calculation rules for the category codes */
  rules: Array<UpdateFeeCalculationRuleInput>;
};

/** Update a fee */
export type UpdateFeeInput = {
  basePrice: Array<MoneyInput>;
  isRefundable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFeeResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | Fee | InvalidDateError | InvalidFeeError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateFeeSettingsInput = {
  /** Settings for calculating the cancellation fee amount. */
  cancellationSettings?: InputMaybe<UpdateFeeCancellationSettingsInput>;
  /** When using percentage cancellation fees and this is also set, uses the effective DPD based on all rebooked trips to determine the cancellation fee. */
  useLowestDpdInRebookingChain?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFeeStatusInput = {
  status: TripComponentStatus;
};

export type UpdateFeeStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | Fee | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateFlightPackageInput = {
  basePrice: Array<MoneyInput>;
  isCustomGateway: Scalars['Boolean'];
};

export type UpdateFlightPackageResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | FlightPackage | InvalidDateError | InvalidFlightPackageError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateFlightPackageStatusInput = {
  status: TripComponentStatus;
};

export type UpdateFlightPackageStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | FlightPackage | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Used to update the auto applied adjustments, or is passed as empty to remove the adjustments */
export type UpdateGcDepartureAdjustmentInput = {
  amount?: Array<MoneyInput>;
  lineItemCode: Scalars['LineItemCode'];
  price: MoneyInput;
};

export type UpdateGcDepartureInput = {
  /** The ID of the GC Departure to update */
  _id: Scalars['ObjectId'];
  /** The adjustments to apply to GM quotes for this GC departure */
  autoAppliedAdjustments?: InputMaybe<Array<UpdateGcDepartureAdjustmentInput>>;
  /** The percentage of the average program price that counts towards a companion discount per qualifying traveler. Limited to 2 decimal places */
  companionDiscountRate: Scalars['Float'];
  /** The expected volume for the GC Departure, used as a stretch goal to track progress by the GC */
  expectedVolume: Scalars['Int'];
  /** The rate at which the GC earns free places for insurance packages based on on the number of paying travelers. Expressed as an integer */
  freeInsuranceRatio: Scalars['FreePlaceRatio'];
  /** The rate at which the GC earns free places for the tour, extension, and excursions based on the number of paying travelers. Expressed as an integer */
  freePlaceRatio: Scalars['FreePlaceRatio'];
  /** An estimate of the number of travelers on the GC Departure, used by account managers to estimate things like the private group fee */
  groupSizeEstimate: Scalars['Int'];
  /** Whether or not trips on this GC Departure should be assessed the private group fee during reconciliation. */
  includePrivateGroupFee?: InputMaybe<Scalars['Boolean']>;
  /** The rules that determine the line items that are available to the GC for the GCDeparture */
  lineItemRules?: InputMaybe<Array<UpdateGcDepartureSettingsLineItemRuleInput>>;
  /** The URL of the microsite where GMs can quote and book */
  micrositeUrl?: InputMaybe<Scalars['String']>;
  /** The date of a recruitment meeting for the GC to promote the GCDeparture to potential group members */
  recruitmentMeetingDate?: InputMaybe<Scalars['Date']>;
  /** The percentage of the average program price that counts towards a GC stipend payment per qualifying traveler. Limited to 2 decimal places */
  stipendRate: Scalars['Float'];
};

/** Input for updating a gc departure prospect */
export type UpdateGcDepartureProspectInput = {
  _id: Scalars['ObjectId'];
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  levelOfInterest?: InputMaybe<GcDepartureProspectLevelOfInterest>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateGcDepartureProspectResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDepartureProspect | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateGcDepartureResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidAdjustmentError | InvalidDateError | InvalidMoneyError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateGcDepartureSettingsInput = {
  eTicketsAvailabilityDpd?: InputMaybe<Scalars['Int']>;
  flightsReleasedDpd?: InputMaybe<Scalars['Int']>;
  reconciliationDpd?: InputMaybe<Scalars['Int']>;
};

/** Used to update GC Departure Line Item Rules */
export type UpdateGcDepartureSettingsLineItemRuleInput = {
  level: GcDepartureRuleLevel;
  lineItemCode: Scalars['LineItemCode'];
  lineItemType: GcDepartureRuleLineItemType;
};

/** Input fields to set GC Departure Stipend detail */
export type UpdateGcDepartureStipendDetailInput = {
  /** The ID of the GC Departure to update */
  _id: Scalars['ObjectId'];
  /** The dollar amount of the check being issued */
  amount?: InputMaybe<MoneyInput>;
  /** A date to record the cancellation of the stipend benefit */
  cancelledDate?: InputMaybe<Scalars['Date']>;
  /** The check number of the issued check */
  checkNumber?: InputMaybe<Scalars['String']>;
  /** The date the check was issued */
  issuedDate?: InputMaybe<Scalars['Date']>;
  /** The current status of the stipend */
  status: GcDepartureStipendDetailStatus;
};

export type UpdateGcDepartureStipendDetailResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | GcDeparture | InvalidDateError | InvalidMoneyError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsuranceExtensionInput = {
  basePrice: Array<MoneyInput>;
};

export type UpdateInsuranceExtensionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsuranceExtension | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsuranceExtensionStatusInput = {
  status: TripComponentStatus;
};

export type UpdateInsuranceExtensionStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsuranceExtension | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsurancePlanAddonInput = {
  basePrice: Array<MoneyInput>;
  includedWithinDays?: InputMaybe<Scalars['Int']>;
  internalName: Scalars['String'];
  preventPurchaseAfterDays?: InputMaybe<Scalars['Int']>;
};

export type UpdateInsurancePlanAddonResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlanAddon | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsurancePlanAddonStatusInput = {
  _id: Scalars['ObjectId'];
  status: TripComponentStatus;
};

export type UpdateInsurancePlanAddonStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlanAddon | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsurancePlanInput = {
  basePrice: Array<MoneyInput>;
  internalName: Scalars['String'];
  isEligibleForRolloverOnRebooking?: InputMaybe<Scalars['Boolean']>;
  policyCoversPreExistingConditions?: InputMaybe<Scalars['Boolean']>;
  policyNumber: Scalars['String'];
  providerName: Scalars['String'];
  /** The insurance plan will become available starting from the specified number of days after the booking date */
  purchasableAfterDays?: InputMaybe<Scalars['Int']>;
  /** The insurance plan will be available during the quote process and for the specified number of days after booking */
  purchasableWithinDays?: InputMaybe<Scalars['Int']>;
};

export type UpdateInsurancePlanResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlan | InvalidDateError | InvalidInsurancePlanError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsurancePlanStatusInput = {
  status: TripComponentStatus;
};

export type UpdateInsurancePlanStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InsurancePlan | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | ValidationError;

export type UpdateInsuranceSettingsInput = {
  nbMaximumDaysToExtend?: InputMaybe<Scalars['Int']>;
  removalWindowDays?: InputMaybe<Scalars['Int']>;
};

export type UpdateIntegrationSettingsInput = {
  cybersourceMerchantIdCA?: InputMaybe<Scalars['String']>;
  cybersourceMerchantIdUS?: InputMaybe<Scalars['String']>;
  mailroom?: InputMaybe<UpdateMailroomSettingsInput>;
  micrositeUrlCA?: InputMaybe<Scalars['String']>;
  micrositeUrlUS?: InputMaybe<Scalars['String']>;
  productMarketCodeCA?: InputMaybe<Scalars['String']>;
  productMarketCodeUS?: InputMaybe<Scalars['String']>;
  rightFlightBrandIdCA?: InputMaybe<Scalars['String']>;
  rightFlightBrandIdUS?: InputMaybe<Scalars['String']>;
  transactionalEmails?: InputMaybe<Array<UpdateTransactionalEmailSettingsInput>>;
  websiteUrlCA?: InputMaybe<Scalars['String']>;
  websiteUrlUS?: InputMaybe<Scalars['String']>;
};

export type UpdateMailroomSettingsInput = {
  isActive: Scalars['Boolean'];
  nbPreDepartureMailDaysFrom: Scalars['Int'];
  nbPreDepartureMailDaysTo: Scalars['Int'];
  preDepartureMailMinimumBalance: Array<MoneyInput>;
};

export type UpdateNoteInput = {
  content: Scalars['String'];
};

export type UpdateNoteResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Note | ValidationError;

export type UpdateOrderSettingsInput = {
  achCheck?: InputMaybe<UpdatePaymentPlanSettingsInput>;
  bypassErrorOnProcessorDown?: InputMaybe<Scalars['Boolean']>;
  creditCard?: InputMaybe<UpdatePaymentPlanSettingsInput>;
  daysToMeetMinimumInstallments?: InputMaybe<Scalars['Int']>;
  debitCard?: InputMaybe<UpdatePaymentPlanSettingsInput>;
  departurePriorityWindowDpd?: InputMaybe<Scalars['Int']>;
  earliestPaymentScheduleStartDays?: InputMaybe<Scalars['Int']>;
  emergencyContactSelfServiceDeadlineDpd?: InputMaybe<Scalars['Int']>;
  expiresInMinutes?: InputMaybe<Scalars['Int']>;
  healthNotesSelfServiceDeadlineDpd?: InputMaybe<Scalars['Int']>;
  latestPaymentScheduleStartDays?: InputMaybe<Scalars['Int']>;
  maximumPerTripPromotionAmount?: InputMaybe<Array<MoneyInput>>;
  nbMaximumAutopayAttempts?: InputMaybe<Scalars['Int']>;
  passportInformationSelfServiceDeadlineDpd?: InputMaybe<Scalars['Int']>;
  physicalCheck?: InputMaybe<UpdatePaymentPlanSettingsInput>;
  riskFreePeriod?: InputMaybe<UpdateRiskFreePeriodSettingsInput>;
  tsaIdentifiersSelfServiceDeadlineDpd?: InputMaybe<Scalars['Int']>;
};

export type UpdatePasswordResult = {
  __typename?: 'UpdatePasswordResult';
  success: Scalars['Boolean'];
};

export type UpdatePaymentPlanSettingsInput = {
  isAutopayAllowed?: InputMaybe<Scalars['Boolean']>;
  isManualAllowed?: InputMaybe<Scalars['Boolean']>;
};

/** Update a private group */
export type UpdatePrivateGroupInput = {
  basePrice: Array<MoneyInput>;
};

export type UpdatePrivateGroupResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type UpdatePrivateGroupStatusInput = {
  status: TripComponentStatus;
};

export type UpdatePrivateGroupStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPrivateGroupError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

/** Update the details of a promo */
export type UpdatePromoInput = {
  displayName: Scalars['String'];
  effectiveFrom: Scalars['DateTime'];
  effectiveTo: Scalars['DateTime'];
  exclusivity: PromoExclusivity;
  isGroupsOnly?: Scalars['Boolean'];
  isHiddenFromSales?: Scalars['Boolean'];
  isIndiesOnly?: Scalars['Boolean'];
  isShareable?: Scalars['Boolean'];
  maxRedemptions?: InputMaybe<Scalars['Int']>;
  promoCategory?: InputMaybe<PromoCategory>;
};

export type UpdatePromoResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type UpdatePromoSettingsInput = {
  /** Promo code to use for the advanced repeat promo feature */
  advancedRepeatPromoCode?: InputMaybe<Scalars['String']>;
  /** Customer facing name for the advanced repeat promo */
  advancedRepeatPromoName?: InputMaybe<Scalars['String']>;
  /** List of trip thresholds for higher repeat promo code discounts */
  repeatThresholds?: InputMaybe<Array<UpdateRepeatThresholdSettingsInput>>;
  /** List of reasons for service recovery promotions. */
  serviceRecoveryReasons?: InputMaybe<Array<Scalars['String']>>;
  /** Enable advanced behavior for the REPEAT promo code. */
  useAdvancedRepeatPromo?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePromoStatusInput = {
  status: TripComponentStatus;
};

export type UpdatePromoStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

/** Update the targets of a promo */
export type UpdatePromoTargetsInput = {
  targets: Array<CreatePromoTargetInput>;
};

export type UpdatePromoTargetsResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Promo | ValidationError;

export type UpdateQuoteExpiryInput = {
  expirationDate: Scalars['DateTime'];
};

export type UpdateQuoteExpiryResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidOrderError | NotAuthorizedError | NotFoundError | Quote | ValidationError;

export type UpdateRepeatThresholdSettingsInput = {
  discount: Array<MoneyInput>;
  /** Number of trips required for discount */
  nbTrips: Scalars['Int'];
  /** Additional amount to discount if applied before repeatBonusExpiresAfterDays */
  repeatBonus: Array<MoneyInput>;
  /** Number of days after the last trip to earn the repeat bonus */
  repeatBonusExpiresAfterDays: Scalars['Int'];
};

/** Updates a review detail */
export type UpdateReviewDetailInput = {
  author?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateReviewDetailResponseInput = {
  author: Scalars['String'];
  body: Scalars['String'];
};

export type UpdateReviewDetailResponseResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidRatingError | InvalidReviewError | InvalidReviewResponseError | NotAuthorizedError | NotFoundError | Review | ValidationError;

export type UpdateReviewDetailStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidRatingError | InvalidReviewError | InvalidReviewResponseError | NotAuthorizedError | NotFoundError | Review | ValidationError;

export type UpdateReviewImagesStatusInput = {
  imageId: Scalars['ObjectId'];
  status: ReviewStatus;
};

export type UpdateReviewImagesStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidReviewError | NotAuthorizedError | NotFoundError | Review | ValidationError;

/** Update a review input. Passing null or undefined will ignore the update */
export type UpdateReviewInput = {
  detail?: InputMaybe<UpdateReviewDetailInput>;
  rating: Scalars['Int'];
};

export type UpdateReviewResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidRatingError | InvalidReviewError | InvalidReviewResponseError | NotAuthorizedError | NotFoundError | Review | ValidationError;

export type UpdateReviewSettingsInput = {
  /** Number of days after the departure that reviews are allowed to be submitted */
  reviewCutoffDays?: InputMaybe<Scalars['Int']>;
};

export type UpdateRiskFreePeriodSettingsInput = {
  minimumEligibleDpd?: InputMaybe<Scalars['Int']>;
  nbAutopayDays?: InputMaybe<Scalars['Int']>;
  nbManualDays?: InputMaybe<Scalars['Int']>;
  nbPayInFullDays?: InputMaybe<Scalars['Int']>;
  preserveRiskFreePeriodOnRebooking?: InputMaybe<Scalars['Boolean']>;
  useMinimumEligibleDpd?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRoleInput = {
  name: Scalars['String'];
  permissionKeys: Array<PermissionKey>;
};

export type UpdateRoleResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Role | ValidationError;

/** Update the details of a service recovery promo. */
export type UpdateServiceRecoveryPromoInput = {
  expiryDate: Scalars['DateTime'];
  recoveryReason: Scalars['String'];
};

export type UpdateServiceRecoveryPromoResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ServiceRecovery | ValidationError;

export type UpdateServiceRecoveryPromoStatusInput = {
  status: TripComponentStatus;
};

export type UpdateServiceRecoveryPromoStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ServiceRecovery | ValidationError;

/** Update the basic details of a tag */
export type UpdateTagInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateTagResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | Tag | ValidationError;

export type UpdateTourExcursionInput = {
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  /** The minimum number of travelers required for this excursion to be confirmed. */
  minNbTravelersRequired?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateTourExcursionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | TourExcursion | ValidationError;

export type UpdateTourExcursionStatusInput = {
  status: TripComponentStatus;
};

export type UpdateTourExcursionStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | TourExcursion | ValidationError;

export type UpdateTourExtensionInput = {
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  /** The default capacity that will set for departures created from this Extension. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreExtension?: InputMaybe<Scalars['Boolean']>;
  minimumCapacity?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateTourExtensionResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourExtension | ValidationError;

export type UpdateTourExtensionRoomInput = {
  /** The _id of the tour extension room to update. */
  _id: Scalars['ObjectId'];
  basePrice?: InputMaybe<Array<MoneyInput>>;
  bedCode?: InputMaybe<TourBedCode>;
  cabinCode?: InputMaybe<Scalars['TourCabinCode']>;
  /** The capacity that will set for Departure Rooms created from this Tour Room.  */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type UpdateTourExtensionRoomResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

export type UpdateTourExtensionRoomStatusInput = {
  /** The _id of the tour extension room to update. */
  _id: Scalars['ObjectId'];
  status: TripComponentStatus;
};

export type UpdateTourExtensionRoomStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

export type UpdateTourExtensionStatusInput = {
  /** Include all the extension rooms statuses */
  includeRooms?: Scalars['Boolean'];
  status: TripComponentStatus;
};

export type UpdateTourExtensionStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | TourExtension | ValidationError;

export type UpdateTourInput = {
  activityLevel?: InputMaybe<Scalars['Int']>;
  autoApproveDeadlineDpd?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Array<MoneyInput>>;
  classLevel?: InputMaybe<Scalars['Int']>;
  /** The default capacity that will set for Departures created from this Tour. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  deposit?: InputMaybe<Array<MoneyInput>>;
  depositForAutomaticPlan?: InputMaybe<Array<MoneyInput>>;
  depositForLateAdd?: InputMaybe<Array<MoneyInput>>;
  feeCategoryCode?: InputMaybe<Scalars['FeeCategoryCode']>;
  finalInstallmentDeadlineDpd?: InputMaybe<Scalars['Int']>;
  finalPaymentDeadlineDpd?: InputMaybe<Scalars['Int']>;
  isFlightShoppingAllowed?: InputMaybe<Scalars['Boolean']>;
  isNonRevenueTour?: InputMaybe<Scalars['Boolean']>;
  isPassportRequired?: InputMaybe<Scalars['Boolean']>;
  isPostStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isPreStayAllowed?: InputMaybe<Scalars['Boolean']>;
  isVisaRequired?: InputMaybe<Scalars['Boolean']>;
  isWaitlistAllowed?: InputMaybe<Scalars['Boolean']>;
  minimumCapacity?: InputMaybe<Scalars['Int']>;
  nbDepartureFlightDays?: InputMaybe<Scalars['Int']>;
  nbReturningFlightDays?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  status?: InputMaybe<TripComponentStatus>;
  tagIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateTourPricingInput = {
  lineItemCode: Scalars['LineItemCode'];
  priceRules: Array<PriceRuleInput>;
};

export type UpdateTourPricingResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPriceYearError | InvalidPricingError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type UpdateTourProductStatusInput = {
  includeChildProducts?: Scalars['Boolean'];
  lineItemCode: Scalars['String'];
  status: TripComponentStatus;
};

export type UpdateTourResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type UpdateTourRoomDetailsInput = {
  /** The default capacity that will set for Departures Rooms created from this Tour Room. Defaults to the Tour capacity or Tour Extension capacity if the extensionLineItemCode was provided. */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
};

export type UpdateTourRoomInput = {
  /** The _id of the tour room to update. */
  _id: Scalars['ObjectId'];
  basePrice?: InputMaybe<Array<MoneyInput>>;
  /** The capacity that will set for Departure Rooms created from this Tour Room.  */
  defaultCapacity?: InputMaybe<Scalars['Int']>;
  /** Whether or not roommate matching is needed for this room */
  enableRoommateMatching?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TripComponentStatus>;
};

export type UpdateTourRoomResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTourError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

export type UpdateTourRoomStatusInput = {
  /** The _id of the tour room to update. */
  _id: Scalars['ObjectId'];
  status: TripComponentStatus;
};

export type UpdateTourRoomStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | TourRoom | ValidationError;

export type UpdateTourStatusInput = {
  /** Include all the tour rooms */
  includeRooms?: Scalars['Boolean'];
  status: TripComponentStatus;
};

export type UpdateTourStatusResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidPricingError | NotAuthorizedError | NotFoundError | Tour | ValidationError;

export type UpdateTransactionalEmailSettingsInput = {
  campaignId: Scalars['Int'];
  description: Scalars['String'];
  internalCode: Scalars['String'];
  isActive: Scalars['Boolean'];
  nbNotificationDaysInAdvance?: InputMaybe<Scalars['Int']>;
};

export type UpdateTripChecklistItemTemplateInput = {
  description?: InputMaybe<Scalars['String']>;
  dpd?: InputMaybe<Scalars['Int']>;
  effectiveFrom?: InputMaybe<Scalars['Date']>;
  effectiveTo?: InputMaybe<Scalars['Date']>;
  excludeTourCodes?: InputMaybe<Array<Scalars['String']>>;
  importanceLevel?: InputMaybe<CheckListItemImportanceLevel>;
  includeTourCodes?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTripChecklistItemTemplateResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidTripChecklistItemTemplateError | NotAuthorizedError | NotFoundError | TripChecklistItemTemplate | ValidationError;

export type UpdateTripChecklistSettingsInput = {
  emergencyContactDueAtDpd?: InputMaybe<Scalars['Int']>;
  healthNotesDueAtDpd?: InputMaybe<Scalars['Int']>;
  passportInformationDueAtDpd?: InputMaybe<Scalars['Int']>;
  termsSignatureDaysDueAfterBooking?: InputMaybe<Scalars['Int']>;
  tsaIdentifiersDueAtDpd?: InputMaybe<Scalars['Int']>;
};

export type UpdateVoucherRefundDetailInput = {
  /** The ID of the Voucher */
  _id: Scalars['ObjectId'];
  /** The date the check was issued */
  checkIssuedOn?: InputMaybe<Scalars['Date']>;
  /** The check number */
  checkNumber?: InputMaybe<Scalars['String']>;
  /** Information about the employee who approved the refund check */
  issuedBy?: InputMaybe<Scalars['String']>;
  /** The reason for the refund */
  reason?: InputMaybe<Scalars['String']>;
  /** The ID of the VoucherRefundedTransaction to edit on this Voucher */
  refundTransactionId: Scalars['ObjectId'];
};

export type UpdateVoucherRefundDetailResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

/** A user */
export type User = {
  _id: Scalars['ObjectId'];
};

/** The input parameters are invalid. */
export type ValidationError = DomainError & {
  __typename?: 'ValidationError';
  allErrors: Array<Scalars['String']>;
  code: Scalars['String'];
  entityId?: Maybe<Scalars['ObjectId']>;
  entityName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type VoidBookingTransferPaymentResult = Booking | BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | NotAuthorizedError | NotFoundError | ValidationError;

/** Voids a non-expired voucher */
export type VoidVoucherInput = {
  /** The ID of the Voucher */
  _id: Scalars['ObjectId'];
  /** The reason that the voucher is being voided */
  reason: Scalars['String'];
};

export type VoidVoucherResult = BusinessNotSpecifiedError | DuplicateUniqueKeyError | InvalidDateError | InvalidVoucherError | NotAuthorizedError | NotFoundError | ValidationError | Voucher;

/** A voucher which contains its rules and transactions */
export type Voucher = {
  __typename?: 'Voucher';
  _id: Scalars['ObjectId'];
  balance: Money;
  booking?: Maybe<Booking>;
  businessCode?: Maybe<BusinessCode>;
  customer?: Maybe<Customer>;
  customerId: Scalars['ObjectId'];
  expirationDate: Scalars['Date'];
  /** Whether the voucher has at least one refund transaction */
  hasRefunds: Scalars['Boolean'];
  isExternal: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  issueDate: Scalars['Date'];
  orderId?: Maybe<Scalars['ObjectId']>;
  reason: VoucherReason;
  status: VoucherStatus;
  transactions: Array<VoucherTransaction>;
  transferBusinessUnit?: Maybe<Scalars['String']>;
};

/** Arbitrary increase of voucher value, usually associated with correcting balance issues */
export type VoucherIncreasedTransaction = VoucherTransaction & {
  __typename?: 'VoucherIncreasedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  approvedBy?: Maybe<Scalars['ObjectId']>;
  reason: Scalars['String'];
  transactionDate: Scalars['DateTime'];
};

/** The initial issuance transaction when the voucher is first created */
export type VoucherIssuedTransaction = VoucherTransaction & {
  __typename?: 'VoucherIssuedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  orderId: Scalars['ObjectId'];
  reason: Scalars['String'];
  transactionDate: Scalars['DateTime'];
};

/** A paginated set of Vouchers and their transactions */
export type VoucherPaginatedResult = {
  __typename?: 'VoucherPaginatedResult';
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<Voucher>;
};

export type VoucherPaymentMethod = {
  __typename?: 'VoucherPaymentMethod';
  isRefundable: Scalars['Boolean'];
  voucher?: Maybe<Voucher>;
  voucherId: Scalars['ObjectId'];
  voucherTransactionId?: Maybe<Scalars['ObjectId']>;
};

export type VoucherPaymentMethodInput = {
  voucherId: Scalars['ObjectId'];
};

/** Why the voucher was issued */
export enum VoucherReason {
  AnyReasonProtection = 'ANY_REASON_PROTECTION',
  ExternalTransfer = 'EXTERNAL_TRANSFER',
  Other = 'OTHER',
  SevereFlightDisruption = 'SEVERE_FLIGHT_DISRUPTION',
  WorldEvents = 'WORLD_EVENTS'
}

/** Logging that a voucher has been used against an order */
export type VoucherRedeemedTransaction = VoucherTransaction & {
  __typename?: 'VoucherRedeemedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  orderId: Scalars['ObjectId'];
  transactionDate: Scalars['DateTime'];
};

/** Arbitrary reduction used to correct balances */
export type VoucherReducedTransaction = VoucherTransaction & {
  __typename?: 'VoucherReducedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  approvedBy?: Maybe<Scalars['ObjectId']>;
  reason: Scalars['String'];
  transactionDate: Scalars['DateTime'];
};

export type VoucherRefundInput = {
  amount: Scalars['Float'];
  voucherId: Scalars['ObjectId'];
};

/** When a voucher's balance is refunded back to the customer */
export type VoucherRefundedTransaction = VoucherTransaction & {
  __typename?: 'VoucherRefundedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  /** The ID of the employee who recorded the refund */
  approvedBy?: Maybe<Scalars['ObjectId']>;
  /** @deprecated Deprecated, source data does not contain time information. Use checkIssuedOn instead */
  checkIssuedAt?: Maybe<Scalars['DateTime']>;
  /** The date the check was issued */
  checkIssuedOn?: Maybe<Scalars['Date']>;
  /** The check number */
  checkNumber?: Maybe<Scalars['String']>;
  /** Information about the employee or team who issued or approved the refund check */
  issuedBy?: Maybe<Scalars['String']>;
  /** The reason for the refund */
  reason?: Maybe<Scalars['String']>;
  transactionDate: Scalars['DateTime'];
};

export enum VoucherSortField {
  ExpirationDate = 'expirationDate'
}

export enum VoucherStatus {
  Approved = 'APPROVED',
  Voided = 'VOIDED'
}

/** Base type for all voucher transactions */
export type VoucherTransaction = {
  _id: Scalars['ObjectId'];
  amount: Money;
  transactionDate: Scalars['DateTime'];
};

export enum VoucherTransferDirection {
  In = 'IN',
  Out = 'OUT'
}

/** Used when a voucher is transferred from or to a non WOJO EF product */
export type VoucherTransferExternalTransaction = VoucherTransaction & {
  __typename?: 'VoucherTransferExternalTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  approvedBy?: Maybe<Scalars['ObjectId']>;
  direction: VoucherTransferDirection;
  transactionDate: Scalars['DateTime'];
  transferBusinessUnit: Scalars['String'];
};

/** When a voucher is transferred to another customer within WOJO */
export type VoucherTransferInternalTransaction = VoucherTransaction & {
  __typename?: 'VoucherTransferInternalTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  approvedBy?: Maybe<Scalars['ObjectId']>;
  transactionDate: Scalars['DateTime'];
  transferVoucherId: Scalars['ObjectId'];
};

/** When the voucher is voided, this transaction is clearing the balance */
export type VoucherVoidedTransaction = VoucherTransaction & {
  __typename?: 'VoucherVoidedTransaction';
  _id: Scalars['ObjectId'];
  amount: Money;
  approvedBy?: Maybe<Scalars['ObjectId']>;
  reason: Scalars['String'];
  transactionDate: Scalars['DateTime'];
};

/** A Tour that a Customer has added to their Wishlist */
export type WishlistItem = {
  __typename?: 'WishlistItem';
  _id: Scalars['ObjectId'];
  businessCode?: Maybe<BusinessCode>;
  customerId: Scalars['ObjectId'];
  tourCode: Scalars['String'];
};

export type WishlistItemRemovedResult = {
  __typename?: 'WishlistItemRemovedResult';
  removedId: Scalars['ObjectId'];
};
