import { Response, Ticket } from "@hooks/useTicket";
import { ZendeskAppTripMapper } from "@hooks/ZendeskAppTripMapper";
import dayjs from "dayjs";
import { BookingTripByLegacyIdDocument } from "@graphql/queries/generated/BookingTripByLegacyIdQuery";
import { ZafClient } from "@localtypes/zafTypes";
import { ZafGraphqlClient } from "@graphql/zaf-graphql-client";
import { BookingTrip } from "@localtypes/generated-gql";

/**
 * A hook that updates custom ticket fields and tags on a ticket. Only works in the ticket sidebar location
 */
export const updateTicketFields = async (
    zafClient: ZafClient | undefined,
    zafGraphqlClient: ZafGraphqlClient | undefined,
    requesterTripId: string,
) => {
    const ticketResponse: null | Response =
        (await zafClient?.get("ticket")) ?? null;
    const ticket: null | Ticket = ticketResponse?.ticket ?? null;

    if (!ticket) {
        return null;
    }

    const bookingTripResponse = await zafGraphqlClient?.execute<{
        bookingTripByLegacyId: BookingTrip;
    }>(BookingTripByLegacyIdDocument, { id: requesterTripId });
    const customerTrip = ZendeskAppTripMapper.map(
        bookingTripResponse?.bookingTripByLegacyId ?? null,
    );

    if (!customerTrip) {
        return null;
    }

    return zafClient?.request({
        url: `/api/v2/tickets/${ticket?.id}.json`,
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
            ticket: {
                custom_fields: [
                    {
                        id: import.meta.env.VITE_TRIP_ID_FIELD_ID,
                        value: customerTrip?.id ?? "",
                    },
                    {
                        id: import.meta.env.VITE_TOUR_CODE_FIELD_ID,
                        value: customerTrip?.departure?.tourCode ?? "",
                    },
                    {
                        id: import.meta.env.VITE_CUSTOMER_ID_FIELD_ID,
                        value: customerTrip?.customer?.id ?? "",
                    },
                    {
                        id: import.meta.env.VITE_CUSTOMER_TYPE_FIELD_ID,
                        value:
                            customerTrip.customerType === "INDIVIDUAL"
                                ? "indies"
                                : customerTrip.customerType ===
                                  "GROUP_COORDINATOR"
                                ? "group_coordinator"
                                : "group_member",
                    },
                    {
                        id: import.meta.env.VITE_SALES_TOUR_ID_FIELD_ID,
                        value: customerTrip?.departure?.atlasSalesTourId ?? "",
                    },
                    {
                        id: import.meta.env.VITE_PROD_TOUR_ID_FIELD_ID,
                        value: customerTrip?.departure?.atlasProdTourId ?? "",
                    },
                    {
                        id: import.meta.env.VITE_DEPARTURE_DATE_FIELD_ID,
                        value: customerTrip?.departure?.departureDate
                            ? dayjs
                                  .utc(customerTrip.departure?.departureDate)
                                  .format("YYYY-MM-DD")
                            : "",
                    },
                    {
                        id: import.meta.env.VITE_MARKET_FIELD_ID,
                        value:
                            customerTrip.currencyCode === "USD"
                                ? "market_us"
                                : "market_ca",
                    },
                ],
                tags: ticket.tags
                    ? [
                          ...ticket.tags.filter(
                              (tag) =>
                                  !tag.startsWith("tour_code") &&
                                  !tag.startsWith("prod_tour_id"),
                          ),
                          customerTrip?.departure?.tourCode
                              ? `tour_code:${customerTrip?.departure?.tourCode.toLowerCase()}`
                              : "",
                          customerTrip?.departure?.atlasProdTourId
                              ? `prod_tour_id:${customerTrip?.departure?.atlasProdTourId}`
                              : "",
                      ]
                    : null,
            },
        }),
    });
};
