import {
    Dropdown,
    Field,
    Item,
    Label,
    Menu,
    Select,
} from "@zendeskgarden/react-dropdowns";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { BusinessCode } from "@localtypes/generated-gql";

export type CustomerBrand = BusinessCode.Gat | BusinessCode.Ub | BusinessCode.Adv;

export type CustomerBrandDropdownProps = {
    selectedCustomerBrand: string | undefined;
    setSelectedCustomerBrand: React.Dispatch<
        React.SetStateAction<BusinessCode>
    >;
};

export const CustomerBrandDropdown: React.FC<CustomerBrandDropdownProps> = ({
    selectedCustomerBrand,
    setSelectedCustomerBrand,
}) => {
    if (!selectedCustomerBrand) {
        return (
            <Alert type="info" style={{ paddingBlock: 8 }}>
                <Title>Brand</Title>
                No customer brands
            </Alert>
        );
    }

    return (
        <Dropdown
            selectedItem={selectedCustomerBrand}
            onSelect={(item: CustomerBrand) => setSelectedCustomerBrand(item)}
            downshiftProps={{
                itemToString: (item: CustomerBrand) => item,
            }}
        >
            <Field>
                <Label>Brand</Label>
                <Select isCompact>{selectedCustomerBrand}</Select>
            </Field>
            <Menu>
                <Item key={"GAT"} value={BusinessCode.Gat}>
                    GAT
                </Item>
                <Item key={"UB"} value={BusinessCode.Ub}>
                    UB
                </Item>
                <Item key={"ADV"} value={BusinessCode.Adv}>
                    ADV
                </Item>
            </Menu>
        </Dropdown>
    );
};
